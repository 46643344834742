<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems" title="{{ 'PAYMENT.VCREDIT.TEXT' | translate }}"></app-page-title>

  <div class="row">
      <div class="col-12">
          <div class="card">
              <div class="card-body">
                  <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
                      <div class="mb-2 mb-md-0">
                          <button type="button" class="btn btn-primary waves-effect waves-light" 
                                  (click)="openFilterModal(filterModal)">
                              <i class="mdi mdi-magnify mr-1"></i> {{ 'SHARED.SEARCH' | translate }}
                              @if (filtersActive) {
                                  <span class="badge badge-light ml-1">
                                      <i class="mdi mdi-filter"></i>
                                  </span>
                              }
                          </button>
                      </div>
                  </div>

                  <div class="table-responsive">
                      <table class="table table-centered table-nowrap">
                          <thead>
                              <tr>
                                  <th>{{ 'PAYMENT.VCREDIT.CLIENT' | translate }}</th>
                                  <th>{{ 'PAYMENT.VCREDIT.AMOUNT' | translate }}</th>
                                  <th>{{ 'PAYMENT.VCREDIT.TYPE' | translate }}</th>
                                  <th>{{ 'PAYMENT.VCREDIT.PAYMENT_METHOD' | translate }}</th>
                                  <th>{{ 'PAYMENT.VCREDIT.BALANCE' | translate }}</th>
                                  <th>{{ 'SHARED.DATE' | translate }}</th>
                              </tr>
                          </thead>
                          <tbody>
                              @for (operation of operations; track operation.uuid) {
                              <tr>
                                  <td>{{ operation.client.name }}</td>
                                  <td>{{ operation.amount | currencyFormat }}</td>
                                  <td>{{ operation.type }}</td>
                                  <td>{{ operation.paymentMethod }}</td>
                                  <td>{{ operation.balance | currencyFormat }}</td>
                                  <td>{{ operation.createdAt | date:'medium' }}</td>
                              </tr>
                              }
                          </tbody>
                      </table>
                  </div>

                  <div class="row mt-4">
                      <div class="col-lg-12">
                          <ngb-pagination [collectionSize]="collectionSize" [(page)]="paginationDto.page" 
                                          [pageSize]="paginationDto.limit" (pageChange)="onPageChange($event)">
                          </ngb-pagination>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template #filterModal let-modal>
  <div class="modal-header">
      <h5 class="modal-title mt-0">{{ 'PAYMENT.VCREDIT.FILTER' | translate }}</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
      <form [formGroup]="filterForm">
          <div class="form-group">
              <label for="clientId">{{ 'PAYMENT.VCREDIT.CLIENT' | translate }}</label>
              <ng-select [items]="clients" bindLabel="name" bindValue="uuid" [clearable]="true" 
                         formControlName="clientId" id="clientId">
              </ng-select>
          </div>
          <div class="form-group">
              <label for="type">{{ 'PAYMENT.VCREDIT.TYPE' | translate }}</label>
              <ng-select [items]="operationTypes" [clearable]="true" formControlName="type" id="type">
              </ng-select>
          </div>
          <div class="form-group">
              <label for="paymentMethod">{{ 'PAYMENT.VCREDIT.PAYMENT_METHOD' | translate }}</label>
              <ng-select [items]="paymentMethods" [clearable]="true" formControlName="paymentMethod" id="paymentMethod">
              </ng-select>
          </div>
          <div class="form-group">
              <label for="startDate">{{ 'PAYMENT.VCREDIT.START_DATE' | translate }}</label>
              <input type="date" class="form-control" id="startDate" formControlName="startDate">
          </div>
          <div class="form-group">
              <label for="endDate">{{ 'PAYMENT.VCREDIT.END_DATE' | translate }}</label>
              <input type="date" class="form-control" id="endDate" formControlName="endDate">
          </div>
      </form>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="clearFilters(); modal.close('Close click')">
          {{ 'SHARED.CLEAR' | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="applyFilters()">
          {{ 'SHARED.APPLY' | translate }}
      </button>
  </div>
</ng-template>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>
