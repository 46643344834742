<div class="form-group">
    <label>{{ 'CLIENT.FORM.FEES' | translate }}</label>
    <div class="d-flex mb-2">
      <ng-select class="flex-grow-1 me-2"
                 [items]="availableFees"
                 bindLabel="label"
                 bindValue="uuid"
                 [(ngModel)]="selectedFeeId"
                 [placeholder]="'CLIENT.FORM.SELECT_FEE' | translate">
      </ng-select>
      <button type="button" class="btn btn-primary" (click)="addFee()" [disabled]="!selectedFeeId">
        {{ 'SHARED.ADD' | translate }}
      </button>
    </div>
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th>{{ 'PAYMENT.FEE.LIST.LABEL' | translate }}</th>
          <th>{{ 'PAYMENT.FEE.LIST.PERCENTAGE' | translate }}</th>
          <th>{{ 'PAYMENT.FEE.LIST.AMOUNT' | translate }}</th>
          <th>{{ 'SHARED.ACTIONS' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fee of clientFees">
          <td>{{ fee.label }}</td>
          <td>{{ fee.percentage }}%</td>
          <td>{{ fee.amount | currencyFormat }}</td>
          <td>
            <button type="button" class="btn btn-sm btn-danger" (click)="removeFee(fee)">
              {{ 'SHARED.REMOVE' | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>