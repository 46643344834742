import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { Profil } from '../../../interfaces/core/user/profil';
import { UserService } from '../../../services/core/user/user.service';
import { ProfilService } from '../../../services/core/user/profil.service';
import { SubscribeDestroyerService } from '../../../services/utils/subscribe-destroyer.service';
import { PageTitleComponent } from 'src/app/shared/ui/pagetitle/pagetitle.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import Swal from 'sweetalert2';
import { BreadcrumbItem } from 'src/app/interfaces/dtos/breadcrumb-item';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { Users } from 'src/app/interfaces/core/user/user';
import { HasPermissionsDirective } from 'src/app/directives/has-permissions.directive';
import { PermissionService } from 'src/app/services/core/user/permission.service';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    RouterModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    HasPermissionsDirective
  ],
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [SubscribeDestroyerService],
})
export class UserListComponent implements OnInit {
  users: Users[] = [];
  profils: Profil[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: 'createdAt',
    orderDirection: 'DESC',
    search: '',
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;

  constructor(
    private userService: UserService,
    private profilService: ProfilService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.initBreadcrumbItems();
    this.initFilterForm();
    this.loadProfils();
    this.loadUsers();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(['SHARED.HOME', 'USER.TEXT', 'USER.LIST.TITLE'])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations['SHARED.HOME'], link: '/' },
            { label: translations['USER.TEXT'], link: '/apps/user/user-list' },
            { label: translations['USER.LIST.TITLE'], active: true },
          ];
        },
        error: (error) => {
          console.error('Error loading translations:', error);
        },
      });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      email: [''],
      firstName: [''],
      lastName: [''],
      profilId: [null],
    });
  }

  async loadProfils() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.profil.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.profilService
      .findAll()
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.profils = response.payload.content;
        },
        error: (error) => {
          console.error('Error loading profils:', error);
          this.showErrorAlert('USER.ERROR.LOAD_PROFILS');
        },
      });
  }

  async loadUsers() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.users.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.updatePaginationDto();

    this.userService
      .findAll(this.paginationDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.users = response.payload.content;
          this.collectionSize = response.payload.totalRecords;
          this.spinner.hide();
        },
        error: (error) => {
          console.error('Error loading users:', error);
          this.showErrorAlert('USER.ERROR.LOAD_USERS');
          this.spinner.hide();
        },
      });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    
    this.paginationDto.params = [];

    if (filters.email) {
      this.paginationDto.search = filters.email;
    }
    if (filters.firstName) {
      this.paginationDto.params.push({ firstName: filters.firstName });
    }
    if (filters.lastName) {
      this.paginationDto.params.push({ lastName: filters.lastName });
    }
    if (filters.profilId) {
      this.paginationDto.params.push({ profilId: filters.profilId });
    }

    this.filtersActive = this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  onPageChange(page: number): void {
    this.paginationDto.page = page;
    this.loadUsers();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  applyFilters(): void {
    this.paginationDto.page = 1;
    this.loadUsers();
    this.modalService.dismissAll();
  }

  clearFilters(): void {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: 'createdAt',
      orderDirection: 'DESC',
      search: '',
      params: [],
    };
    this.filtersActive = false;
    this.loadUsers();
  }

 async createUser() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.users.create'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.router.navigate(['/apps/user/user-list/create']);
  }

  async editUser(uuid: string) {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.users.update'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.router.navigate(['/apps/user/user-list/edit', uuid]);
  }

 async deleteUser(uuid: string) {
  if (!await this.permissionService.hasPermission({ permissions: ['perm.users.delete'], condition: 'AND', equalsTo: true })) {
    return;
  }
    this.translateService
      .get([
        'USER.CONFIRM_DELETE',
        'SHARED.YES',
        'SHARED.NO',
        'USER.DELETED',
        'USER.ERROR.DELETE',
      ])
      .subscribe({
        next: (translations) => {
          Swal.fire({
            title: translations['USER.CONFIRM_DELETE'],
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: translations['SHARED.YES'],
            cancelButtonText: translations['SHARED.NO'],
          }).then((result) => {
            if (result.isConfirmed) {
              this.userService
                .delete(uuid)
                .pipe(takeUntil(this.subscribeDestroyer$))
                .subscribe({
                  next: () => {
                    Swal.fire(translations['USER.DELETED'], '', 'success');
                    this.loadUsers();
                  },
                  error: (error) => {
                    console.error('Error deleting user:', error);
                    this.showErrorAlert('USER.ERROR.DELETE');
                  },
                });
            }
          });
        },
        error: (error) => {
          console.error('Error loading translations:', error);
        },
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(['SHARED.ERROR', messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(translations['SHARED.ERROR'], translations[messageKey], 'error');
        },
        error: (error) => {
          console.error('Error loading translations:', error);
        },
      });
  }
}
