import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRoute, Router } from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { Devise } from "src/app/interfaces/core/common/devise";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { DeviseService } from "src/app/services/core/common/devise.service";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-devise-create-update",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    HasPermissionsDirective
  ],
  templateUrl: "./devise-create-update.component.html",
  styleUrls: ["./devise-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class DeviseCreateUpdateComponent implements OnInit {
  deviseForm: FormGroup;
  isUpdateMode = false;
  deviseId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private deviseService: DeviseService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.deviseForm = this.formBuilder.group({
      code: ["", [Validators.required, Validators.maxLength(10)]],
      label: ["", Validators.required],
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "COMMON.CURRENCY",
        "COMMON.CURRENCY.CREATE.TITLE",
        "COMMON.CURRENCY.EDIT.TITLE",
      ])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            {
              label: translations["COMMON.CURRENCY"],
              link: "/apps/common/currency",
            },
            {
              label: this.isUpdateMode
                ? translations["COMMON.CURRENCY.EDIT.TITLE"]
                : translations["COMMON.CURRENCY.CREATE.TITLE"],
              active: true,
            },
          ];
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        }
      });
  }

  async checkForUpdateMode(): Promise<void> {
    this.deviseId = this.route.snapshot.paramMap.get("id");
    if (this.deviseId) {
      this.isUpdateMode = true;
      await this.loadDeviseData();
    }
  }

  async loadDeviseData(): Promise<void> {
    if (!this.deviseId) return;

    if (!await this.permissionService.hasPermission({ permissions: ['perm.devise.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.deviseService
      .findOne(this.deviseId)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.deviseForm.patchValue(response.payload);
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading devise:", error);
          this.showErrorAlert("COMMON.CURRENCY.ERROR.LOAD_CURRENCY");
          this.spinner.hide();
        },
      });
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.deviseForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  async onSubmit(): Promise<void> {
    this.isFormSubmitted = true;

    if (this.deviseForm.valid) {
      this.spinner.show();
      const deviseData: Devise = this.deviseForm.value;

      if (this.isUpdateMode && this.deviseId) {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.devise.update'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }

        this.deviseService
          .update(this.deviseId, deviseData)
          .pipe(takeUntil(this.subscribeDestroyer$))
          .subscribe({
            next: () => {
              this.showSuccessAlert("COMMON.CURRENCY.UPDATED");
              this.router.navigate(["/apps/common/currency"]);
              this.spinner.hide();
            },
            error: (error) => {
              console.error("Error updating devise:", error);
              this.showErrorAlert("COMMON.CURRENCY.ERROR.UPDATE");
              this.spinner.hide();
            },
          });
      } else {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.devise.create'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }

        this.deviseService
          .create(deviseData)
          .pipe(takeUntil(this.subscribeDestroyer$))
          .subscribe({
            next: () => {
              this.showSuccessAlert("COMMON.CURRENCY.CREATED");
              this.router.navigate(["/apps/common/currency"]);
              this.spinner.hide();
            },
            error: (error) => {
              console.error("Error creating devise:", error);
              this.showErrorAlert("COMMON.CURRENCY.ERROR.CREATE");
              this.spinner.hide();
            },
          });
      }
    }
  }

  cancel(): void {
    this.router.navigate(["/apps/common/currency"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.SUCCESS", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.SUCCESS"],
            translations[messageKey],
            "success"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Success", "Operation completed successfully", "success");
        }
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Error", "An error occurred", "error");
        }
      });
  }
}