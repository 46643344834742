<div class="container-fluid">
    <app-page-title [breadcrumbItems]="breadcrumbItems" title="{{ 'USER.PROFIL.LIST.TITLE' | translate }}"></app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
                        <div class="mb-2 mb-md-0"   *appHasPermissions="{ permissions: ['perm.profil.read'], condition: 'OR', equalsTo: true }">
                            <button type="button" class="btn btn-primary waves-effect waves-light"
                                (click)="openFilterModal(filterModal)">
                                <i class="mdi mdi-magnify mr-1"></i> {{ 'SHARED.SEARCH' | translate }}
                                @if(filtersActive){
                                <span class="badge badge-light ml-1">
                                    <i class="mdi mdi-filter"></i>
                                </span>
                                }
                            </button>
                        </div>
                        <div  *appHasPermissions="{ permissions: ['perm.profil.create'], condition: 'OR', equalsTo: true }">
                            <button type="button" class="btn btn-success btn-rounded waves-effect waves-light"
                                (click)="createProfil()">
                                <i class="mdi mdi-plus mr-1"></i> {{ 'USER.PROFIL.LIST.ADD' | translate }}
                            </button>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-centered table-nowrap">
                            <thead>
                                <tr>
                                    <th>{{ 'USER.PROFIL.LIST.NAME' | translate }}</th>
                                    <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                @for (profil of profils; track profil.uuid) {
                                <tr>
                                    <td>{{ profil.name }}</td>
                                    
                                    <td>
                                        <button  *appHasPermissions="{ permissions: ['perm.profil.update'], condition: 'OR', equalsTo: true }" type="button" class="btn btn-primary btn-sm btn-rounded me-2"
                                            (click)="editProfil(profil.uuid)">
                                            {{ 'SHARED.EDIT' | translate }}
                                        </button>
                                        <button  *appHasPermissions="{ permissions: ['perm.profil.delete'], condition: 'OR', equalsTo: true }" type="button" class="btn btn-danger btn-sm btn-rounded"
                                            (click)="deleteProfil(profil.uuid)">
                                            {{ 'SHARED.DELETE' | translate }}
                                        </button>
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="paginationDto.page"
                                [pageSize]="paginationDto.limit" (pageChange)="onPageChange($event)">
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #filterModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">{{ 'USER.PROFIL.LIST.FILTER.TITLE' | translate }}</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
    <div class="modal-body">
        <form [formGroup]="filterForm">
            <div class="form-group">
                <label for="name">{{ 'USER.PROFIL.LIST.FILTER.NAME' | translate }}</label>
                <input type="text" class="form-control" id="name" formControlName="name">
            </div>
            <div class="form-group">
                <label for="permission">{{ 'USER.PROFIL.LIST.FILTER.PERMISSIONS' | translate }}</label>
                <ng-select [items]="permissions" bindLabel="name" bindValue="uuid" [multiple]="true"
                    formControlName="permissionIds" id="permission">
                </ng-select>
            </div>
            <div class="form-group">
                <label for="appFeature">{{ 'USER.PROFIL.LIST.FILTER.APP_FEATURES' | translate }}</label>
                <ng-select [items]="appFeatures" bindLabel="name" bindValue="uuid" [multiple]="true"
                    formControlName="appFeatureIds" id="appFeature">
                </ng-select>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="clearFilters(); modal.close('Close click')">
            {{ 'SHARED.CLEAR' | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="applyFilters()">
            {{ 'SHARED.APPLY' | translate }}
        </button>
    </div>
</ng-template>

<ngx-spinner type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>