import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRoute, Router } from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { finalize, takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { ActivityArea } from "src/app/interfaces/core/client/activity-area";
import { Client } from "src/app/interfaces/core/client/client";
import { TypeClient } from "src/app/interfaces/core/client/type-client";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { ActivityAreaService } from "src/app/services/core/client/activity-area.service";
import { ClientService } from "src/app/services/core/client/client.service";
import { TypeClientService } from "src/app/services/core/client/type-client.service";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { AttributeManagementComponent } from "src/app/shared/ui/attribute-management/attribute-management.component";
import { Attribute } from "src/app/interfaces/core/common/attribute";
import { AttributeService } from "src/app/services/core/common/attribute.service";
import { ClientAttribute } from "src/app/interfaces/core/client/client-attribute";
import { ClientAttributeService } from "src/app/services/core/client/client-attribute.service";
import { Fee } from "src/app/interfaces/core/payment/fee";
import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ClientFeeManagementComponent } from "../../components/client-fee-management/client-fee-management.component";

@Component({
  selector: "app-client-create-update",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    AttributeManagementComponent,
    ClientFeeManagementComponent
  ],
  templateUrl: "./client-create-update.component.html",
  styleUrls: ["./client-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ClientCreateUpdateComponent implements OnInit {
  clientForm: FormGroup;
  typeClients: TypeClient[] = [];
  activityAreas: ActivityArea[] = [];
  isUpdateMode = false;
  clientId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];
  clientAttributes: ClientAttribute[] = [];
  originalClientAttributes: ClientAttribute[] = [];
  allAttributes: Attribute[] = [];
  clientFees: Fee[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private typeClientService: TypeClientService,
    private activityAreaService: ActivityAreaService,
    private attributeService: AttributeService,
    private clientAttributeService: ClientAttributeService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadTypeClients();
    this.loadActivityAreas();
    this.loadAllAttributes();
    this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.clientForm = this.formBuilder.group({
      name: ["", Validators.required],
      eyoneExternalId: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      send_fees: [false],
      typeClientId: ["", Validators.required],
      activityAreaId: ["", Validators.required],
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "CLIENT.TEXT",
        "CLIENT.CREATE.TITLE",
        "CLIENT.EDIT.TITLE",
      ])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            {
              label: translations["CLIENT.TEXT"],
              link: "/apps/client/client-list",
            },
            {
              label: this.isUpdateMode
                ? translations["CLIENT.EDIT.TITLE"]
                : translations["CLIENT.CREATE.TITLE"],
              active: true,
            },
          ];
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        },
      });
  }

  loadTypeClients(): void {
    this.spinner.show();
    this.typeClientService
      .findAll({limit:0})
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.typeClients = response.payload.content;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading type clients:", error);
          this.showErrorAlert("CLIENT.ERROR.LOAD_TYPE_CLIENTS");
          this.spinner.hide();
        },
      });
  }

  loadActivityAreas(): void {
    this.spinner.show();
    this.activityAreaService
    .findAll({limit:0})
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.activityAreas = response.payload.content;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading activity areas:", error);
          this.showErrorAlert("CLIENT.ERROR.LOAD_ACTIVITY_AREAS");
          this.spinner.hide();
        },
      });
  }

  loadAllAttributes(): void {
    this.spinner.show();
    this.attributeService.findAll({
      limit: 0
    }).pipe(
      takeUntil(this.subscribeDestroyer$)
    ).subscribe({
      next: (response) => {
        this.allAttributes = response.payload.content;
        this.spinner.hide();
      },
      error: (error) => {
        console.error("Error loading attributes:", error);
        this.showErrorAlert("COMMON.ATTRIBUTE.ERROR.LOAD_ATTRIBUTES");
        this.spinner.hide();
      }
    });
  }

  checkForUpdateMode(): void {
    this.clientId = this.route.snapshot.paramMap.get("id");
    if (this.clientId) {
      this.isUpdateMode = true;
      this.loadClientData();
    }
  }

  loadClientData(): void {
    if (this.clientId) {
      this.spinner.show();
      this.clientService
        .findOne(this.clientId)
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: (response) => {
            this.clientForm.patchValue(response.payload);
            this.clientAttributes = response.payload.clientAttributes || [];
            this.originalClientAttributes = [...this.clientAttributes];
            this.clientFees = response.payload.fees || [];
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error loading client:", error);
            this.showErrorAlert("CLIENT.ERROR.LOAD_CLIENT");
            this.spinner.hide();
          },
        });
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.clientForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  onSubmit(): void {
    this.isFormSubmitted = true;

    if (this.clientForm.valid) {
      this.spinner.show();
      const clientData: Client = {
        ...this.clientForm.value,
        fees: this.clientFees
      };

      if (this.isUpdateMode && this.clientId) {
        this.updateClient(clientData);
      } else {
        this.createClient(clientData);
      }
    }
  }

  createClient(clientData: Client): void {
    this.clientService
      .create(clientData)
      .pipe(
        switchMap((response) => {
          const clientId = response.payload.uuid;
          if (this.clientAttributes.length > 0) {
            const attributeObservables = this.clientAttributes.map(attr => {
              const newAttribute: ClientAttribute = {
                value: attr.value,
                clientId: clientId,
                attributeId: attr.attributeId
              };
              return this.clientAttributeService.create(newAttribute);
            });
            return forkJoin(attributeObservables);
          } else {
            return of(null);
          }
        }),
        takeUntil(this.subscribeDestroyer$)
      )
      .subscribe({
        next: () => {
          this.showSuccessAlert("CLIENT.CREATED");
          this.router.navigate(["/apps/client/client-list"]);
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error creating client:", error);
          this.showErrorAlert("CLIENT.ERROR.CREATE");
          this.spinner.hide();
        },
      });
  }

  updateClient(clientData: Client): void {
    if (this.clientId) {
      this.clientService
        .update(this.clientId, clientData)
        .pipe(
          switchMap(() => {
            const attributeObservables = [];

            const deletedAttributes = this.originalClientAttributes.filter(
              original => !this.clientAttributes.some(current => current.uuid === original.uuid)
            );
            deletedAttributes.forEach(attr => {
              attributeObservables.push(this.clientAttributeService.delete(attr.uuid!));
            });

            this.clientAttributes.forEach(attr => {
              if (attr.uuid) {
                attributeObservables.push(this.clientAttributeService.update(attr.uuid, attr));
              } else {
                const newAttribute: ClientAttribute = {
                  value: attr.value,
                  clientId: this.clientId!,
                  attributeId: attr.attributeId
                };
                attributeObservables.push(this.clientAttributeService.create(newAttribute));
              }
            });

            return attributeObservables.length > 0 ? forkJoin(attributeObservables) : of(null);
          }),
          takeUntil(this.subscribeDestroyer$)
        )
        .subscribe({
          next: () => {
            this.showSuccessAlert("CLIENT.UPDATED");
            this.router.navigate(["/apps/client/client-list"]);
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error updating client:", error);
            this.showErrorAlert("CLIENT.ERROR.UPDATE");
            this.spinner.hide();
          },
        });
    }
  }

  onAttributesChanged(attributes: ClientAttribute[]): void {
    this.clientAttributes = attributes;
  }

  onFeesChanged(fees: Fee[]): void {
    console.log('on change ',fees);
    
    this.clientFees = fees;
  }

  cancel(): void {
    this.router.navigate(["/apps/client/client-list"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.SUCCESS", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.SUCCESS"],
            translations[messageKey],
            "success"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Success", "Operation completed successfully", "success");
        },
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Error", "An error occurred", "error");
        },
      });
  }
}