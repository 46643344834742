<div class="container-fluid">
    <app-page-title [breadcrumbItems]="breadcrumbItems" [title]="'USER.APP_FEATURE.LIST.TITLE' | translate"></app-page-title>
  
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
              <div class="mb-2 mb-md-0"  *appHasPermissions="{ permissions: ['perm.appfeature.read'], condition: 'OR', equalsTo: true }">
                <button type="button" class="btn btn-primary waves-effect waves-light" (click)="openFilterModal(filterModal)">
                  <i class="mdi mdi-magnify mr-1"></i> {{ 'SHARED.SEARCH' | translate }}
                  @if (filtersActive) {
                    <span class="badge badge-light ml-1">
                      <i class="mdi mdi-filter"></i>
                    </span>
                  }
                </button>
              </div>
              <div *appHasPermissions="{ permissions: ['perm.appfeature.create'], condition: 'OR', equalsTo: true }">
                <button type="button" class="btn btn-success btn-rounded waves-effect waves-light" routerLink="/apps/user/app-feature/create">
                  <i class="mdi mdi-plus mr-1"></i> {{ 'USER.APP_FEATURE.LIST.ADD_NEW' | translate }}
                </button>
              </div>
            </div>
  
            <div class="table-responsive">
              <table class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>{{ 'USER.APP_FEATURE.LIST.NAME' | translate }}</th>
                    <th>{{ 'USER.APP_FEATURE.LIST.DESCRIPTION' | translate }}</th>
                    <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  @for (feature of appFeatures; track feature.uuid) {
                    <tr>
                      <td>{{ feature.name }}</td>
                      <td>{{ feature.description }}</td>
                      <td>
                        <button *appHasPermissions="{ permissions: ['perm.appfeature.update'], condition: 'OR', equalsTo: true }" class="btn btn-primary btn-sm btn-rounded me-2" [routerLink]="['/apps/user/app-feature/edit', feature.uuid]">
                          {{ 'SHARED.EDIT' | translate }}
                        </button>
                        <button  *appHasPermissions="{ permissions: ['perm.appfeature.delete'], condition: 'OR', equalsTo: true }" class="btn btn-danger btn-sm btn-rounded" (click)="deleteAppFeature(feature.uuid)">
                          {{ 'SHARED.DELETE' | translate }}
                        </button>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
  
            <div class="row mt-4">
              <div class="col-lg-12">
                <ngb-pagination
                  [collectionSize]="collectionSize"
                  [(page)]="paginationDto.page"
                  [pageSize]="paginationDto.limit"
                  (pageChange)="onPageChange($event)"
                  [maxSize]="5"
                  [ellipses]="false"
                  [rotate]="true"
                ></ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #filterModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">{{ 'USER.APP_FEATURE.LIST.FILTER' | translate }}</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
    <div class="modal-body">
      <form [formGroup]="filterForm">
        <div class="form-group">
          <label for="name">{{ 'USER.APP_FEATURE.LIST.NAME' | translate }}</label>
          <input type="text" id="name" class="form-control" formControlName="name">
        </div>
        <div class="form-group">
          <label for="description">{{ 'USER.APP_FEATURE.LIST.DESCRIPTION' | translate }}</label>
          <input type="text" id="description" class="form-control" formControlName="description">
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="clearFilters(); modal.close('Close click')">
        {{ 'SHARED.CLEAR' | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="applyFilters()">
        {{ 'SHARED.APPLY' | translate }}
      </button>
    </div>
  </ng-template>
  