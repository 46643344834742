import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TypeClient } from 'src/app/interfaces/core/client/type-client';
import { DefaultResponsePayload } from 'src/app/interfaces/dtos/default-response-payload';
import { getPagination } from 'src/app/interfaces/dtos/filter';
import { FindAllResponse } from 'src/app/interfaces/dtos/find-all-response';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TypeClientService {
    private readonly apiUrl = `${environment.API_BASE_URL}/type-client`;

    constructor(private http: HttpClient) {}

    findAll(pagination?: PaginationDto): Observable<FindAllResponse<TypeClient>> {
        const params = getPagination(pagination);
        return this.http.get<FindAllResponse<TypeClient>>(this.apiUrl, { params });
    }

    findOne(id: string): Observable<DefaultResponsePayload<TypeClient>> {
        return this.http.get<DefaultResponsePayload<TypeClient>>(`${this.apiUrl}/${id}`);
    }

    create(payload: TypeClient): Observable<DefaultResponsePayload<TypeClient>> {
        return this.http.post<DefaultResponsePayload<TypeClient>>(this.apiUrl, payload);
    }

    createBulk(payload: TypeClient[]): Observable<DefaultResponsePayload<TypeClient[]>> {
        return this.http.post<DefaultResponsePayload<TypeClient[]>>(`${this.apiUrl}/bulk`, payload);
    }

    update(id: string, payload: TypeClient): Observable<DefaultResponsePayload<TypeClient>> {
        return this.http.patch<DefaultResponsePayload<TypeClient>>(`${this.apiUrl}/${id}`, payload);
    }

    delete(id: string): Observable<DefaultResponsePayload<TypeClient>> {
        return this.http.delete<DefaultResponsePayload<TypeClient>>(`${this.apiUrl}/${id}`);
    }

    deleteBulk(ids: string[]): Observable<DefaultResponsePayload<TypeClient[]>> {
        return this.http.post<DefaultResponsePayload<TypeClient[]>>(`${this.apiUrl}/bulk/delete`, { ids });
    }
}
