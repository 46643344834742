<form [formGroup]="attributeForm" (ngSubmit)="addAttribute()" class="mb-4">
  <div class="row g-3">
    <div class="col-12 col-md-5">
      <div class="form-group">
        <label for="attributeSelect" class="form-label">{{ 'COMMON.ATTRIBUTE.SELECT' | translate }}</label>
        <ng-select
          [items]="allAttributes"
          bindLabel="label"
          bindValue="uuid"
          [clearable]="false"
          formControlName="attributeId"
          id="attributeSelect">
        </ng-select>
      </div>
    </div>
    <div class="col-12 col-md-5">
      <div class="form-group">
        <label for="attributeValue" class="form-label">{{ 'COMMON.ATTRIBUTE.VALUE' | translate }}</label>
        @switch (selectedAttribute?.type) {
          @case ('string') {
            <input type="text" class="form-control" id="attributeValue" formControlName="value">
          }
          @case ('number') {
            <input type="number" class="form-control" id="attributeValue" formControlName="value">
          }
          @case ('boolean') {
            <select class="form-control" id="attributeValue" formControlName="value">
              <option [ngValue]="true">True</option>
              <option [ngValue]="false">False</option>
            </select>
          }
          @case ('date') {
            <input type="date" class="form-control" id="attributeValue" formControlName="value">
          }
          @default {
            <input type="text" class="form-control" id="attributeValue" formControlName="value">
          }
        }
      </div>
    </div>
    <div class="col-12 col-md-2 d-flex align-items-end">
      <button type="submit" class="btn btn-primary w-100" [disabled]="!attributeForm.valid">
        {{ 'COMMON.ATTRIBUTE.ADD' | translate }}
      </button>
    </div>
  </div>
</form>

<div class="mt-4">
  <h6>{{ 'COMMON.ATTRIBUTE.ASSIGNED' | translate }}</h6>
  <ul class="list-group">
    <li *ngFor="let attr of entityAttributes; let i = index" class="list-group-item">
      <div class="attribute-item">
        <div class="attribute-label-value">
          <strong>{{ getAttributeLabel(attr.attributeId) }}:</strong>
          <span class="attribute-value" [class.masked]="!visibleValues[i]">
            {{ getDisplayValue(attr.value, getAttributeType(attr.attributeId), i) }}
          </span>
        </div>
        <div class="attribute-actions">
          <button type="button" class="btn btn-outline-secondary btn-sm" (click)="toggleValueVisibility(i)" [attr.aria-label]="visibleValues[i] ? 'Hide' : 'Show'">
            <i [class]="visibleValues[i] ? 'ri-eye-off-line' : 'ri-eye-line'"></i>
          </button>
          <button type="button" class="btn btn-outline-primary btn-sm" (click)="copyAttributeValue(attr.value)" aria-label="Copy">
            <i class="ri-file-copy-line"></i>
          </button>
          <button type="button" class="btn btn-danger btn-sm" (click)="removeAttribute(i)" aria-label="Remove">
            <i class="ri-delete-bin-line"></i>
          </button>
        </div>
      </div>
    </li>
  </ul>
</div>