import { Component } from '@angular/core';

@Component({
  selector: 'app-permission',
  standalone: true,
  imports: [],
  templateUrl: './permission.component.html',
  styleUrl: './permission.component.scss'
})
export class PermissionComponent {

}
