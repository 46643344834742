import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import {
  NgbModalModule,
  NgbPaginationModule,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { Devise } from "../../../interfaces/core/common/devise";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { DeviseService } from "src/app/services/core/common/devise.service";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-devise-list",
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    HasPermissionsDirective
  ],
  templateUrl: "./devise-list.component.html",
  styleUrls: ["./devise-list.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class DeviseListComponent implements OnInit {
  devises: Devise[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: "createdAt",
    orderDirection: "DESC",
    search: "",
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;

  constructor(
    private deviseService: DeviseService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initBreadcrumbItems();
    this.initFilterForm();
    await this.loadDevises();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "COMMON.CURRENCY.TEXT",
        "COMMON.CURRENCY.LIST.TITLE",
      ])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            {
              label: translations["COMMON.CURRENCY.TEXT"],
              link: "/apps/common/currency",
            },
            { label: translations["COMMON.CURRENCY.LIST.TITLE"], active: true },
          ];
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        }
      });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      code: [""],
      label: [""],
    });
  }

  async loadDevises(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.devise.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.updatePaginationDto();

    this.deviseService
      .findAll(this.paginationDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.devises = response.payload.content;
          this.collectionSize = response.payload.totalRecords;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading devises:", error);
          this.showErrorAlert("COMMON.CURRENCY.ERROR.LOAD_CURRENCIES");
          this.spinner.hide();
        },
      });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];

    if (filters.code) {
      this.paginationDto.params.push({ code: filters.code });
    }
    if (filters.label) {
      this.paginationDto.search = filters.label;
    }

    this.filtersActive =
      this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  async onPageChange(page: number): Promise<void> {
    this.paginationDto.page = page;
    await this.loadDevises();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  async applyFilters(): Promise<void> {
    this.paginationDto.page = 1;
    await this.loadDevises();
    this.modalService.dismissAll();
  }

  async clearFilters(): Promise<void> {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: "createdAt",
      orderDirection: "DESC",
      search: "",
      params: [],
    };
    this.filtersActive = false;
    await this.loadDevises();
  }

  async createDevise(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.devise.create'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.router.navigate(["/apps/common/currency/create"]);
  }

  async editDevise(uuid: string): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.devise.update'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.router.navigate(["/apps/common/currency/edit", uuid]);
  }

  async deleteDevise(uuid: string): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.devise.delete'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.translateService
      .get([
        "COMMON.CURRENCY.CONFIRM_DELETE",
        "SHARED.YES",
        "SHARED.NO",
        "COMMON.CURRENCY.DELETED",
        "COMMON.CURRENCY.ERROR.DELETE",
      ])
      .subscribe({
        next: (translations) => {
          Swal.fire({
            title: translations["COMMON.CURRENCY.CONFIRM_DELETE"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: translations["SHARED.YES"],
            cancelButtonText: translations["SHARED.NO"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.deviseService
                .delete(uuid)
                .pipe(takeUntil(this.subscribeDestroyer$))
                .subscribe({
                  next: () => {
                    Swal.fire(
                      translations["COMMON.CURRENCY.DELETED"],
                      "",
                      "success"
                    );
                    this.loadDevises();
                  },
                  error: (error) => {
                    console.error("Error deleting devise:", error);
                    this.showErrorAlert("COMMON.CURRENCY.ERROR.DELETE");
                  },
                });
            }
          });
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        }
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Error", "An error occurred", "error");
        }
      });
  }
}