import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRoute, Router } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProviderAttribute } from "src/app/interfaces/core/configuration/provider-attribute";
import { ProviderAttributeService } from "src/app/services/core/configuration/provider-attribute.service";
import { ProviderService } from "src/app/services/core/configuration/provider.service";
import { AttributeManagementComponent } from "src/app/shared/ui/attribute-management/attribute-management.component";
import { Provider } from "src/app/interfaces/core/configuration/provider";
import { Attribute } from "src/app/interfaces/core/common/attribute";
import { AttributeService } from "src/app/services/core/common/attribute.service";
import { ProviderType } from "src/app/interfaces/enums/provider-type.enum";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-provider-create-update",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    AttributeManagementComponent,
    HasPermissionsDirective
  ],
  templateUrl: "./provider-create-update.component.html",
  styleUrls: ["./provider-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ProviderCreateUpdateComponent implements OnInit {
  providerForm: FormGroup;
  isUpdateMode = false;
  providerId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];
  providerAttributes: ProviderAttribute[] = [];
  originalProviderAttributes: ProviderAttribute[] = [];
  allAttributes: Attribute[] = [];
  providerTypes = Object.values(ProviderType);

  constructor(
    private formBuilder: FormBuilder,
    private providerService: ProviderService,
    private providerAttributeService: ProviderAttributeService,
    private attributeService: AttributeService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.loadAllAttributes();
    await this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.providerForm = this.formBuilder.group({
      name: ["", Validators.required],
      eyoneExternalId: ["", Validators.required],
      type: ["", Validators.required],
    });
  }

  async loadAllAttributes(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.attribute.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.attributeService.findAll({
      limit:0
    }).pipe(
      takeUntil(this.subscribeDestroyer$)
    ).subscribe({
      next: (response) => {
        this.allAttributes = response.payload.content;
        this.spinner.hide();
      },
      error: (error) => {
        console.error("Error loading attributes:", error);
        this.showErrorAlert("COMMON.ATTRIBUTE.ERROR.LOAD_ATTRIBUTES");
        this.spinner.hide();
      }
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "CONFIG.TEXT",
        "CONFIG.PROVIDER_CREATE.TITLE",
        "CONFIG.PROVIDER_EDIT.TITLE",
      ])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            {
              label: translations["CONFIG.TEXT"],
              link: "/apps/config/provider",
            },
            {
              label: this.isUpdateMode
                ? translations["CONFIG.PROVIDER_EDIT.TITLE"]
                : translations["CONFIG.PROVIDER_CREATE.TITLE"],
              active: true,
            },
          ];
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        }
      });
  }

  async checkForUpdateMode(): Promise<void> {
    this.providerId = this.route.snapshot.paramMap.get("id");
    if (this.providerId) {
      this.isUpdateMode = true;
      await this.loadProviderData();
    }
  }

  async loadProviderData(): Promise<void> {
    if (!this.providerId) return;

    if (!await this.permissionService.hasPermission({ permissions: ['perm.provider.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.providerService
      .findOne(this.providerId)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.providerForm.patchValue(response.payload);
          this.providerAttributes = response.payload.providerAttributes || [];
          this.originalProviderAttributes = [...this.providerAttributes];
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading provider:", error);
          this.showErrorAlert("CONFIG.PROVIDER_ERROR.LOAD_PROVIDER");
          this.spinner.hide();
        },
      });
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.providerForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  async onSubmit(): Promise<void> {
    this.isFormSubmitted = true;

    if (this.providerForm.valid) {
      this.spinner.show();
      const providerData: Provider = {
        ...this.providerForm.value,
      };

      if (this.isUpdateMode && this.providerId) {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.provider.update'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }
        await this.updateProvider(providerData);
      } else {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.provider.create'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }
        await this.createProvider(providerData);
      }
    }
  }

  async createProvider(providerData: Provider): Promise<void> {
    this.providerService
      .create(providerData)
      .pipe(
        switchMap((response) => {
          const providerId = response.payload.uuid;
          const attributeObservables = this.providerAttributes.map(attr => {
            const newAttribute: ProviderAttribute = {
              value: attr.value,
              providerId: providerId,
              attributeId: attr.attributeId
            };
            return this.providerAttributeService.create(newAttribute);
          });
          return forkJoin(attributeObservables);
        }),
        takeUntil(this.subscribeDestroyer$)
      )
      .subscribe({
        next: () => {
          this.showSuccessAlert("CONFIG.PROVIDER_CREATED");
          this.router.navigate(["/apps/config/provider"]);
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error creating provider:", error);
          this.showErrorAlert("CONFIG.PROVIDER_ERROR.CREATE");
          this.spinner.hide();
        },
      });
  }

  async updateProvider(providerData: Provider): Promise<void> {
    if (!this.providerId) return;

    this.providerService
      .update(this.providerId, providerData)
      .pipe(
        switchMap(() => {
          const attributeObservables = [];

          const deletedAttributes = this.originalProviderAttributes.filter(
            original => !this.providerAttributes.some(current => current.uuid === original.uuid)
          );
          deletedAttributes.forEach(attr => {
            attributeObservables.push(this.providerAttributeService.delete(attr.uuid!));
          });

          this.providerAttributes.forEach(attr => {
            if (attr.uuid) {
              attributeObservables.push(this.providerAttributeService.update(attr.uuid, attr));
            } else {
              const newAttribute: ProviderAttribute = {
                value: attr.value,
                providerId: this.providerId!,
                attributeId: attr.attributeId
              };
              attributeObservables.push(this.providerAttributeService.create(newAttribute));
            }
          });

          return forkJoin(attributeObservables);
        }),
        takeUntil(this.subscribeDestroyer$)
      )
      .subscribe({
        next: () => {
          this.showSuccessAlert("CONFIG.PROVIDER_UPDATED");
          this.router.navigate(["/apps/config/provider"]);
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error updating provider:", error);
          this.showErrorAlert("CONFIG.PROVIDER_ERROR.UPDATE");
          this.spinner.hide();
        },
      });
  }

  onAttributesChanged(attributes: ProviderAttribute[]): void {
    this.providerAttributes = attributes;
  }

  cancel(): void {
    this.router.navigate(["/apps/config/provider"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.SUCCESS", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.SUCCESS"],
            translations[messageKey],
            "success"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Success", "Operation completed successfully", "success");
        }
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Error", "An error occurred", "error");
        }
      });
  }
}