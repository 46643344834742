import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, finalize, switchMap, throwError } from 'rxjs';
import { AuthUtils } from '../services/utils/auth.utils';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.authService.accessToken) {
            if (!AuthUtils.isTokenExpired(this.authService.accessToken)) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${this.authService.accessToken}`,
                    },
                });
            } else if (
                !AuthUtils.isTokenExpired(this.authService.refreshToken) &&
                !req.url.includes('/auth/token/refresh') &&
                !this.authService.refreshInProgress()
            ) {
                return this.refreshTokenAndHandleRequest(req, next);
            } else {
                this.authService.signOut();
                return next.handle(req);
            }
        }
        return next.handle(req);
    }

    private refreshTokenAndHandleRequest(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.authService.refreshInProgress.set(true);
        return this.authService
            .refreshTokenRequest({
                refreshToken: this.authService.refreshToken,
            })
            .pipe(
                switchMap((response) => {
                    this.authService.accessToken =
                        response.body?.payload.accessToken!;
                    const transformedReq = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${this.authService.accessToken}`,
                        },
                    });
                    return next.handle(transformedReq);
                }),
                catchError((error) =>
                    this.handleUnauthorizedError(error, req, next)
                ),
                finalize(() => this.authService.refreshInProgress.set(false))
            );
    }

    private handleUnauthorizedError(
        error: any,
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<never> {
        if (error instanceof HttpErrorResponse && error.status === 401) {
            this.authService.signOut();
            this.router.navigate(['/']);
        }
        return throwError(() => error);
    }
}
