import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRoute, Router } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { Attribute } from "src/app/interfaces/core/common/attribute";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { AttributeService } from "src/app/services/core/common/attribute.service";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";

@Component({
  selector: "app-attribute-create-update",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
  ],
  templateUrl: "./attribute-create-update.component.html",
  styleUrls: ["./attribute-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class AttributeCreateUpdateComponent implements OnInit {
  attributeForm: FormGroup;
  isUpdateMode = false;
  attributeId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];
  attributeTypes: string[] = ["string", "number", "boolean", "date", "object"];

  constructor(
    private formBuilder: FormBuilder,
    private attributeService: AttributeService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.attributeForm = this.formBuilder.group({
      label: ["", Validators.required],
      type: ["", Validators.required],
      lov: [""],
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "COMMON.ATTRIBUTE.TEXT",
        "COMMON.ATTRIBUTE.CREATE.TITLE",
        "COMMON.ATTRIBUTE.EDIT.TITLE",
      ])
      .subscribe((translations) => {
        this.breadcrumbItems = [
          { label: translations["SHARED.HOME"], link: "/" },
          { label: translations["COMMON.ATTRIBUTE.TEXT"], link: "/apps/common/attribute" },
          {
            label: this.isUpdateMode
              ? translations["COMMON.ATTRIBUTE.EDIT.TITLE"]
              : translations["COMMON.ATTRIBUTE.CREATE.TITLE"],
            active: true,
          },
        ];
      });
  }

  checkForUpdateMode(): void {
    this.attributeId = this.route.snapshot.paramMap.get("id");
    if (this.attributeId) {
      this.isUpdateMode = true;
      this.loadAttributeData();
    }
  }

  loadAttributeData(): void {
    if (this.attributeId) {
      this.spinner.show();
      this.attributeService
        .findOne(this.attributeId)
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: (response) => {
            this.attributeForm.patchValue(response.payload);
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error loading attribute:", error);
            this.showErrorAlert("COMMON.ATTRIBUTE.ERROR.LOAD_ATTRIBUTE");
            this.spinner.hide();
          },
        });
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.attributeForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  onSubmit(): void {
    this.isFormSubmitted = true;

    if (this.attributeForm.valid) {
      this.spinner.show();
      const attributeData: Attribute = this.attributeForm.value;

      if (this.isUpdateMode && this.attributeId) {
        this.attributeService
          .update(this.attributeId, attributeData)
          .pipe(takeUntil(this.subscribeDestroyer$))
          .subscribe({
            next: () => {
              this.showSuccessAlert("COMMON.ATTRIBUTE.UPDATED");
              this.router.navigate(["/apps/common/attribute"]);
              this.spinner.hide();
            },
            error: (error) => {
              console.error("Error updating attribute:", error);
              this.showErrorAlert("COMMON.ATTRIBUTE.ERROR.UPDATE");
              this.spinner.hide();
            },
          });
      } else {
        this.attributeService
          .create(attributeData)
          .pipe(takeUntil(this.subscribeDestroyer$))
          .subscribe({
            next: () => {
              this.showSuccessAlert("COMMON.ATTRIBUTE.CREATED");
              this.router.navigate(["/apps/common/attribute"]);
              this.spinner.hide();
            },
            error: (error) => {
              console.error("Error creating attribute:", error);
              this.showErrorAlert("COMMON.ATTRIBUTE.ERROR.CREATE");
              this.spinner.hide();
            },
          });
      }
    }
  }

  cancel(): void {
    this.router.navigate(["/apps/common/attribute"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.SUCCESS", messageKey])
      .subscribe((translations) => {
        Swal.fire(
          translations["SHARED.SUCCESS"],
          translations[messageKey],
          "success"
        );
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe((translations) => {
        Swal.fire(
          translations["SHARED.ERROR"],
          translations[messageKey],
          "error"
        );
      });
  }
}