import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/interfaces/core/configuration/configuration';
import { DefaultResponsePayload } from 'src/app/interfaces/dtos/default-response-payload';
import { getPagination } from 'src/app/interfaces/dtos/filter';
import { FindAllResponse } from 'src/app/interfaces/dtos/find-all-response';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private readonly apiUrl = `${environment.API_BASE_URL}/configuration`;

  constructor(private http: HttpClient) {}

  findAll(pagination?: PaginationDto): Observable<FindAllResponse<Configuration>> {
    const params = getPagination(pagination);
    return this.http.get<FindAllResponse<Configuration>>(this.apiUrl, { params });
  }

  findOne(id: string): Observable<DefaultResponsePayload<Configuration>> {
    return this.http.get<DefaultResponsePayload<Configuration>>(`${this.apiUrl}/${id}`);
  }

  create(payload: Configuration): Observable<DefaultResponsePayload<Configuration>> {
    return this.http.post<DefaultResponsePayload<Configuration>>(this.apiUrl, payload);
  }

  createBulk(payload: Configuration[]): Observable<DefaultResponsePayload<Configuration[]>> {
    return this.http.post<DefaultResponsePayload<Configuration[]>>(`${this.apiUrl}/bulk`, payload);
  }

  update(id: string, payload: Configuration): Observable<DefaultResponsePayload<Configuration>> {
    return this.http.patch<DefaultResponsePayload<Configuration>>(`${this.apiUrl}/${id}`, payload);
  }

  delete(id: string): Observable<DefaultResponsePayload<Configuration>> {
    return this.http.delete<DefaultResponsePayload<Configuration>>(`${this.apiUrl}/${id}`);
  }

  deleteBulk(ids: string[]): Observable<DefaultResponsePayload<Configuration[]>> {
    return this.http.post<DefaultResponsePayload<Configuration[]>>(`${this.apiUrl}/bulk/delete`, { ids });
  }

  setDefault(id: string): Observable<DefaultResponsePayload<Configuration>> {
    return this.http.patch<DefaultResponsePayload<Configuration>>(`${this.apiUrl}/${id}/default`, {});
  }

  findDefaultByProviderType(type: string): Observable<DefaultResponsePayload<Configuration>> {
    return this.http.get<DefaultResponsePayload<Configuration>>(`${this.apiUrl}/default/${type}`);
  }
}
