<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems"
    [title]="(isUpdateMode ? 'CONFIG.EDIT.TITLE' : 'CONFIG.CREATE.TITLE') | translate"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="configurationForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">{{ 'CONFIG.FORM.NAME' | translate }}</label>
                  <input type="text" class="form-control" id="name" formControlName="name"
                    [ngClass]="{'is-invalid': hasError('name', 'required')}">
                  @if(hasError('name', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CONFIG.FORM.NAME_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="providerId">{{ 'CONFIG.FORM.PROVIDER' |translate }}</label>
                  <ng-select [items]="providers" bindLabel="name" bindValue="uuid" formControlName="providerId"
                    id="providerId" [ngClass]="{'is-invalid': hasError('providerId', 'required')}">
                  </ng-select>
                  @if(hasError('providerId', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CONFIG.FORM.PROVIDER_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="countryId">{{ 'CONFIG.FORM.COUNTRY' | translate }}</label>
                  <ng-select [items]="countries" bindLabel="name" bindValue="uuid" formControlName="countryId"
                    id="countryId" [ngClass]="{'is-invalid': hasError('countryId', 'required')}">
                  </ng-select>
                  @if(hasError('countryId', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CONFIG.FORM.COUNTRY_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="deviseId">{{ 'CONFIG.FORM.DEVISE' | translate }}</label>
                  <ng-select [items]="devises" bindLabel="code" bindValue="uuid" formControlName="deviseId"
                    id="deviseId" [ngClass]="{'is-invalid': hasError('deviseId', 'required')}">
                  </ng-select>
                  @if(hasError('deviseId', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CONFIG.FORM.DEVISE_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="conversionRate">{{ 'CONFIG.FORM.CONVERSION_RATE' | translate }}</label>
                  <input type="number" class="form-control" id="conversionRate" formControlName="conversionRate"
                    [ngClass]="{'is-invalid': hasError('conversionRate', 'required') || hasError('conversionRate', 'min')}">
                  @if(hasError('conversionRate', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CONFIG.FORM.CONVERSION_RATE_REQUIRED' | translate }}
                  </div>
                  }
                  @if(hasError('conversionRate', 'min')){
                  <div class="invalid-feedback">
                    {{ 'CONFIG.FORM.CONVERSION_RATE_MIN' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <app-attribute-management [entityAttributes]="configurationAttributes" [allAttributes]="allAttributes"
                (attributesChanged)="onAttributesChanged($event)">
              </app-attribute-management>
            </div>

            <div class="row mt-4">
              <div class="col-12 text-right">
                <button type="button" class="btn btn-secondary me-2" (click)="cancel()">{{ 'SHARED.CANCEL' | translate }}</button>
                <button type="submit" class="btn btn-primary" [disabled]="!configurationForm.valid">
                  {{ (isUpdateMode ? 'SHARED.UPDATE' : 'SHARED.CREATE') | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>