import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from "@angular/forms";
import { NgbModalModule, NgbPaginationModule, NgbModal, NgbAccordionModule, NgbAccordionDirective, NgbAccordionItem, NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionHeader } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { Transaction } from "../../../interfaces/core/payment/transaction";
import { LangChangeEvent, TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { TransactionService } from "src/app/services/core/payment/transaction.service";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { TransactionStatus } from "src/app/interfaces/enums/transaction-status.enum";
import { TransactionType } from "src/app/interfaces/enums/transaction-type.enum";
import { CurrencyFormatPipe } from "../../../pipes/currency-format.pipe";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

interface GroupedTransaction {
  mainTransaction: Transaction;
  feeTransactions: Transaction[];
}

@Component({
  selector: "app-transaction-list",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbAccordionModule,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionBody,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    CurrencyPipe,
    DatePipe,
    CurrencyFormatPipe,
    HasPermissionsDirective
  ],
  templateUrl: "./transaction-list.component.html",
  styleUrls: ["./transaction-list.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class TransactionListComponent implements OnInit {
  groupedTransactions: GroupedTransaction[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: "createdAt",
    orderDirection: "DESC",
    search: "",
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;
  transactionStatuses = Object.values(TransactionStatus);
  transactionTypes = Object.values(TransactionType);
  language: string;

  constructor(
    private transactionService: TransactionService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.language = event.lang;
    });
    this.initBreadcrumbItems();
    this.initFilterForm();
    this.loadTransactions();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(["SHARED.HOME", "PAYMENT.TEXT", "PAYMENT.TRANSACTION.TITLE"])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            { label: translations["PAYMENT.TEXT"], link: "/apps/payment" },
            { label: translations["PAYMENT.TRANSACTION.TITLE"], active: true },
          ];
        },
        error: (error) => {
          console.error("Translation error:", error);
        }
      });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      reference: [""],
      status: [null],
      type: [null],
    });
  }

  async loadTransactions() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.transaction.read'], condition: 'AND', equalsTo: true })) {
      return;
    }
    
    this.spinner.show();
    this.updatePaginationDto();

    this.transactionService
      .findAll(this.paginationDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.groupTransactions(response.payload.content);
          this.collectionSize = response.payload.totalRecords;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading transactions:", error);
          this.showErrorAlert("PAYMENT.TRANSACTION.ERROR.LOAD");
          this.spinner.hide();
        },
      });
  }

  groupTransactions(transactions: Transaction[]): void {
    const grouped: { [key: string]: GroupedTransaction } = {};

    transactions.forEach(transaction => {
      if (!grouped[transaction.paymentId]) {
        grouped[transaction.paymentId] = {
          mainTransaction: null,
          feeTransactions: []
        };
      }

      if (transaction.type === TransactionType.VIRTUAL_CREDIT_DEPOSIT || 
          transaction.type === TransactionType.VIRTUAL_CREDIT_USAGE) {
        grouped[transaction.paymentId].mainTransaction = transaction;
      } else {
        grouped[transaction.paymentId].feeTransactions.push(transaction);
      }
    });

    this.groupedTransactions = Object.values(grouped).filter(group => group.mainTransaction !== null);
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];

    if (filters.reference) {
      this.paginationDto.search = filters.reference;
    }
    if (filters.status) {
      this.paginationDto.params.push({ status: filters.status });
    }
    if (filters.type) {
      this.paginationDto.params.push({ type: filters.type });
    }

    this.filtersActive =
      this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  onPageChange(page: number): void {
    this.paginationDto.page = page;
    this.loadTransactions();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  applyFilters(): void {
    this.paginationDto.page = 1;
    this.loadTransactions();
    this.modalService.dismissAll();
  }

  clearFilters(): void {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: "createdAt",
      orderDirection: "DESC",
      search: "",
      params: [],
    };
    this.filtersActive = false;
    this.loadTransactions();
  }

  viewTransaction(uuid: string): void {
    this.router.navigate(["/apps/payment/transaction", uuid]);
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Translation error:", error);
        }
      });
  }
}