import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from "@angular/forms";
import { NgbModalModule, NgbPaginationModule, NgbModal, NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { Configuration } from "../../../interfaces/core/configuration/configuration";
import { Provider } from "../../../interfaces/core/configuration/provider";
import { Country } from "../../../interfaces/core/common/country";
import { Devise } from "../../../interfaces/core/common/devise";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { ConfigurationService } from "src/app/services/core/configuration/configuration.service";
import { ProviderService } from "src/app/services/core/configuration/provider.service";
import { CountryService } from "src/app/services/core/common/country.service";
import { DeviseService } from "src/app/services/core/common/devise.service";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-configuration-list",
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    CurrencyPipe,
    DecimalPipe,
    HasPermissionsDirective
  ],
  templateUrl: "./configuration-list.component.html",
  styleUrls: ["./configuration-list.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ConfigurationListComponent implements OnInit {
  configurations: Configuration[] = [];
  providers: Provider[] = [];
  countries: Country[] = [];
  devises: Devise[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: "createdAt",
    orderDirection: "DESC",
    search: "",
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;

  constructor(
    private configurationService: ConfigurationService,
    private providerService: ProviderService,
    private countryService: CountryService,
    private deviseService: DeviseService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initBreadcrumbItems();
    this.initFilterForm();
    await this.loadProviders();
    await this.loadCountries();
    await this.loadDevises();
    await this.loadConfigurations();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(["SHARED.HOME", "CONFIG.TEXT", "CONFIG.CONFIG"])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            { label: translations["CONFIG.TEXT"], link: "/apps/config/configuration" },
            { label: translations["CONFIG.CONFIG"], active: true },
          ];
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        }
      });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      name: [""],
      providerId: [null],
      countryId: [null],
      deviseId: [null],
      isDefault: [null],
    });
  }

  async loadProviders(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.provider.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.providerService
      .findAll()
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.providers = response.payload.content;
        },
        error: (error) => {
          console.error("Error loading providers:", error);
          this.showErrorAlert("CONFIG.ERROR.LOAD_PROVIDERS");
        },
      });
  }

  async loadCountries(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.country.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.countryService
      .findAll()
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.countries = response.payload.content;
        },
        error: (error) => {
          console.error("Error loading countries:", error);
          this.showErrorAlert("COMMON.COUNTRY.ERROR.LOAD_COUNTRIES");
        },
      });
  }

  async loadDevises(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.devise.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.deviseService
      .findAll()
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.devises = response.payload.content;
        },
        error: (error) => {
          console.error("Error loading devises:", error);
          this.showErrorAlert("COMMON.CURRENCY.ERROR.LOAD_CURRENCIES");
        },
      });
  }

  async loadConfigurations(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.configuration.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.updatePaginationDto();

    this.configurationService
      .findAll(this.paginationDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.configurations = response.payload.content;
          this.collectionSize = response.payload.totalRecords;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading configurations:", error);
          this.showErrorAlert("CONFIG.ERROR.LOAD_CONFIGURATIONS");
          this.spinner.hide();
        },
      });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];

    if (filters.name) {
      this.paginationDto.search = filters.name;
    }
    if (filters.providerId) {
      this.paginationDto.params.push({ providerId: filters.providerId });
    }
    if (filters.countryId) {
      this.paginationDto.params.push({ countryId: filters.countryId });
    }
    if (filters.deviseId) {
      this.paginationDto.params.push({ deviseId: filters.deviseId });
    }
    if (filters.isDefault !== null) {
      this.paginationDto.params.push({ isDefault: filters.isDefault });
    }

    this.filtersActive = this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  async onPageChange(page: number): Promise<void> {
    this.paginationDto.page = page;
    await this.loadConfigurations();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  async applyFilters(): Promise<void> {
    this.paginationDto.page = 1;
    await this.loadConfigurations();
    this.modalService.dismissAll();
  }

  async clearFilters(): Promise<void> {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: "createdAt",
      orderDirection: "DESC",
      search: "",
      params: [],
    };
    this.filtersActive = false;
    await this.loadConfigurations();
  }

  async createConfiguration(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.configuration.create'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.router.navigate(["/apps/config/configuration/create"]);
  }

  async editConfiguration(uuid: string): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.configuration.update'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.router.navigate(["/apps/config/configuration/edit", uuid]);
  }

  async deleteConfiguration(uuid: string): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.configuration.delete'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.translateService
      .get(["CONFIG.CONFIRM_DELETE", "SHARED.YES", "SHARED.NO", "CONFIG.DELETED", "CONFIG.ERROR.DELETE"])
      .subscribe({
        next: (translations) => {
          Swal.fire({
            title: translations["CONFIG.CONFIRM_DELETE"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: translations["SHARED.YES"],
            cancelButtonText: translations["SHARED.NO"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.configurationService
                .delete(uuid)
                .pipe(takeUntil(this.subscribeDestroyer$))
                .subscribe({
                  next: () => {
                    Swal.fire(translations["CONFIG.DELETED"], "", "success");
                    this.loadConfigurations();
                  },
                  error: (error) => {
                    console.error("Error deleting configuration:", error);
                    this.showErrorAlert("CONFIG.ERROR.DELETE");
                  },
                });
            }
          });
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        }
      });
  }

  async setDefaultConfiguration(uuid: string): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.configuration.update'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.translateService
      .get(["CONFIG.SET_DEFAULT_CONFIRM", "SHARED.YES", "SHARED.NO", "CONFIG.UPDATED", "CONFIG.ERROR.UPDATE"])
      .subscribe({
        next: (translations) => {
          Swal.fire({
            title: translations["CONFIG.SET_DEFAULT_CONFIRM"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: translations["SHARED.YES"],
            cancelButtonText: translations["SHARED.NO"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.configurationService
                .setDefault(uuid)
                .pipe(takeUntil(this.subscribeDestroyer$))
                .subscribe({
                  next: () => {
                    Swal.fire(translations["CONFIG.UPDATED"], "", "success");
                    this.loadConfigurations();
                  },
                  error: (error) => {
                    console.error("Error setting default configuration:", error);
                    this.showErrorAlert("CONFIG.ERROR.UPDATE");
                  },
                });
            }
          });
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        }
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService.get(["SHARED.ERROR", messageKey]).subscribe({
      next: (translations) => {
        Swal.fire(translations["SHARED.ERROR"], translations[messageKey], "error");
      },
      error: (error) => {
        console.error("Error loading translations:", error);
        Swal.fire("Error", "An error occurred", "error");
      }
    });
  }
}