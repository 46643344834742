import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { lastValueFrom, Observable } from "rxjs";
import { Permission } from "src/app/interfaces/core/user/permission";
import { DefaultResponsePayload } from "src/app/interfaces/dtos/default-response-payload";
import { getPagination } from "src/app/interfaces/dtos/filter";
import { FindAllResponse } from "src/app/interfaces/dtos/find-all-response";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "../../utils/local-storage.service";
import { PermissionDirectiveDto } from "src/app/interfaces/dtos/permission-directive-dto";
import { CurrentPermissions } from "src/app/interfaces/dtos/current-permissions";

@Injectable({
  providedIn: "root",
})
export class PermissionService {
  refreshPermission = signal(false);

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  async hasPermission(permission: PermissionDirectiveDto) {
    let hasPermission: boolean = false;
    if (permission.condition == "AND") {
      let currentPermissions = await this.getCurrentUserPermissions();
    
      hasPermission = permission.permissions.every((x) => {
        try {
          let data = currentPermissions.permissions.find((y) => y.code == x);
          return data ? data.code == x : false;
        } catch (error) {}
        return false;
      });
    } else {
      let currentPermissions = await this.getCurrentUserPermissions();

      hasPermission = permission.permissions.some((x) => {
        try {
          let data = currentPermissions.permissions.find((y) => y.code == x);
          return data ? data.code == x : false;
        } catch (error) {}
        return false;
      });
    }
    
    return hasPermission == permission.equalsTo;
  }

  async getCurrentUserPermissions() {
    let fetchAndSavePermissions = async () => {
      let newPermissions = await lastValueFrom(
        this.http.post<DefaultResponsePayload<CurrentPermissions>>(
          `${environment.API_BASE_URL}/user/permission/current-user-permissions`,{}
        )
      );

      this.localStorageService.saveData(
        environment.CURRENT_PERMISSIONS,
        JSON.stringify(newPermissions.payload)
      );
      return newPermissions.payload;
    };
    try {
      let dataString = this.localStorageService.getData(
        environment.CURRENT_PERMISSIONS
      );
      let data: CurrentPermissions = JSON.parse(dataString);
      if (new Date() > new Date(data.dateExpiration)) {
        return fetchAndSavePermissions();
      }
      return data;
    } catch (error) {
      return fetchAndSavePermissions();
    }
  }

  resetCurrentUserPermissions() {
    this.localStorageService.removeData(environment.CURRENT_PERMISSIONS);
    this.refreshPermission.set(true)
    return this.getCurrentUserPermissions();
  }

  findAll(pagination?: PaginationDto): Observable<FindAllResponse<Permission>> {
    let params = getPagination(pagination);

    return this.http.get<FindAllResponse<Permission>>(
      `${environment.API_BASE_URL}/user/permission`,
      {
        params,
      }
    );
  }

  findOne(id: string): Observable<DefaultResponsePayload<Permission>> {
    return this.http.get<DefaultResponsePayload<Permission>>(
      `${environment.API_BASE_URL}/user/permission/${id}`
    );
  }

  create(payload: Permission): Observable<DefaultResponsePayload<Permission>> {
    return this.http.post<DefaultResponsePayload<Permission>>(
      `${environment.API_BASE_URL}/user/permission`,
      payload
    );
  }

  createBulk(
    payload: Permission[]
  ): Observable<DefaultResponsePayload<Permission[]>> {
    return this.http.post<DefaultResponsePayload<Permission[]>>(
      `${environment.API_BASE_URL}/user/permission/bulk`,
      payload
    );
  }

  update(
    id: string,
    payload: Permission
  ): Observable<DefaultResponsePayload<Permission>> {
    return this.http.patch<DefaultResponsePayload<Permission>>(
      `${environment.API_BASE_URL}/user/permission/${id}`,
      payload
    );
  }

  delete(id: string): Observable<DefaultResponsePayload<Permission>> {
    return this.http.delete<DefaultResponsePayload<Permission>>(
      `${environment.API_BASE_URL}/user/permission/${id}`
    );
  }

  deleteBulk(ids: string[]): Observable<DefaultResponsePayload<Permission[]>> {
    return this.http.post<DefaultResponsePayload<Permission[]>>(
      `${environment.API_BASE_URL}/user/permission/bulk/delete`,
      { ids }
    );
  }
}
