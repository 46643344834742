import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LayoutComponent } from "./layouts/layout/layout.component";
import { AuthGuard } from "./guards/canActivate/auth.guard";
import { DashboardComponent } from "./modules/dashboard/dashboard.component";
import { VirtualCreditOperationComponent } from "./modules/payment/virtual-credit-operation/virtual-credit-operation.component";
import { ActionComponent } from "./modules/user/action/action.component";
import { PermissionComponent } from "./modules/user/permission/permission.component";
import { ProfilComponent } from "./modules/user/profil/profil.component";
import { UserComponent } from "./modules/user/user/user.component";
import { ClientListComponent } from "./modules/client/client-list/client-list.component";
import { TypeClientCreateUpdateComponent } from "./modules/client/type-client-list/pages/type-client-create-update/type-client-create-update.component";
import { TypeClientListComponent } from "./modules/client/type-client-list/type-client-list.component";
import { ActivityAreaListComponent } from "./modules/client/activity-area-list/activity-area-list.component";
import { ActivityAreaCreateUpdateComponent } from "./modules/client/activity-area-list/pages/activity-area-create-update/activity-area-create-update.component";
import { DeviseListComponent } from "./modules/common/devise-list/devise-list.component";
import { DeviseCreateUpdateComponent } from "./modules/common/devise-list/pages/devise-create-update/devise-create-update.component";
import { CountryListComponent } from "./modules/common/country-list/country-list.component";
import { CountryCreateUpdateComponent } from "./modules/common/country-list/pages/country-create-update/country-create-update.component";
import { ProviderCreateUpdateComponent } from "./modules/config/provider-list/pages/provider-create-update/provider-create-update.component";
import { ProviderListComponent } from "./modules/config/provider-list/provider-list.component";
import { ConfigurationListComponent } from "./modules/config/configuration-list/configuration-list.component";
import { ConfigurationCreateUpdateComponent } from "./modules/config/configuration-list/pages/configuration-create-update/configuration-create-update.component";
import { FeeListComponent } from "./modules/payment/fee-list/fee-list.component";
import { FeeCreateUpdateComponent } from "./modules/payment/fee-list/pages/fee-create-update/fee-create-update.component";
import { AttributeListComponent } from "./modules/common/attribute-list/attribute-list.component";
import { AttributeCreateUpdateComponent } from "./modules/common/attribute-list/pages/attribute-create-update/attribute-create-update.component";
import { PaymentListComponent } from "./modules/payment/payment-list/payment-list.component";
import { TransactionListComponent } from "./modules/payment/transaction-list/transaction-list.component";
import { TransactionDetailComponent } from "./modules/payment/transaction-list/pages/transaction-detail/transaction-detail.component";
import { PaymentDetailComponent } from "./modules/payment/payment-list/pages/payment-detail/payment-detail.component";
import { ClientCreateUpdateComponent } from "./modules/client/client-list/pages/client-create-update/client-create-update.component";
import { VirtualCreditTransferComponent } from "./modules/payment/virtual-credit-transfer/virtual-credit-transfer.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "apps",
  },
  {
    path: "account",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./account/auth/auth.module").then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: "apps",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", pathMatch: "full", redirectTo: "dashboard" },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },

      {
        path: "user",
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "user" },
          { path: "user", component: UserComponent },
          { path: "profil", component: ProfilComponent },
          { path: "action", component: ActionComponent },
          { path: "permission", component: PermissionComponent },
        ],
      },
      {
        path: "client",
        children: [
          { path: "", pathMatch: "full", redirectTo: "client-list" },
          { path: "client-list", component: ClientListComponent },
          {
            path: "client-list/create",
            component: ClientCreateUpdateComponent,
          },
          {
            path: "client-list/edit/:id",
            component: ClientCreateUpdateComponent,
          },
          { path: "type-client", component: TypeClientListComponent },
          {
            path: "type-client/create",
            component: TypeClientCreateUpdateComponent,
          },
          {
            path: "type-client/edit/:id",
            component: TypeClientCreateUpdateComponent,
          },
          { path: "activity-area", component: ActivityAreaListComponent },
          {
            path: "activity-area/create",
            component: ActivityAreaCreateUpdateComponent,
          },
          {
            path: "activity-area/edit/:id",
            component: ActivityAreaCreateUpdateComponent,
          },
        ],
      },
      {
        path: "payment",
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "payment" },
          { path: "payment", component: PaymentListComponent },
          {
            path: "payment/:id",
            component: PaymentDetailComponent
          },
          { path: "transaction", component: TransactionListComponent },
          { path: "transaction/:id", component: TransactionDetailComponent },
          {
            path: "virtual-credit-operation",
            component: VirtualCreditOperationComponent,
          },
          { path: "fee", component: FeeListComponent },
          { path: "fee/create", component: FeeCreateUpdateComponent },
          { path: "fee/edit/:id", component: FeeCreateUpdateComponent },
          {
            path: 'virtual-credit-transfer',
            component: VirtualCreditTransferComponent
          }
        ],
      },
      {
        path: "config",
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "configuration" },
          { path: "configuration", component: ConfigurationListComponent },
          {
            path: "configuration/create",
            component: ConfigurationCreateUpdateComponent,
          },
          {
            path: "configuration/edit/:id",
            component: ConfigurationCreateUpdateComponent,
          },
          { path: "provider", component: ProviderListComponent },
          { path: "provider/create", component: ProviderCreateUpdateComponent },
          {
            path: "provider/edit/:id",
            component: ProviderCreateUpdateComponent,
          },
        ],
      },
      {
        path: "common",
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "attribute" },
          { path: "attribute", component: AttributeListComponent },
          {
            path: "attribute/create",
            component: AttributeCreateUpdateComponent,
          },
          {
            path: "attribute/edit/:id",
            component: AttributeCreateUpdateComponent,
          },
          { path: "currency", component: DeviseListComponent },
          { path: "currency/create", component: DeviseCreateUpdateComponent },
          { path: "currency/edit/:id", component: DeviseCreateUpdateComponent },
          { path: "country", component: CountryListComponent },
          { path: "country/create", component: CountryCreateUpdateComponent },
          { path: "country/edit/:id", component: CountryCreateUpdateComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
