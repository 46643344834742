import { HttpParams } from '@angular/common/http';
import { PaginationDto } from './pagination-dto';

export const DefaultPagination: PaginationDto = {
    orderField: 'createdAt',
    orderDirection: 'DESC',
    page: 1,
    limit: 50,
    search: '',
    params: [],
};

export const getPagination = (pagination?: PaginationDto): HttpParams => {
    let params = new HttpParams();

    if (!pagination) {
        params = params.set('orderBy', DefaultPagination.orderField!);
        params = params.set(
            'orderDirection',
            DefaultPagination.orderDirection!
        );
        params = params.set('page', 0);
        params = params.set('limit', 0);
        return params;
    }

    params = params.set(
        'orderBy',
        pagination.orderField ?? DefaultPagination.orderField!
    );
    params = params.set(
        'orderDirection',
        pagination.orderDirection ?? DefaultPagination.orderDirection!
    );
    params = params.set('page', pagination.page?.toString() ?? '0');
    params = params.set('limit', pagination.limit?.toString() ?? '0');
    params = params.set('search', pagination.search ?? '');

    if (pagination.params && pagination.params.length > 0) {
        for (let i = 0; i < pagination.params.length; i++) {
            const param = pagination.params[i];
            const key = Object.keys(param)[0];
            const value = Object.values(param)[0];
            params = params.set(
                key,
                value !== undefined ? value!.toString() : ''
            );
        }
    }

    return params;
};
