import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Client } from 'src/app/interfaces/core/client/client';
import { DefaultResponsePayload } from 'src/app/interfaces/dtos/default-response-payload';
import { getPagination } from 'src/app/interfaces/dtos/filter';
import { FindAllResponse } from 'src/app/interfaces/dtos/find-all-response';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ClientService {
    private readonly apiUrl = `${environment.API_BASE_URL}/client`;

    constructor(private http: HttpClient) {}

    findAll(pagination?: PaginationDto): Observable<FindAllResponse<Client>> {
        const params = getPagination(pagination);
        return this.http.get<FindAllResponse<Client>>(this.apiUrl, { params });
    }

    findOne(id: string): Observable<DefaultResponsePayload<Client>> {
        return this.http.get<DefaultResponsePayload<Client>>(`${this.apiUrl}/${id}`);
    }

    create(payload: Client): Observable<DefaultResponsePayload<Client>> {
        return this.http.post<DefaultResponsePayload<Client>>(this.apiUrl, payload);
    }

    createBulk(payload: Client[]): Observable<DefaultResponsePayload<Client[]>> {
        return this.http.post<DefaultResponsePayload<Client[]>>(`${this.apiUrl}/bulk`, payload);
    }

    update(id: string, payload: Client): Observable<DefaultResponsePayload<Client>> {
        return this.http.patch<DefaultResponsePayload<Client>>(`${this.apiUrl}/${id}`, payload);
    }

    delete(id: string): Observable<DefaultResponsePayload<Client>> {
        return this.http.delete<DefaultResponsePayload<Client>>(`${this.apiUrl}/${id}`);
    }

    deleteBulk(ids: string[]): Observable<DefaultResponsePayload<Client[]>> {
        return this.http.post<DefaultResponsePayload<Client[]>>(`${this.apiUrl}/bulk/delete`, { ids });
    }
}
