import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRoute, Router } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProviderAttribute } from "src/app/interfaces/core/configuration/provider-attribute";
import { ProviderAttributeService } from "src/app/services/core/configuration/provider-attribute.service";
import { ProviderService } from "src/app/services/core/configuration/provider.service";
import { AttributeManagementComponent } from "src/app/shared/ui/attribute-management/attribute-management.component";
import { Provider } from "src/app/interfaces/core/configuration/provider";
import { Attribute } from "src/app/interfaces/core/common/attribute";
import { AttributeService } from "src/app/services/core/common/attribute.service";
import { ProviderType } from "src/app/interfaces/enums/provider-type.enum";

@Component({
  selector: "app-provider-create-update",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    AttributeManagementComponent
  ],
  templateUrl: "./provider-create-update.component.html",
  styleUrls: ["./provider-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ProviderCreateUpdateComponent implements OnInit {
  providerForm: FormGroup;
  isUpdateMode = false;
  providerId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];
  providerAttributes: ProviderAttribute[] = [];
  originalProviderAttributes: ProviderAttribute[] = [];
  allAttributes: Attribute[] = [];
  providerTypes = Object.values(ProviderType);

  constructor(
    private formBuilder: FormBuilder,
    private providerService: ProviderService,
    private providerAttributeService: ProviderAttributeService,
    private attributeService: AttributeService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadAllAttributes();
    this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.providerForm = this.formBuilder.group({
      name: ["", Validators.required],
      eyoneExternalId: ["", Validators.required],
      type: ["", Validators.required],
    });
  }

  loadAllAttributes(): void {
    this.spinner.show();
    this.attributeService.findAll({
      limit:0
    }).pipe(
      takeUntil(this.subscribeDestroyer$)
    ).subscribe({
      next: (response) => {
        this.allAttributes = response.payload.content;
        this.spinner.hide();
      },
      error: (error) => {
        console.error("Error loading attributes:", error);
        this.showErrorAlert("COMMON.ATTRIBUTE.ERROR.LOAD_ATTRIBUTES");
        this.spinner.hide();
      }
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "CONFIG.TEXT",
        "CONFIG.PROVIDER_CREATE.TITLE",
        "CONFIG.PROVIDER_EDIT.TITLE",
      ])
      .subscribe((translations) => {
        this.breadcrumbItems = [
          { label: translations["SHARED.HOME"], link: "/" },
          {
            label: translations["CONFIG.TEXT"],
            link: "/apps/config/provider",
          },
          {
            label: this.isUpdateMode
              ? translations["CONFIG.PROVIDER_EDIT.TITLE"]
              : translations["CONFIG.PROVIDER_CREATE.TITLE"],
            active: true,
          },
        ];
      });
  }

  checkForUpdateMode(): void {
    this.providerId = this.route.snapshot.paramMap.get("id");
    if (this.providerId) {
      this.isUpdateMode = true;
      this.loadProviderData();
    }
  }

  loadProviderData(): void {
    if (this.providerId) {
      this.spinner.show();
      this.providerService
        .findOne(this.providerId)
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: (response) => {
            this.providerForm.patchValue(response.payload);
            this.providerAttributes = response.payload.providerAttributes || [];
            this.originalProviderAttributes = [...this.providerAttributes];
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error loading provider:", error);
            this.showErrorAlert("CONFIG.PROVIDER_ERROR.LOAD_PROVIDER");
            this.spinner.hide();
          },
        });
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.providerForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  onSubmit(): void {
    this.isFormSubmitted = true;

    if (this.providerForm.valid) {
      this.spinner.show();
      const providerData: Provider = {
        ...this.providerForm.value,
      };

      if (this.isUpdateMode && this.providerId) {
        this.updateProvider(providerData);
      } else {
        this.createProvider(providerData);
      }
    }
  }

  createProvider(providerData: Provider): void {
    this.providerService
      .create(providerData)
      .pipe(
        switchMap((response) => {
          const providerId = response.payload.uuid;
          const attributeObservables = this.providerAttributes.map(attr => {
            const newAttribute: ProviderAttribute = {
              value: attr.value,
              providerId: providerId,
              attributeId: attr.attributeId
            };
            return this.providerAttributeService.create(newAttribute);
          });
          return forkJoin(attributeObservables);
        }),
        takeUntil(this.subscribeDestroyer$)
      )
      .subscribe({
        next: () => {
          this.showSuccessAlert("CONFIG.PROVIDER_CREATED");
          this.router.navigate(["/apps/config/provider"]);
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error creating provider:", error);
          this.showErrorAlert("CONFIG.PROVIDER_ERROR.CREATE");
          this.spinner.hide();
        },
      });
  }

  updateProvider(providerData: Provider): void {
    if (this.providerId) {
      this.providerService
        .update(this.providerId, providerData)
        .pipe(
          switchMap(() => {
            const attributeObservables = [];

            // Handle deleted attributes
            const deletedAttributes = this.originalProviderAttributes.filter(
              original => !this.providerAttributes.some(current => current.uuid === original.uuid)
            );
            deletedAttributes.forEach(attr => {
              attributeObservables.push(this.providerAttributeService.delete(attr.uuid!));
            });

            // Handle new and updated attributes
            this.providerAttributes.forEach(attr => {
              if (attr.uuid) {
                // Update existing attribute
                attributeObservables.push(this.providerAttributeService.update(attr.uuid, attr));
              } else {
                // Create new attribute
                const newAttribute: ProviderAttribute = {
                  value: attr.value,
                  providerId: this.providerId!,
                  attributeId: attr.attributeId
                };
                attributeObservables.push(this.providerAttributeService.create(newAttribute));
              }
            });

            return forkJoin(attributeObservables);
          }),
          takeUntil(this.subscribeDestroyer$)
        )
        .subscribe({
          next: () => {
            this.showSuccessAlert("CONFIG.PROVIDER_UPDATED");
            this.router.navigate(["/apps/config/provider"]);
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error updating provider:", error);
            this.showErrorAlert("CONFIG.PROVIDER_ERROR.UPDATE");
            this.spinner.hide();
          },
        });
    }
  }

  onAttributesChanged(attributes: ProviderAttribute[]): void {
    this.providerAttributes = attributes;
  }

  cancel(): void {
    this.router.navigate(["/apps/config/provider"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.SUCCESS", messageKey])
      .subscribe((translations) => {
        Swal.fire(
          translations["SHARED.SUCCESS"],
          translations[messageKey],
          "success"
        );
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe((translations) => {
        Swal.fire(
          translations["SHARED.ERROR"],
          translations[messageKey],
          "error"
        );
      });
  }
}