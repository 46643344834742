<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems" title="{{ 'PAYMENT.TRANSACTION.DETAIL' | translate }}"></app-page-title>

  <div class="row">
    <div class="col-12">
      @if (transaction) {
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">
              <i class="mdi mdi-file-document-outline me-2"></i>
              {{ 'PAYMENT.TRANSACTION.MAIN_TRANSACTION' | translate }}
            </h4>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="font-size-14">{{ 'PAYMENT.TRANSACTION.REFERENCE' | translate }}</h5>
                  <p class="text-muted mb-0">{{ transaction.reference }}</p>
                </div>
                <div class="mb-3">
                  <h5 class="font-size-14">{{ 'PAYMENT.TRANSACTION.AMOUNT' | translate }}</h5>
                  <p class="text-muted mb-0">{{ transaction.totalBrutAmount | currencyFormat:transaction.devise?.code }}</p>
                </div>
                <div class="mb-3">
                  <h5 class="font-size-14">{{ 'PAYMENT.TRANSACTION.TYPE' | translate }}</h5>
                  <span [ngClass]="{
                    'badge bg-info': transaction.type === 'VIRTUAL_CREDIT_DEPOSIT',
                    'badge bg-warning': transaction.type === 'VIRTUAL_CREDIT_USAGE'
                  }">
                    {{ transaction.type }}
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="font-size-14">{{ 'PAYMENT.TRANSACTION.STATUS' | translate }}</h5>
                  <span [ngClass]="{
                    'badge bg-success': transaction.payment.status === 'COMPLETED',
                    'badge bg-warning': transaction.payment.status === 'PENDING',
                    'badge bg-danger': transaction.payment.status === 'FAILED' || transaction.payment.status === 'CANCELLED'
                  }">
                    {{ transaction.payment.status }}
                  </span>
                </div>
                <div class="mb-3">
                  <h5 class="font-size-14">{{ 'PAYMENT.TRANSACTION.DATE' | translate }}</h5>
                  <p class="text-muted mb-0">{{ transaction.createdAt | date:(language === 'fr' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm') }}</p>
                </div>
                <div class="mb-3">
                  <h5 class="font-size-14">{{ 'PAYMENT.TRANSACTION.PAYMENT_ID' | translate }}</h5>
                  <p class="text-muted mb-0">{{ transaction.paymentId }}</p>
                </div>
                <div class="mb-3">
                  <h5 class="font-size-14">{{ 'PAYMENT.TRANSACTION.FEE' | translate }}</h5>
                  <p class="text-muted mb-0">{{ transaction.totalFee | currencyFormat:transaction.devise?.code }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        @if (relatedTransactions.length > 0) {
          <div class="card mt-4">
            <div class="card-body">
              <h4 class="card-title mb-4">
                <i class="mdi mdi-link-variant me-2"></i>
                {{ 'PAYMENT.TRANSACTION.RELATED_TRANSACTIONS' | translate }}
              </h4>
              <div class="table-responsive">
                <table class="table table-centered table-nowrap mb-0">
                  <thead class="table-light">
                    <tr>
                      <th>{{ 'PAYMENT.TRANSACTION.REFERENCE' | translate }}</th>
                      <th>{{ 'PAYMENT.TRANSACTION.AMOUNT' | translate }}</th>
                      <th>{{ 'PAYMENT.TRANSACTION.TYPE' | translate }}</th>
                      <th>{{ 'PAYMENT.TRANSACTION.STATUS' | translate }}</th>
                      <th>{{ 'PAYMENT.TRANSACTION.DATE' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (relatedTransaction of relatedTransactions; track relatedTransaction.uuid) {
                    <tr>
                      <td>{{ relatedTransaction.reference }}</td>
                      <td>{{ relatedTransaction.totalBrutAmount | currencyFormat:relatedTransaction.devise?.code }}</td>
                      <td>
                        <span [ngClass]="{
                          'badge bg-info': relatedTransaction.type === 'VIRTUAL_CREDIT_DEPOSIT',
                          'badge bg-warning': relatedTransaction.type === 'VIRTUAL_CREDIT_USAGE',
                          'badge bg-secondary': relatedTransaction.type === 'FEE' || relatedTransaction.type === 'EYONE_FEE'
                        }">
                          {{ relatedTransaction.type }}
                        </span>
                      </td>
                      <td>
                        <span [ngClass]="{
                          'badge bg-success': relatedTransaction.status === 'COMPLETED',
                          'badge bg-warning': relatedTransaction.status === 'PENDING',
                          'badge bg-danger': relatedTransaction.status === 'FAILED' || relatedTransaction.status === 'CANCELLED'
                        }">
                          {{ relatedTransaction.status }}
                        </span>
                      </td>
                      <td>{{ relatedTransaction.createdAt | date:(language === 'fr' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm') }}</td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
      } @else {
        <div class="card">
          <div class="card-body">
            <p class="text-center">{{ 'PAYMENT.TRANSACTION.NOT_FOUND' | translate }}</p>
          </div>
        </div>
      }

      <div class="mt-4 mb-4">
        <div class="col-12">
          <button type="button" class="btn btn-secondary" (click)="goBack()">
            {{ 'SHARED.BACK' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>