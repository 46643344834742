import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from '../utils/local-storage.service';
import { Observable, of, throwError, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from '../core/user/user.service';
import { DefaultResponsePayload } from 'src/app/interfaces/dtos/default-response-payload';
import { LoginDto } from 'src/app/interfaces/dtos/login-dto';
import { LoginResponse } from 'src/app/interfaces/dtos/login-response';
import { RefreshTokenDto } from 'src/app/interfaces/dtos/refresh-token-dto';
import { TokenDto } from 'src/app/interfaces/dtos/token.dto';
import { VerifyEmailResponseDto } from 'src/app/interfaces/dtos/verify-email-response.dto';
import { Users } from 'src/app/interfaces/core/user/user';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private jwtService: JwtHelperService = new JwtHelperService();
  private authenticated = signal(false);
  refreshInProgress = signal(false);
  private httpClient = inject(HttpClient);
  private localStorageService = inject(LocalStorageService);
  private userService = inject(UserService);

  constructor() {
    this.userService.user = this.getUserData();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  set accessToken(token: string) {
    this.localStorageService.saveData(environment.ACCESS_TOKEN_KEY, token);
  }

  get accessToken(): string {
    return (
      this.localStorageService.getData(environment.ACCESS_TOKEN_KEY) ?? ''
    );
  }

  set refreshToken(token: string) {
    this.localStorageService.saveData(environment.REFRESH_TOKEN_KEY, token);
  }

  get refreshToken(): string {
    return (
      this.localStorageService.getData(environment.REFRESH_TOKEN_KEY) ?? ''
    );
  }

  saveLoggedUserInfo(response: LoginResponse): Observable<LoginResponse> {
    this.accessToken = response.token.accessToken;
    this.refreshToken = response.token.refreshToken;
    this.userService.user = response.user;
    this.authenticated.set(true);
    this.localStorageService.saveData(
      environment.USER_KEY,
      JSON.stringify(response.user)
    );
    this.localStorageService.saveData(
      environment.PROFILE_KEY,
      JSON.stringify(response.access.profil)
    );

    return of(response);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  forgotPassword(email: string): Observable<VerifyEmailResponseDto> {
    return this.httpClient.post<VerifyEmailResponseDto>(
      `${environment.API_BASE_URL}/auth/forgot-password/send-verify-code`,
      { email }
    );
  }

  verifyCode(
    email: string,
    code: string
  ): Observable<DefaultResponsePayload<LoginResponse>> {
    return this.httpClient
      .post<DefaultResponsePayload<LoginResponse>>(
        `${environment.API_BASE_URL}/auth/verify-code`,
        {
          email,
          code,
        }
      )
      .pipe(
        tap((response) => {
          return this.saveLoggedUserInfo(response.payload);
        })
      );
  }

  resetPassword(
    password: string
  ): Observable<DefaultResponsePayload<LoginResponse>> {
    return this.httpClient.post<DefaultResponsePayload<LoginResponse>>(
      `${environment.API_BASE_URL}/auth/reset-password`,
      { password }
    );
  }

  signIn(
    credentials: LoginDto
  ): Observable<DefaultResponsePayload<LoginResponse>> {
    if (this.authenticated()) {
      return throwError(() => 'User is already logged in.');
    }

    return this.httpClient
      .post<DefaultResponsePayload<LoginResponse>>(
        `${environment.API_BASE_URL}/auth/login`,
        credentials
      )
      .pipe(
        tap((response) => {
          return this.saveLoggedUserInfo(response.payload);
        })
      );
  }

  refreshTokenRequest(
    payload: RefreshTokenDto
  ): Observable<HttpResponse<DefaultResponsePayload<TokenDto>>> {
    return this.httpClient.post<DefaultResponsePayload<TokenDto>>(
      `${environment.API_BASE_URL}/auth/token/refresh`,
      payload,
      { observe: 'response' }
    );
  }

  signOut(): Observable<any> {
    this.localStorageService.removeData(environment.ACCESS_TOKEN_KEY);
    this.localStorageService.removeData(environment.REFRESH_TOKEN_KEY);
    this.localStorageService.removeData(environment.USER_KEY);
    this.localStorageService.removeData(environment.PROFILE_KEY);
    this.localStorageService.clearData();
    this.authenticated.set(false);
    this.userService.user = null;
    return of(true);
  }

  check(): Observable<boolean> {
    let userInfo = this.getUserData();
    if (!(userInfo || this.accessToken || this.refreshToken)) {
      return of(false);
    }
    if (
      userInfo &&
      !(
        this.jwtService.isTokenExpired(this.accessToken) &&
        this.jwtService.isTokenExpired(this.refreshToken)
      )
    ) {
      return of(true);
    }
    if (!this.accessToken) {
      return of(false);
    }
    if (this.jwtService.isTokenExpired(this.accessToken)) {
      return of(false);
    }
    return of(false);
  }

  isValidate(): boolean {
    return this.getUserData()?.isValidate ?? false;
  }

  public getUserData(): Users | null {
    const data =
      this.localStorageService.getData(environment.USER_KEY) ?? null;
    if (data) {
      try {
        return JSON.parse(data);
      } catch (error) {}
    }
    return null;
  }

  getUserProfil(): string[] | null {
    const data =
      this.localStorageService.getData(environment.PROFILE_KEY) ?? null;
    if (data) {
      try {
        return JSON.parse(data);
      } catch (error) {}
    }
    return null;
  }

  hasAccess(profils: string[]) {
    let currentProfils = this.getUserProfil();
    if (!currentProfils) {
      return false;
    }
    if (!(profils && profils?.length > 0)) {
      return true;
    }
    return profils.some((x) => {
      return currentProfils?.some(
        (y) => y.toLowerCase() == x.toLowerCase()
      );
    });
  }
}
