import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'currencyFormat',
  standalone: true
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number | null | undefined, currencyCode: string = 'XOF'): string {
    if (value == null) {
      return '';
    }

    // Assurez-vous que currencyCode a toujours une valeur
    currencyCode = currencyCode || 'XOF';

    const language = this.translateService.currentLang;

    try {
      // Format the integer part
      const integerFormatter = new Intl.NumberFormat(language === 'fr' ? 'fr-FR' : 'en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });

      const parts = value.toString().split('.');
      const integerPart = integerFormatter.format(parseInt(parts[0]));
      const fractionalPart = parts[1] || '';

      const formattedValue = fractionalPart 
        ? `${integerPart},${fractionalPart}`  // Use comma for French
        : integerPart;

      return `${formattedValue} ${currencyCode}`;
    } catch (error) {
      console.error('Error formatting number:', error);
      return `${value} ${currencyCode}`;
    }
  }
}
