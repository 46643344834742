import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRoute, Router } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { Fee } from "src/app/interfaces/core/payment/fee";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { FeeService } from "src/app/services/core/payment/fee.service";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-fee-create-update",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    HasPermissionsDirective
  ],
  templateUrl: "./fee-create-update.component.html",
  styleUrls: ["./fee-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class FeeCreateUpdateComponent implements OnInit {
  feeForm: FormGroup;
  isUpdateMode = false;
  feeId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private feeService: FeeService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.feeForm = this.formBuilder.group({
      label: ["", Validators.required],
      percentage: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      amount: [0, [Validators.required, Validators.min(0)]],
      isEyoneFee: [false],
      isGlobal: [false],
      isApplicableForIncomingPayment: [false],
      isApplicableForOutgoingPayment: [false]
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "PAYMENT.TEXT",
        "PAYMENT.FEE.CREATE.TITLE",
        "PAYMENT.FEE.EDIT.TITLE",
      ])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            {
              label: translations["PAYMENT.TEXT"],
              link: "/apps/payment/fee",
            },
            {
              label: this.isUpdateMode
                ? translations["PAYMENT.FEE.EDIT.TITLE"]
                : translations["PAYMENT.FEE.CREATE.TITLE"],
              active: true,
            },
          ];
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        }
      });
  }

  async checkForUpdateMode(): Promise<void> {
    this.feeId = this.route.snapshot.paramMap.get("id");
    if (this.feeId) {
      this.isUpdateMode = true;
      await this.loadFeeData();
    }
  }

  async loadFeeData(): Promise<void> {
    if (!this.feeId) return;

    if (!await this.permissionService.hasPermission({ permissions: ['perm.fee.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.feeService
      .findOne(this.feeId)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.feeForm.patchValue(response.payload);
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading fee:", error);
          this.showErrorAlert("PAYMENT.FEE.ERROR.LOAD_FEE");
          this.spinner.hide();
        },
      });
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.feeForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  async onSubmit(): Promise<void> {
    this.isFormSubmitted = true;

    if (this.feeForm.valid) {
      this.spinner.show();
      const feeData: Fee = this.feeForm.value;

      if (this.isUpdateMode && this.feeId) {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.fee.update'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }

        this.feeService
          .update(this.feeId, feeData)
          .pipe(takeUntil(this.subscribeDestroyer$))
          .subscribe({
            next: () => {
              this.showSuccessAlert("PAYMENT.FEE.UPDATED");
              this.router.navigate(["/apps/payment/fee"]);
              this.spinner.hide();
            },
            error: (error) => {
              console.error("Error updating fee:", error);
              this.showErrorAlert("PAYMENT.FEE.ERROR.UPDATE");
              this.spinner.hide();
            },
          });
      } else {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.fee.create'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }

        this.feeService
          .create(feeData)
          .pipe(takeUntil(this.subscribeDestroyer$))
          .subscribe({
            next: () => {
              this.showSuccessAlert("PAYMENT.FEE.CREATED");
              this.router.navigate(["/apps/payment/fee"]);
              this.spinner.hide();
            },
            error: (error) => {
              console.error("Error creating fee:", error);
              this.showErrorAlert("PAYMENT.FEE.ERROR.CREATE");
              this.spinner.hide();
            },
          });
      }
    }
  }

  cancel(): void {
    this.router.navigate(["/apps/payment/fee"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.SUCCESS", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.SUCCESS"],
            translations[messageKey],
            "success"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Success", "Operation completed successfully", "success");
        }
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Error", "An error occurred", "error");
        }
      });
  }
}