<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems" title="{{ 'PAYMENT.LIST' | translate }}"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
            <div class="mb-2 mb-md-0" *appHasPermissions="{
              permissions: ['perm.payment.read'],
              condition: 'OR',
              equalsTo: true
            }">
              <button type="button" class="btn btn-primary waves-effect waves-light" (click)="openFilterModal(filterModal)">
                <i class="mdi mdi-magnify mr-1"></i> {{ 'SHARED.SEARCH' | translate }}
                @if(filtersActive){
                <span class="badge badge-light ml-1">
                  <i class="mdi mdi-filter"></i>
                </span>
                }
              </button>
            </div>
          </div>

          <div class="table-responsive" *appHasPermissions="{
            permissions: ['perm.payment.read'],
            condition: 'OR',
            equalsTo: true
          }">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>{{ 'PAYMENT.REFERENCE' | translate }}</th>
                  <th>{{ 'PAYMENT.AMOUNT' | translate }}</th>
                  <th>{{ 'PAYMENT.METHOD' | translate }}</th>
                  <th>{{ 'PAYMENT.STATUS' | translate }}</th>
                  <th>{{ 'PAYMENT.SENDER' | translate }}</th>
                  <th>{{ 'PAYMENT.RECEIVER' | translate }}</th>
                  <th>{{ 'PAYMENT.DEVISE' | translate }}</th>
                  <th>{{ 'PAYMENT.CONFIGURATION' | translate }}</th>
                  <th>{{ 'PAYMENT.DATE' | translate }}</th>
                  <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                @for (payment of payments; track payment.uuid) {
                <tr>
                  <td>{{ payment.reference }}</td>
                  <td>{{ payment.amount | currencyFormat:payment.devise.code }}</td>
                  <td>
                    <span [ngClass]="{
                      'badge badge-soft-primary': payment.method === 'ORANGE_MONEY',
                      'badge badge-soft-success': payment.method === 'WAVE',
                      'badge badge-soft-info': payment.method === 'CREDIT_CARD',
                      'badge badge-soft-warning': payment.method === 'BANK_TRANSFER'
                    }">
                      {{ payment.method }}
                    </span>
                  </td>
                  <td>
                    <span [ngClass]="{
                      'badge badge-soft-success': payment.status === 'COMPLETED',
                      'badge badge-soft-warning': payment.status === 'PENDING',
                      'badge badge-soft-danger': payment.status === 'FAILED' || payment.status === 'CANCELLED',
                      'badge badge-soft-info': payment.status === 'REFUNDED'
                    }">
                      {{ payment.status }}
                    </span>
                  </td>
                  <td>{{ payment.customerSender?.name }}</td>
                  <td>{{ payment.customerReceiver?.name }}</td>
                  <td>{{ payment.devise?.code }}</td>
                  <td>{{ payment.configuration?.name }}</td>
                  <td>{{ payment.createdAt | date:'medium' }}</td>
                  <td *appHasPermissions="{
                    permissions: ['perm.payment.read'],
                    condition: 'OR',
                    equalsTo: true
                  }">
                    <button type="button" class="btn btn-primary btn-sm btn-rounded" (click)="viewPayment(payment.uuid)">
                      {{ 'SHARED.VIEW' | translate }}
                    </button>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>

          <div class="row mt-4">
            <div class="col-lg-12">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="paginationDto.page"
                [pageSize]="paginationDto.limit" (pageChange)="onPageChange($event)"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filterModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{ 'PAYMENT.FILTER' | translate }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body" *appHasPermissions="{
    permissions: ['perm.payment.read'],
    condition: 'OR',
    equalsTo: true
  }">
    <form [formGroup]="filterForm">
      <div class="form-group">
        <label for="reference">{{ 'PAYMENT.REFERENCE' | translate }}</label>
        <input type="text" class="form-control" id="reference" formControlName="reference">
      </div>
      <div class="form-group">
        <label for="status">{{ 'PAYMENT.STATUS' | translate }}</label>
        <ng-select [items]="paymentStatuses" formControlName="status">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="method">{{ 'PAYMENT.METHOD' | translate }}</label>
        <ng-select [items]="paymentMethods" formControlName="method" id="method">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="configurationId">{{ 'PAYMENT.CONFIGURATION' | translate }}</label>
        <ng-select [items]="configurations" bindLabel="name" bindValue="uuid" formControlName="configurationId"
          id="configurationId">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="customerSenderId">{{ 'PAYMENT.SENDER' | translate }}</label>
        <ng-select [items]="clients" bindLabel="name" bindValue="uuid" formControlName="customerSenderId"
          id="customerSenderId">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="customerReceiverId">{{ 'PAYMENT.RECEIVER' | translate }}</label>
        <ng-select [items]="clients" bindLabel="name" bindValue="uuid" formControlName="customerReceiverId"
          id="customerReceiverId">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="deviseId">{{ 'PAYMENT.DEVISE' | translate }}</label>
        <ng-select [items]="devises" bindLabel="code" bindValue="uuid" formControlName="deviseId" id="deviseId">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="transactionType">{{ 'PAYMENT.TRANSACTION_TYPE' | translate }}</label>
        <ng-select [items]="transactionTypes" formControlName="transactionType" id="transactionType">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="transactionStatus">{{ 'PAYMENT.TRANSACTION_STATUS' | translate }}</label>
        <ng-select [items]="transactionStatuses" formControlName="transactionStatus" id="transactionStatus">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="startDate">{{ 'PAYMENT.START_DATE' | translate }}</label>
        <input type="date" class="form-control" id="startDate" formControlName="startDate">
      </div>
      <div class="form-group">
        <label for="endDate">{{ 'PAYMENT.END_DATE' | translate }}</label>
        <input type="date" class="form-control" id="endDate" formControlName="endDate">
      </div>
    </form>
  </div>
  <div class="modal-footer" *appHasPermissions="{
    permissions: ['perm.payment.read'],
    condition: 'OR',
    equalsTo: true
  }">
    <button type="button" class="btn btn-light" (click)="clearFilters(); modal.close('Close click')">{{ 'SHARED.CLEAR' |
      translate }}</button>
    <button type="button" class="btn btn-primary" (click)="applyFilters()">{{ 'SHARED.APPLY' | translate }}</button>
  </div>
</ng-template>

<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
