// src/app/directives/has-permissions.directive.ts
import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { PermissionDirectiveDto } from '../interfaces/dtos/permission-directive-dto';
import { PermissionService } from '../services/core/user/permission.service';

@Directive({
  standalone: true,
  selector: '[appHasPermissions]',
})
export class HasPermissionsDirective implements OnInit, OnDestroy {
  @Input('appHasPermissions') appHasPermissions?: PermissionDirectiveDto;

  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    if (!this.appHasPermissions) {
      // Si aucune permission n'est spécifiée, afficher l'élément par défaut
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      return;
    }

    // Vérifier les permissions de manière asynchrone
    this.permissionService.hasPermission(this.appHasPermissions)
      .then(hasPerm => {
        if (hasPerm) {
          if (!this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
          }
        } else {
          if (this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
          }
        }
      })
      .catch(err => {
        console.error('Échec de la vérification des permissions', err);
        if (this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

  ngOnDestroy() {
    // Aucun abonnement à nettoyer puisque nous utilisons des promesses
  }
}
