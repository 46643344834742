<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems" title="{{ 'PAYMENT.DETAIL' | translate }}"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          @if (payment) {
            <div class="row">
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.REFERENCE' | translate }}</h5>
                <p>{{ payment.reference }}</p>
              </div>
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.STATUS' | translate }}</h5>
                <p>
                  <span [ngClass]="{
                    'badge badge-soft-success': payment.status === 'COMPLETED',
                    'badge badge-soft-warning': payment.status === 'PENDING',
                    'badge badge-soft-danger': payment.status === 'FAILED' || payment.status === 'CANCELLED',
                    'badge badge-soft-info': payment.status === 'REFUNDED'
                  }">
                    {{ payment.status }}
                  </span>
                </p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.AMOUNT' | translate }}</h5>
                <p>{{ payment.amount | currencyFormat:payment.devise.code }}</p>
              </div>
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.METHOD' | translate }}</h5>
                <p>{{ payment.method }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.SENDER' | translate }}</h5>
                <p>{{ payment.customerSender?.name }}</p>
              </div>
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.RECEIVER' | translate }}</h5>
                <p>{{ payment.customerReceiver?.name }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.CONFIGURATION' | translate }}</h5>
                <p>{{ payment.configuration?.name }}</p>
              </div>
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.DEVISE' | translate }}</h5>
                <p>{{ payment.devise?.code }} - {{ payment.devise?.label }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.SUBJECT' | translate }}</h5>
                <p>{{ payment.subject }}</p>
              </div>
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.CONVERSION_RATE' | translate }}</h5>
                <p>{{ payment.conversionRate }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.ERROR_URL' | translate }}</h5>
                <p>{{ payment.error_url }}</p>
              </div>
              <div class="col-md-6">
                <h5>{{ 'PAYMENT.SUCCESS_URL' | translate }}</h5>
                <p>{{ payment.success_url }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <h5>{{ 'PAYMENT.METADATA' | translate }}</h5>
                <pre>{{ payment.metadata | json }}</pre>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <h5>{{ 'PAYMENT.TRANSACTIONS' | translate }}</h5>
                <table class="table table-centered table-nowrap">
                  <thead>
                    <tr>
                      <th>{{ 'PAYMENT.TRANSACTION_TYPE' | translate }}</th>
                      <th>{{ 'PAYMENT.AMOUNT' | translate }}</th>
                      <th>{{ 'PAYMENT.FEE' | translate }}</th>
                      <th>{{ 'PAYMENT.STATUS' | translate }}</th>
                      <th>{{ 'PAYMENT.REFERENCE' | translate }}</th>
                      <th>{{ 'PAYMENT.DATE' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (transaction of payment.transactions; track transaction.uuid) {
                    <tr>
                      <td>
                        <span [ngClass]="{
                          'badge badge-soft-info': transaction.type === 'VIRTUAL_CREDIT_DEPOSIT',
                          'badge badge-soft-warning': transaction.type === 'VIRTUAL_CREDIT_USAGE',
                          'badge badge-soft-primary': transaction.type === 'VIRTUAL_CREDIT_TRANSFER',
                          'badge badge-soft-danger': transaction.type === 'REFUND',
                          'badge badge-soft-secondary': transaction.type === 'FEE' || transaction.type === 'EYONE_FEE'
                        }">
                          {{ getTransactionTypeLabel(transaction.type) }}
                        </span>
                      </td>
                      <td>{{ transaction.totalBrutAmount | currencyFormat:payment.devise?.code }}</td>
                      <td>{{ transaction.totalFee | currencyFormat:payment.devise?.code }}</td>
                      <td>
                        <span [ngClass]="{
                          'badge badge-soft-success': transaction.status === 'COMPLETED',
                          'badge badge-soft-warning': transaction.status === 'PENDING',
                          'badge badge-soft-danger': transaction.status === 'FAILED' || transaction.status === 'CANCELLED'
                        }">
                          {{ getTransactionStatusLabel(transaction.status) }}</span>
                        </td>
                        <td>{{ transaction.reference }}</td>
                        <td>{{ transaction.createdAt | date:'medium' }}</td>
                      </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <h5>{{ 'PAYMENT.HTTP_RESPONSE' | translate }}</h5>
                  <pre>{{ payment.httpResponse | json }}</pre>
                </div>
              </div>
              @if (payment.bulkCashInRequest) {
              <div class="row mt-3">
                <div class="col-md-12">
                  <h5>{{ 'PAYMENT.BULK_CASH_IN_REQUEST' | translate }}</h5>
                  <p>{{ 'PAYMENT.BULK_CASH_IN_ID' | translate }}: {{ payment.bulkCashInRequestId }}</p>
                  <!-- Add more details about bulk cash in request if needed -->
                </div>
              </div>
              }
              <div class="row mt-3">
                <div class="col-md-6">
                  <h5>{{ 'PAYMENT.DATE' | translate }}</h5>
                  <p>{{ payment.createdAt | date:'medium' }}</p>
                </div>
              </div>
            } @else {
              <p>{{ 'PAYMENT.NOT_FOUND' | translate }}</p>
            }
            <div class="row mt-4">
              <div class="col-12">
                <button type="button" class="btn btn-secondary" (click)="goBack()">
                  {{ 'SHARED.BACK' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ngx-spinner type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
  </ngx-spinner>