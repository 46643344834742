<div class="container-fluid">
    <app-page-title [breadcrumbItems]="breadcrumbItems"
        [title]="(isUpdateMode ? 'USER.APP_FEATURE.EDIT.TITLE' : 'USER.APP_FEATURE.CREATE.TITLE') | translate">
    </app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="appFeatureForm" (ngSubmit)="onSubmit()"  *appHasPermissions="{ permissions: [isUpdateMode ? 'perm.appfeature.update' : 'perm.appfeature.create'], condition: 'OR', equalsTo: true }">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name">{{ 'USER.APP_FEATURE.FORM.NAME' | translate }}</label>
                                    <input type="text" id="name" class="form-control" formControlName="name"
                                        [ngClass]="{'is-invalid': appFeatureForm.get('name')?.invalid && (appFeatureForm.get('name')?.dirty || appFeatureForm.get('name')?.touched)}">
                                    <div *ngIf="appFeatureForm.get('name')?.invalid && (appFeatureForm.get('name')?.dirty || appFeatureForm.get('name')?.touched)"
                                        class="invalid-feedback">
                                        {{ 'USER.APP_FEATURE.FORM.NAME_REQUIRED' | translate }}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="description">{{ 'USER.APP_FEATURE.FORM.DESCRIPTION' | translate
                                        }}</label>
                                    <input type="text" id="description" class="form-control"
                                        formControlName="description"
                                        [ngClass]="{'is-invalid': appFeatureForm.get('description')?.invalid && (appFeatureForm.get('description')?.dirty || appFeatureForm.get('description')?.touched)}">
                                    <div *ngIf="appFeatureForm.get('description')?.invalid && (appFeatureForm.get('description')?.dirty || appFeatureForm.get('description')?.touched)"
                                        class="invalid-feedback">
                                        {{ 'USER.APP_FEATURE.FORM.DESCRIPTION_REQUIRED' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-12 text-right">
                                <button type="button" class="btn btn-secondary me-2" (click)="cancel()">
                                    {{ 'SHARED.CANCEL' | translate }}
                                </button>
                                <button type="submit" class="btn btn-primary" [disabled]="!appFeatureForm.valid">
                                    {{ (isUpdateMode ? 'SHARED.UPDATE' : 'SHARED.CREATE') | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>