<div class="container-fluid">
    <app-page-title [breadcrumbItems]="breadcrumbItems"
      [title]="(isUpdateMode ? 'COMMON.ATTRIBUTE.EDIT.TITLE' : 'COMMON.ATTRIBUTE.CREATE.TITLE') | translate"></app-page-title>
  
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form [formGroup]="attributeForm" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="label">{{ 'COMMON.ATTRIBUTE.FORM.LABEL' | translate }}</label>
                    <input type="text" class="form-control" id="label" formControlName="label"
                      [ngClass]="{'is-invalid': hasError('label', 'required')}">
                    @if(hasError('label', 'required')){
                    <div class="invalid-feedback">
                      {{ 'COMMON.ATTRIBUTE.FORM.LABEL_REQUIRED' | translate }}
                    </div>
                    }
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="type">{{ 'COMMON.ATTRIBUTE.FORM.TYPE' | translate }}</label>
                    <ng-select [items]="attributeTypes" bindLabel="label" bindValue="value" formControlName="type"
                      id="type" [ngClass]="{'is-invalid': hasError('type', 'required')}">
                    </ng-select>
                    @if(hasError('type', 'required')){
                    <div class="invalid-feedback">
                      {{ 'COMMON.ATTRIBUTE.FORM.TYPE_REQUIRED' | translate }}
                    </div>
                    }
                  </div>
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="lov">{{ 'COMMON.ATTRIBUTE.FORM.LOV' | translate }}</label>
                    <input type="text" class="form-control" id="lov" formControlName="lov">
                  </div>
                </div>
              </div>
  
              <div class="row mt-4">
                <div class="col-12 text-right">
                  <button type="button" class="btn btn-secondary me-2" (click)="cancel()">{{ 'SHARED.CANCEL' | translate }}</button>
                  <button type="submit" class="btn btn-primary" [disabled]="!attributeForm.valid">
                    {{ (isUpdateMode ? 'SHARED.UPDATE' : 'SHARED.CREATE') | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ngx-spinner type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
  </ngx-spinner>