import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import {
  NgbModalModule,
  NgbPaginationModule,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { Client } from "../../../interfaces/core/client/client";
import { TypeClient } from "../../../interfaces/core/client/type-client";
import { ActivityArea } from "../../../interfaces/core/client/activity-area";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { ActivityAreaService } from "src/app/services/core/client/activity-area.service";
import { ClientService } from "src/app/services/core/client/client.service";
import { TypeClientService } from "src/app/services/core/client/type-client.service";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";
import { CurrencyPipe } from "@angular/common";
import { CurrencyFormatPipe } from "../../../pipes/currency-format.pipe";

@Component({
  selector: "app-client-list",
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    CurrencyPipe,
    CurrencyFormatPipe
],
  templateUrl: "./client-list.component.html",
  styleUrls: ["./client-list.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ClientListComponent implements OnInit {
  clients: Client[] = [];
  typeClients: TypeClient[] = [];
  activityAreas: ActivityArea[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: "createdAt",
    orderDirection: "DESC",
    search: "",
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;

  constructor(
    private clientService: ClientService,
    private typeClientService: TypeClientService,
    private activityAreaService: ActivityAreaService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initBreadcrumbItems();
    this.initFilterForm();
    this.loadTypeClients();
    this.loadActivityAreas();
    this.loadClients();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(["SHARED.HOME", "CLIENT.TEXT", "CLIENT.LIST.TITLE"])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            {
              label: translations["CLIENT.TEXT"],
              link: "/apps/client/client-list",
            },
            { label: translations["CLIENT.LIST.TITLE"], active: true },
          ];
        },
        error: (error) => {
          console.error("Error loading translations:", error);
        }
      });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      name: [""],
      eyoneExternalId: [""],
      phoneNumber: [""],
      typeClientId: [null],
      activityAreaId: [null],
    });
  }

  loadTypeClients(): void {
    this.typeClientService
      .findAll()
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.typeClients = response.payload.content;
        },
        error: (error) => {
          console.error("Error loading type clients:", error);
          this.showErrorAlert("CLIENT.ERROR.LOAD_TYPE_CLIENTS");
        },
      });
  }

  loadActivityAreas(): void {
    this.activityAreaService
      .findAll()
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.activityAreas = response.payload.content;
        },
        error: (error) => {
          console.error("Error loading activity areas:", error);
          this.showErrorAlert("CLIENT.ERROR.LOAD_ACTIVITY_AREAS");
        },
      });
  }

  loadClients(): void {
    this.spinner.show();
    this.updatePaginationDto();

    this.clientService
      .findAll(this.paginationDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.clients = response.payload.content;
          this.collectionSize = response.payload.totalRecords;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading clients:", error);
          this.showErrorAlert("CLIENT.ERROR.LOAD_CLIENTS");
          this.spinner.hide();
        },
      });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];

    if (filters.name) {
      this.paginationDto.search = filters.name;
    }
    if (filters.eyoneExternalId) {
      this.paginationDto.params.push({
        eyoneExternalId: filters.eyoneExternalId,
      });
    }
    if (filters.phoneNumber) {
      this.paginationDto.params.push({
        phoneNumber: filters.phoneNumber,
      });
    }
    if (filters.typeClientId) {
      this.paginationDto.params.push({ typeClientId: filters.typeClientId });
    }
    if (filters.activityAreaId) {
      this.paginationDto.params.push({
        activityAreaId: filters.activityAreaId,
      });
    }

    this.filtersActive =
      this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  onPageChange(page: number): void {
    this.paginationDto.page = page;
    this.loadClients();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  applyFilters(): void {
    this.paginationDto.page = 1;
    this.loadClients();
    this.modalService.dismissAll();
  }

  clearFilters(): void {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: "createdAt",
      orderDirection: "DESC",
      search: "",
      params: [],
    };
    this.filtersActive = false;
    this.loadClients();
  }

  createClient(): void {
    this.router.navigate(["/apps/client/client-list/create"]);
  }

  editClient(uuid: string): void {
    this.router.navigate(["/apps/client/client-list/edit", uuid]);
  }

  deleteClient(uuid: string): void {
    this.translateService
      .get([
        "CLIENT.CONFIRM_DELETE",
        "SHARED.YES",
        "SHARED.NO",
        "CLIENT.DELETED",
        "CLIENT.ERROR.DELETE",
      ])
      .subscribe({
        next: (translations) => {
          Swal.fire({
            title: translations["CLIENT.CONFIRM_DELETE"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: translations["SHARED.YES"],
            cancelButtonText: translations["SHARED.NO"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.clientService
                .delete(uuid)
                .pipe(takeUntil(this.subscribeDestroyer$))
                .subscribe({
                  next: () => {
                    Swal.fire(translations["CLIENT.DELETED"], "", "success");
                    this.loadClients();
                  },
                  error: (error) => {
                    console.error("Error deleting client:", error);
                    this.showErrorAlert("CLIENT.ERROR.DELETE");
                  },
                });
            }
          });
        },
        error: (error) => {
          console.error("Error loading translations:", error);
        }
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
        }
      });
  }
}