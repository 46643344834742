import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRoute, Router } from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { Configuration } from "src/app/interfaces/core/configuration/configuration";
import { Provider } from "src/app/interfaces/core/configuration/provider";
import { Country } from "src/app/interfaces/core/common/country";
import { Devise } from "src/app/interfaces/core/common/devise";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { ConfigurationService } from "src/app/services/core/configuration/configuration.service";
import { ProviderService } from "src/app/services/core/configuration/provider.service";
import { CountryService } from "src/app/services/core/common/country.service";
import { DeviseService } from "src/app/services/core/common/devise.service";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { AttributeManagementComponent } from "src/app/shared/ui/attribute-management/attribute-management.component";
import { Attribute } from "src/app/interfaces/core/common/attribute";
import { AttributeService } from "src/app/services/core/common/attribute.service";
import { ConfigurationAttribute } from "src/app/interfaces/core/configuration/configuration-attribute";
import { ConfigurationAttributeService } from "src/app/services/core/configuration/configuration-attribute.service";
import { forkJoin, of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-configuration-create-update",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    AttributeManagementComponent,
  ],
  templateUrl: "./configuration-create-update.component.html",
  styleUrls: ["./configuration-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ConfigurationCreateUpdateComponent implements OnInit {
  configurationForm: FormGroup;
  providers: Provider[] = [];
  countries: Country[] = [];
  devises: Devise[] = [];
  isUpdateMode = false;
  configurationId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];
  configurationAttributes: ConfigurationAttribute[] = [];
  originalConfigurationAttributes: ConfigurationAttribute[] = [];
  allAttributes: Attribute[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private configurationAttributeService: ConfigurationAttributeService,
    private providerService: ProviderService,
    private countryService: CountryService,
    private deviseService: DeviseService,
    private attributeService: AttributeService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadProviders();
    this.loadCountries();
    this.loadDevises();
    this.loadAllAttributes();
    this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.configurationForm = this.formBuilder.group({
      name: ["", Validators.required],
      providerId: ["", Validators.required],
      countryId: ["", Validators.required],
      deviseId: ["", Validators.required],
      conversionRate: [0, [Validators.required, Validators.min(0)]],
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "CONFIG.TEXT",
        "CONFIG.CREATE.TITLE",
        "CONFIG.EDIT.TITLE",
      ])
      .subscribe((translations) => {
        this.breadcrumbItems = [
          { label: translations["SHARED.HOME"], link: "/" },
          {
            label: translations["CONFIG.TEXT"],
            link: "/apps/config/configuration",
          },
          {
            label: this.isUpdateMode
              ? translations["CONFIG.EDIT.TITLE"]
              : translations["CONFIG.CREATE.TITLE"],
            active: true,
          },
        ];
      });
  }

  loadProviders(): void {
    this.spinner.show();
    this.providerService
      .findAll({ limit: 0 })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.providers = response.payload.content;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading providers:", error);
          this.showErrorAlert("CONFIG.ERROR.LOAD_PROVIDERS");
          this.spinner.hide();
        },
      });
  }

  loadCountries(): void {
    this.spinner.show();
    this.countryService
      .findAll({ limit: 0 })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.countries = response.payload.content;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading countries:", error);
          this.showErrorAlert("COMMON.COUNTRY.ERROR.LOAD_COUNTRIES");
          this.spinner.hide();
        },
      });
  }

  loadDevises(): void {
    this.spinner.show();
    this.deviseService
      .findAll({ limit: 0 })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.devises = response.payload.content;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading devises:", error);
          this.showErrorAlert("COMMON.CURRENCY.ERROR.LOAD_CURRENCIES");
          this.spinner.hide();
        },
      });
  }

  loadAllAttributes(): void {
    this.spinner.show();
    this.attributeService
      .findAll({
        limit: 0,
      })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.allAttributes = response.payload.content;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading attributes:", error);
          this.showErrorAlert("COMMON.ATTRIBUTE.ERROR.LOAD_ATTRIBUTES");
          this.spinner.hide();
        },
      });
  }

  checkForUpdateMode(): void {
    this.configurationId = this.route.snapshot.paramMap.get("id");
    if (this.configurationId) {
      this.isUpdateMode = true;
      this.loadConfigurationData();
    }
  }

  loadConfigurationData(): void {
    if (this.configurationId) {
      this.spinner.show();
      this.configurationService
        .findOne(this.configurationId)
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: (response) => {
            const configuration = response.payload;
            this.configurationForm.patchValue({
              name: configuration.name,
              providerId: configuration.providerId,
              countryId: configuration.countryId,
              deviseId: configuration.deviseId,
              conversionRate: configuration.conversionRate,
            });
            this.configurationAttributes =
              configuration.configurationAttributes || [];
            this.originalConfigurationAttributes = [
              ...this.configurationAttributes,
            ];
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error loading configuration:", error);
            this.showErrorAlert("CONFIG.ERROR.LOAD_CONFIGURATION");
            this.spinner.hide();
          },
        });
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.configurationForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  onSubmit(): void {
    this.isFormSubmitted = true;

    if (this.configurationForm.valid) {
      this.spinner.show();
      const configurationData: Configuration = {
        ...this.configurationForm.value,
      };

      if (this.isUpdateMode && this.configurationId) {
        this.updateConfiguration(configurationData);
      } else {
        this.createConfiguration(configurationData);
      }
    }
  }

  createConfiguration(configurationData: Configuration): void {
    this.configurationService
      .create(configurationData)
      .pipe(
        switchMap((response) => {
          const configurationId = response.payload.uuid;
          const attributeObservables = this.configurationAttributes.map(
            (attr) => {
              const newAttribute: ConfigurationAttribute = {
                value: attr.value,
                configurationId: configurationId,
                attributeId: attr.attributeId,
              };
              return this.configurationAttributeService.create(newAttribute);
            }
          );
          return forkJoin(attributeObservables);
        }),
        takeUntil(this.subscribeDestroyer$)
      )
      .subscribe({
        next: () => {
          this.showSuccessAlert("CONFIG.CREATED");
          this.router.navigate(["/apps/config/configuration"]);
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error creating configuration:", error);
          this.showErrorAlert("CONFIG.ERROR.CREATE");
          this.spinner.hide();
        },
      });
  }

  updateConfiguration(configurationData: Configuration): void {
    if (this.configurationId) {
      this.configurationService
        .update(this.configurationId, configurationData)
        .pipe(
          switchMap(() => {
            const attributeObservables = [];

            // Handle deleted attributes
            const deletedAttributes =
              this.originalConfigurationAttributes.filter(
                (original) =>
                  !this.configurationAttributes.some(
                    (current) => current.uuid === original.uuid
                  )
              );
            deletedAttributes.forEach((attr) => {
              attributeObservables.push(
                this.configurationAttributeService.delete(attr.uuid!)
              );
            });

            // Handle new and updated attributes
            this.configurationAttributes.forEach((attr) => {
              if (attr.uuid) {
                // Update existing attribute
                attributeObservables.push(
                  this.configurationAttributeService.update(attr.uuid, attr)
                );
              } else {
                // Create new attribute
                const newAttribute: ConfigurationAttribute = {
                  value: attr.value,
                  configurationId: this.configurationId!,
                  attributeId: attr.attributeId,
                };
                attributeObservables.push(
                  this.configurationAttributeService.create(newAttribute)
                );
              }
            });

            return forkJoin(attributeObservables);
          }),
          takeUntil(this.subscribeDestroyer$)
        )
        .subscribe({
          next: () => {
            this.showSuccessAlert("CONFIG.UPDATED");
            this.router.navigate(["/apps/config/configuration"]);
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error updating configuration:", error);
            this.showErrorAlert("CONFIG.ERROR.UPDATE");
            this.spinner.hide();
          },
        });
    }
  }

  onAttributesChanged(attributes: ConfigurationAttribute[]): void {
    this.configurationAttributes = attributes;
  }

  cancel(): void {
    this.router.navigate(["/apps/config/configuration"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.SUCCESS", messageKey])
      .subscribe((translations) => {
        Swal.fire(
          translations["SHARED.SUCCESS"],
          translations[messageKey],
          "success"
        );
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe((translations) => {
        Swal.fire(
          translations["SHARED.ERROR"],
          translations[messageKey],
          "error"
        );
      });
  }
}
