<div class="container-fluid">
  <app-page-title
    [breadcrumbItems]="breadcrumbItems"
    [title]="
      (isUpdateMode ? 'USER.EDIT.TITLE' : 'USER.CREATE.TITLE') | translate
    "
  >
  </app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form
            [formGroup]="userForm"
            (ngSubmit)="onSubmit()"
            *appHasPermissions="{
              permissions: [
                isUpdateMode ? 'perm.users.update' : 'perm.users.create'
              ],
              condition: 'OR',
              equalsTo: true
            }"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">{{ "USER.FORM.EMAIL" | translate }}</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    formControlName="email"
                    [class.is-invalid]="
                      hasError('email', 'required') ||
                      hasError('email', 'email')
                    "
                  />
                  @if (hasError('email', 'required')) {
                  <div class="invalid-feedback">
                    {{ "USER.FORM.EMAIL_REQUIRED" | translate }}
                  </div>
                  } @else if (hasError('email', 'email')) {
                  <div class="invalid-feedback">
                    {{ "USER.FORM.EMAIL_INVALID" | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="firstName">{{
                    "USER.FORM.FIRST_NAME" | translate
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    formControlName="firstName"
                    [class.is-invalid]="hasError('firstName', 'required')"
                  />
                  @if (hasError('firstName', 'required')) {
                  <div class="invalid-feedback">
                    {{ "USER.FORM.FIRST_NAME_REQUIRED" | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lastName">{{
                    "USER.FORM.LAST_NAME" | translate
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    formControlName="lastName"
                    [class.is-invalid]="hasError('lastName', 'required')"
                  />
                  @if (hasError('lastName', 'required')) {
                  <div class="invalid-feedback">
                    {{ "USER.FORM.LAST_NAME_REQUIRED" | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="profilId">{{
                    "USER.FORM.PROFILE" | translate
                  }}</label>
                  <ng-select
                    [items]="profils"
                    bindLabel="name"
                    bindValue="uuid"
                    formControlName="profilId"
                    id="profilId"
                    [class.is-invalid]="hasError('profilId', 'required')"
                    placeholder="{{ 'SHARED.SELECT' | translate }}"
                  >
                  </ng-select>
                  @if (hasError('profilId', 'required')) {
                  <div class="invalid-feedback">
                    {{ "USER.FORM.PROFILE_REQUIRED" | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="phone">{{ "USER.FORM.PHONE" | translate }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    formControlName="phone"
                    [class.is-invalid]="hasError('phone', 'required')"
                  />
                  @if (hasError('phone', 'required')) {
                  <div class="invalid-feedback">
                    {{ "USER.FORM.PHONE_REQUIRED" | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="address">{{
                    "USER.FORM.ADDRESS" | translate
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    formControlName="address"
                    [class.is-invalid]="hasError('address', 'required')"
                  />
                  @if (hasError('address', 'required')) {
                  <div class="invalid-feedback">
                    {{ "USER.FORM.ADDRESS_REQUIRED" | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="gender">{{
                    "USER.FORM.GENDER" | translate
                  }}</label>
                  <ng-select
                    [items]="[
                      {
                        label: 'USER.GENDER_OPTIONS.MALE' | translate,
                        value: 'MALE'
                      },
                      {
                        label: 'USER.GENDER_OPTIONS.FEMALE' | translate,
                        value: 'FEMALE'
                      },
                      {
                        label: 'USER.GENDER_OPTIONS.OTHER' | translate,
                        value: 'OTHER'
                      }
                    ]"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="gender"
                    id="gender"
                    placeholder="{{ 'SHARED.SELECT' | translate }}"
                  >
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12 text-right">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  (click)="cancel()"
                >
                  {{ "SHARED.CANCEL" | translate }}
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  [disabled]="!userForm.valid"
                >
                  {{
                    (isUpdateMode ? "SHARED.UPDATE" : "SHARED.CREATE")
                      | translate
                  }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">
    {{ "SHARED.LOADING" | translate }}
  </p>
</ngx-spinner>
