import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Payment } from 'src/app/interfaces/core/payment/payment';
import { CashInPaymentDto } from 'src/app/interfaces/dtos/cash-in-payment-dto';
import { DefaultResponsePayload } from 'src/app/interfaces/dtos/default-response-payload';
import { getPagination } from 'src/app/interfaces/dtos/filter';
import { FindAllResponse } from 'src/app/interfaces/dtos/find-all-response';
import { OrangeMoneyBulkCashInPaymentDto } from 'src/app/interfaces/dtos/orange-money-bulk-cash-in-payment-dto';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { WavePayoutBatchDto } from 'src/app/interfaces/dtos/wave-payout-batch-dto';
import { WavePayoutDto } from 'src/app/interfaces/dtos/wave-payout-dto';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    private readonly apiUrl = `${environment.API_BASE_URL}/payment`;

    constructor(private http: HttpClient) {}

    findAll(pagination?: PaginationDto): Observable<FindAllResponse<Payment>> {
        const params = getPagination(pagination);
        return this.http.get<FindAllResponse<Payment>>(this.apiUrl, { params });
    }

    findOne(id: string): Observable<DefaultResponsePayload<Payment>> {
        return this.http.get<DefaultResponsePayload<Payment>>(`${this.apiUrl}/${id}`);
    }

    // New methods for cash-in and bulk cash-in
    cashIn(cashInPaymentDto: CashInPaymentDto): Observable<DefaultResponsePayload<Payment>> {
        return this.http.post<DefaultResponsePayload<Payment>>(`${this.apiUrl}/orange-money/cash-in`, cashInPaymentDto);
    }

    bulkCashIn(bulkCashInDto: OrangeMoneyBulkCashInPaymentDto): Observable<DefaultResponsePayload<any>> {
        return this.http.post<DefaultResponsePayload<any>>(`${this.apiUrl}/orange-money/bulk-cash-in`, bulkCashInDto);
    }

    // New methods for Wave payout and payout batch
    createWavePayout(wavePayoutDto: WavePayoutDto): Observable<DefaultResponsePayload<Payment>> {
        return this.http.post<DefaultResponsePayload<Payment>>(`${this.apiUrl}/wave/payout`, wavePayoutDto);
    }

    createWavePayoutBatch(wavePayoutBatchDto: WavePayoutBatchDto): Observable<DefaultResponsePayload<string>> {
        return this.http.post<DefaultResponsePayload<string>>(`${this.apiUrl}/wave/payout-batch`, wavePayoutBatchDto);
    }

    // New method for payment statistics
    getPaymentStats(startDate: Date, endDate: Date): Observable<DefaultResponsePayload<any>> {
        const params = new HttpParams()
            .set('startDate', startDate.toISOString())
            .set('endDate', endDate.toISOString());
        return this.http.get<DefaultResponsePayload<any>>(`${this.apiUrl}/stats`, { params });
    }

    // New method for client payment history
    getClientPaymentHistory(clientId: string, pagination?: PaginationDto): Observable<FindAllResponse<Payment>> {
        const params = getPagination(pagination);
        return this.http.get<FindAllResponse<Payment>>(`${this.apiUrl}/history/${clientId}`, { params });
    }

    // Existing methods...
    create(payload: Payment): Observable<DefaultResponsePayload<Payment>> {
        return this.http.post<DefaultResponsePayload<Payment>>(this.apiUrl, payload);
    }

    update(id: string, payload: Payment): Observable<DefaultResponsePayload<Payment>> {
        return this.http.patch<DefaultResponsePayload<Payment>>(`${this.apiUrl}/${id}`, payload);
    }

    delete(id: string): Observable<DefaultResponsePayload<Payment>> {
        return this.http.delete<DefaultResponsePayload<Payment>>(`${this.apiUrl}/${id}`);
    }
}