import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { NgbPaginationModule, NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PermissionService } from 'src/app/services/core/user/permission.service';
import { Permission } from 'src/app/interfaces/core/user/permission';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { takeUntil } from 'rxjs/operators';
import { SubscribeDestroyerService } from 'src/app/services/utils/subscribe-destroyer.service';
import Swal from 'sweetalert2';
import { PageTitleComponent } from "../../../shared/ui/pagetitle/pagetitle.component";
import { BreadcrumbItem } from 'src/app/interfaces/dtos/breadcrumb-item';
import { HasPermissionsDirective } from 'src/app/directives/has-permissions.directive';

@Component({
  selector: 'app-permission',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbModalModule,
    TranslateModule,
    PageTitleComponent,
    HasPermissionsDirective
  ],
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
  providers: [SubscribeDestroyerService],
})
export class PermissionComponent implements OnInit {
  permissions: Permission[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: 'createdAt',
    orderDirection: 'DESC',
    search: '',
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;

  constructor(
    private permissionService: PermissionService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private subscribeDestroyer$: SubscribeDestroyerService
  ) {}

  ngOnInit(): void {
    this.initBreadcrumbItems();
    this.initFilterForm();
    this.loadPermissions();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(["SHARED.HOME", "USER.PERMISSION.TEXT", "USER.PERMISSION.LIST.TITLE"])
      .subscribe((translations) => {
        this.breadcrumbItems = [
          { label: translations["SHARED.HOME"], link: "/" },
          { label: translations["USER.PERMISSION.TEXT"], link: "/apps/user/permission" },
          { label: translations["USER.PERMISSION.LIST.TITLE"], active: true },
        ];
      });
  }

  initFilterForm(): void {
    this.filterForm = this.fb.group({
      name: [''],
      code: [''],
      description: [''],
    });
  }

  async loadPermissions() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.permission.read'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.updatePaginationDto();
    this.permissionService.findAll(this.paginationDto).pipe(
      takeUntil(this.subscribeDestroyer$)
    ).subscribe({
      next: (response) => {
        this.permissions = response.payload.content;
        this.collectionSize = response.payload.totalRecords;
      },
      error: (error) => {
        console.error('Error loading permissions:', error);
        this.showErrorAlert('USER.PERMISSION.ERROR.LOAD');
      },
    });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];

    if (filters.name) {
      this.paginationDto.search = filters.name;
    }
    if (filters.code) {
      this.paginationDto.params.push({ code: filters.code });
    }
    if (filters.description) {
      this.paginationDto.params.push({ description: filters.description });
    }

    this.filtersActive = this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  onPageChange(page: number): void {
    this.paginationDto.page = page;
    this.loadPermissions();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  applyFilters(): void {
    this.paginationDto.page = 1;
    this.loadPermissions();
    this.modalService.dismissAll();
  }

  clearFilters(): void {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: 'createdAt',
      orderDirection: 'DESC',
      search: '',
      params: [],
    };
    this.filtersActive = false;
    this.loadPermissions();
  }

  async deletePermission(uuid: string) {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.permission.delete'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.translateService.get(['SHARED.CONFIRM_DELETE', 'SHARED.YES', 'SHARED.NO', 'USER.PERMISSION.DELETED', 'USER.PERMISSION.ERROR.DELETE']).subscribe(translations => {
      Swal.fire({
        title: translations['SHARED.CONFIRM_DELETE'],
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: translations['SHARED.YES'],
        cancelButtonText: translations['SHARED.NO'],
      }).then((result) => {
        if (result.isConfirmed) {
          this.permissionService.delete(uuid).pipe(
            takeUntil(this.subscribeDestroyer$)
          ).subscribe({
            next: () => {
              Swal.fire(translations['USER.PERMISSION.DELETED'], '', 'success');
              this.loadPermissions();
            },
            error: (error) => {
              console.error('Error deleting permission:', error);
              this.showErrorAlert('USER.PERMISSION.ERROR.DELETE');
            },
          });
        }
      });
    });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService.get(['SHARED.ERROR', messageKey]).subscribe(translations => {
      Swal.fire(translations['SHARED.ERROR'], translations[messageKey], 'error');
    });
  }
}
