import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbModalModule, NgbPaginationModule, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { Payment } from "../../../interfaces/core/payment/payment";
import { LangChangeEvent, TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { PaymentService } from "src/app/services/core/payment/payment.service";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { PaymentStatus } from "src/app/interfaces/enums/payment-status.enum";
import { PaymentMethod } from "src/app/interfaces/enums/payment-method.enum";
import { ConfigurationService } from "src/app/services/core/configuration/configuration.service";
import { ClientService } from "src/app/services/core/client/client.service";
import { DeviseService } from "src/app/services/core/common/devise.service";
import { CurrencyFormatPipe } from "../../../pipes/currency-format.pipe";
import { TransactionType } from "src/app/interfaces/enums/transaction-type.enum";
import { TransactionStatus } from "src/app/interfaces/enums/transaction-status.enum";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-payment-list",
  standalone: true,
  imports: [
    RouterModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    CurrencyPipe,
    DatePipe,
    CommonModule,
    CurrencyFormatPipe,
    HasPermissionsDirective
  ],
  templateUrl: "./payment-list.component.html",
  styleUrls: ["./payment-list.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class PaymentListComponent implements OnInit {
  payments: Payment[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: "createdAt",
    orderDirection: "DESC",
    search: "",
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;
  paymentStatuses = Object.values(PaymentStatus);
  paymentMethods = Object.values(PaymentMethod);
  transactionTypes = Object.values(TransactionType);
  transactionStatuses = Object.values(TransactionStatus);
  configurations: any[] = [];
  clients: any[] = [];
  devises: any[] = [];
  language: string;

  constructor(
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private configurationService: ConfigurationService,
    private clientService: ClientService,
    private deviseService: DeviseService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.language = event.lang;
    });
    this.initBreadcrumbItems();
    this.initFilterForm();
    this.loadPayments();
    this.loadConfigurations();
    this.loadClients();
    this.loadDevises();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(["SHARED.HOME", "PAYMENT.TEXT", "PAYMENT.LIST"])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            { label: translations["PAYMENT.TEXT"], link: "/apps/payment" },
            { label: translations["PAYMENT.LIST"], active: true },
          ];
        },
        error: (error) => {
          console.error("Translation error:", error);
        }
      });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      reference: [""],
      status: [null],
      method: [null],
      configurationId: [null],
      customerSenderId: [null],
      customerReceiverId: [null],
      deviseId: [null],
      startDate: [null],
      endDate: [null],
      transactionType: [null],
      transactionStatus: [null],
    });
  }

  async loadPayments() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.payment.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.updatePaginationDto();

    this.paymentService
      .findAll(this.paginationDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.payments = response.payload.content;
          this.collectionSize = response.payload.totalRecords;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading payments:", error);
          this.showErrorAlert("PAYMENT.ERROR.LOAD");
          this.spinner.hide();
        },
      });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];

    if (filters.reference) {
      this.paginationDto.search = filters.reference;
    }
    if (filters.status) {
      this.paginationDto.params.push({ status: filters.status });
    }
    if (filters.method) {
      this.paginationDto.params.push({ method: filters.method });
    }
    if (filters.configurationId) {
      this.paginationDto.params.push({ configurationId: filters.configurationId });
    }
    if (filters.customerSenderId) {
      this.paginationDto.params.push({ customerSenderId: filters.customerSenderId });
    }
    if (filters.customerReceiverId) {
      this.paginationDto.params.push({ customerReceiverId: filters.customerReceiverId });
    }
    if (filters.deviseId) {
      this.paginationDto.params.push({ deviseId: filters.deviseId });
    }
    if (filters.startDate) {
      this.paginationDto.params.push({ startDate: filters.startDate });
    }
    if (filters.endDate) {
      this.paginationDto.params.push({ endDate: filters.endDate });
    }
    if (filters.transactionType) {
      this.paginationDto.params.push({ transactionType: filters.transactionType });
    }
    if (filters.transactionStatus) {
      this.paginationDto.params.push({ transactionStatus: filters.transactionStatus });
    }

    this.filtersActive =
      this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  onPageChange(page: number): void {
    this.paginationDto.page = page;
    this.loadPayments();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  applyFilters(): void {
    this.paginationDto.page = 1;
    this.loadPayments();
    this.modalService.dismissAll();
  }

  clearFilters(): void {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: "createdAt",
      orderDirection: "DESC",
      search: "",
      params: [],
    };
    this.filtersActive = false;
    this.loadPayments();
  }

  viewPayment(uuid: string): void {
    this.router.navigate(["/apps/payment/payment", uuid]);
  }

  async loadConfigurations() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.configuration.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.configurationService
      .findAll({ limit: 0 })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.configurations = response.payload.content;
        },
        error: (error) => {
          console.error("Error loading configurations:", error);
          this.showErrorAlert("CONFIG.ERROR.LOAD_CONFIGURATIONS");
        },
      });
  }

  async loadClients() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.client.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.clientService
      .findAll({ limit: 0 })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.clients = response.payload.content;
        },
        error: (error) => {
          console.error("Error loading clients:", error);
          this.showErrorAlert("CLIENT.ERROR.LOAD_CLIENTS");
        },
      });
  }

  async loadDevises() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.devise.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.deviseService
      .findAll({ limit: 0 })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.devises = response.payload.content;
        },
        error: (error) => {
          console.error("Error loading devises:", error);
          this.showErrorAlert("COMMON.CURRENCY.ERROR.LOAD_CURRENCIES");
        },
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Translation error:", error);
        }
      });
  }
}