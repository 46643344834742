<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems"
    [title]="(isUpdateMode ? 'CLIENT.EDIT.TITLE' : 'CLIENT.CREATE.TITLE') | translate"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="clientForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">{{ 'CLIENT.FORM.NAME' | translate }}</label>
                  <input type="text" class="form-control" id="name" formControlName="name"
                    [ngClass]="{'is-invalid': hasError('name', 'required')}">
                  @if(hasError('name', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CLIENT.FORM.NAME_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="eyoneExternalId">{{ 'CLIENT.FORM.EYONE_ID' | translate }}</label>
                  <input type="text" class="form-control" id="eyoneExternalId" formControlName="eyoneExternalId"
                    [ngClass]="{'is-invalid': hasError('eyoneExternalId', 'required')}">
                  @if(hasError('eyoneExternalId', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CLIENT.FORM.EYONE_ID_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="phoneNumber">{{ 'CLIENT.FORM.PHONE_NUMBER' | translate }}</label>
                  <input type="text" class="form-control" id="phoneNumber" formControlName="phoneNumber"
                    [ngClass]="{'is-invalid': hasError('phoneNumber', 'required')}">
                  @if(hasError('phoneNumber', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CLIENT.FORM.PHONE_NUMBER_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="typeClientId">{{ 'CLIENT.FORM.TYPE' | translate }}</label>
                  <ng-select [items]="typeClients" bindLabel="label" bindValue="uuid" formControlName="typeClientId"
                    id="typeClientId" [ngClass]="{'is-invalid': hasError('typeClientId', 'required')}">
                  </ng-select>
                  @if(hasError('typeClientId', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CLIENT.FORM.TYPE_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="activityAreaId">{{ 'CLIENT.FORM.ACTIVITY_AREA' | translate }}</label>
                  <ng-select [items]="activityAreas" bindLabel="label" bindValue="uuid" formControlName="activityAreaId"
                    id="activityAreaId" [ngClass]="{'is-invalid': hasError('activityAreaId', 'required')}">
                  </ng-select>
                  @if(hasError('activityAreaId', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CLIENT.FORM.ACTIVITY_AREA_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div class="custom-control custom-switch mb-2" dir="ltr">
                    <input type="checkbox" class="custom-control-input" id="send_fees" formControlName="send_fees">
                    <label class="custom-control-label" for="send_fees">{{ 'CLIENT.FORM.SEND_FEES' | translate
                      }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12" *appHasPermissions="{
                permissions: ['perm.client.read'],
                condition: 'OR',
                equalsTo: true
              }">
                <app-client-fee-management [clientFees]="clientFees" (feesChanged)="onFeesChanged($event)">
                </app-client-fee-management>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12" *appHasPermissions="{
                permissions: ['perm.client.read'],
                condition: 'OR',
                equalsTo: true
              }">
                <app-attribute-management [entityAttributes]="clientAttributes" [allAttributes]="allAttributes"
                  (attributesChanged)="onAttributesChanged($event)">
                </app-attribute-management>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12 text-right">
                <button type="button" class="btn btn-secondary me-2" (click)="cancel()" *appHasPermissions="{
                  permissions: ['perm.client.read'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  {{ 'SHARED.CANCEL' | translate }}
                </button>
                <button type="submit" class="btn btn-primary" [disabled]="!clientForm.valid" *appHasPermissions="{
                  permissions: [isUpdateMode ? 'perm.client.update' : 'perm.client.create'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  {{ (isUpdateMode ? 'SHARED.UPDATE' : 'SHARED.CREATE') | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>
