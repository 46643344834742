import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRoute, Router } from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { ActivityArea } from "src/app/interfaces/core/client/activity-area";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { ActivityAreaService } from "src/app/services/core/client/activity-area.service";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-activity-area-create-update",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    HasPermissionsDirective
  ],
  templateUrl: "./activity-area-create-update.component.html",
  styleUrls: ["./activity-area-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ActivityAreaCreateUpdateComponent implements OnInit {
  activityAreaForm: FormGroup;
  isUpdateMode = false;
  activityAreaId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private activityAreaService: ActivityAreaService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.activityAreaForm = this.formBuilder.group({
      code: ["", Validators.required],
      label: ["", Validators.required],
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "CLIENT.TEXT",
        "CLIENT.ACTIVITY.CREATE.TITLE",
        "CLIENT.ACTIVITY.EDIT.TITLE",
      ])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            {
              label: translations["CLIENT.TEXT"],
              link: "/apps/client/activity-area",
            },
            {
              label: this.isUpdateMode
                ? translations["CLIENT.ACTIVITY.EDIT.TITLE"]
                : translations["CLIENT.ACTIVITY.CREATE.TITLE"],
              active: true,
            },
          ];
        }
      });
  }

  checkForUpdateMode(): void {
    this.activityAreaId = this.route.snapshot.paramMap.get("id");
    if (this.activityAreaId) {
      this.isUpdateMode = true;
      this.loadActivityAreaData();
    }
  }

  async loadActivityAreaData(): Promise<void> {
    if (this.activityAreaId) {
      if (!await this.permissionService.hasPermission({ permissions: ['perm.activityarea.read'], condition: 'AND', equalsTo: true })) {
        return;
      }

      this.spinner.show();
      this.activityAreaService
        .findOne(this.activityAreaId)
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: (response) => {
            this.activityAreaForm.patchValue(response.payload);
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error loading activity area:", error);
            this.showErrorAlert("CLIENT.ACTIVITY.ERROR.LOAD");
            this.spinner.hide();
          }
        });
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.activityAreaForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  async onSubmit(): Promise<void> {
    this.isFormSubmitted = true;

    if (this.activityAreaForm.valid) {
      this.spinner.show();
      const activityAreaData: ActivityArea = this.activityAreaForm.value;

      if (this.isUpdateMode && this.activityAreaId) {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.activityarea.update'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }

        this.activityAreaService
          .update(this.activityAreaId, activityAreaData)
          .pipe(takeUntil(this.subscribeDestroyer$))
          .subscribe({
            next: () => {
              this.showSuccessAlert("CLIENT.ACTIVITY.UPDATED");
              this.router.navigate(["/apps/client/activity-area"]);
              this.spinner.hide();
            },
            error: (error) => {
              console.error("Error updating activity area:", error);
              this.showErrorAlert("CLIENT.ACTIVITY.ERROR.UPDATE");
              this.spinner.hide();
            }
          });
      } else {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.activityarea.create'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }

        this.activityAreaService
          .create(activityAreaData)
          .pipe(takeUntil(this.subscribeDestroyer$))
          .subscribe({
            next: () => {
              this.showSuccessAlert("CLIENT.ACTIVITY.CREATED");
              this.router.navigate(["/apps/client/activity-area"]);
              this.spinner.hide();
            },
            error: (error) => {
              console.error("Error creating activity area:", error);
              this.showErrorAlert("CLIENT.ACTIVITY.ERROR.CREATE");
              this.spinner.hide();
            }
          });
      }
    }
  }

  cancel(): void {
    this.router.navigate(["/apps/client/activity-area"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.SUCCESS", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.SUCCESS"],
            translations[messageKey],
            "success"
          );
        }
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        }
      });
  }
}