<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems"
    [title]="(isUpdateMode ? 'CONFIG.PROVIDER_EDIT.TITLE' : 'CONFIG.PROVIDER_CREATE.TITLE') | translate"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="providerForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" *appHasPermissions="{
                  permissions: ['perm.provider.create', 'perm.provider.update'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  <label for="name">{{ 'CONFIG.PROVIDER_FORM.NAME' | translate }}</label>
                  <input type="text" class="form-control" id="name" formControlName="name"
                    [ngClass]="{'is-invalid': hasError('name', 'required')}">
                  @if(hasError('name', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CONFIG.PROVIDER_FORM.NAME_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" *appHasPermissions="{
                  permissions: ['perm.provider.create', 'perm.provider.update'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  <label for="eyoneExternalId">{{ 'CONFIG.PROVIDER_FORM.EYONE_ID' | translate }}</label>
                  <input type="text" class="form-control" id="eyoneExternalId" formControlName="eyoneExternalId"
                    [ngClass]="{'is-invalid': hasError('eyoneExternalId', 'required')}">
                  @if(hasError('eyoneExternalId', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CONFIG.PROVIDER_FORM.EYONE_ID_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group" *appHasPermissions="{
                  permissions: ['perm.provider.create', 'perm.provider.update'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  <label for="type">{{ 'CONFIG.PROVIDER_FORM.TYPE' | translate }}</label>
                  <ng-select [items]="providerTypes" bindLabel="type" bindValue="type" formControlName="type" id="type"
                    [ngClass]="{'is-invalid': hasError('type', 'required')}">
                  </ng-select>
                  @if(hasError('type', 'required')){
                  <div class="invalid-feedback">
                    {{ 'CONFIG.PROVIDER_FORM.TYPE_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row mt-4" *appHasPermissions="{
              permissions: ['perm.provider.update'],
              condition: 'OR',
              equalsTo: true
            }">
              <app-attribute-management [entityAttributes]="providerAttributes" [allAttributes]="allAttributes"
                (attributesChanged)="onAttributesChanged($event)">
              </app-attribute-management>
            </div>

            <div class="row mt-4">
              <div class="col-12 text-right">
                <button type="button" class="btn btn-secondary margin me-2" (click)="cancel()" *appHasPermissions="{
                  permissions: ['perm.provider.read'],
                  condition: 'OR',
                  equalsTo: true
                }">{{ 'SHARED.CANCEL' | translate }}</button>
                <button type="submit" class="btn btn-primary" [disabled]="!providerForm.valid" *appHasPermissions="{
                  permissions: [isUpdateMode ? 'perm.provider.update' : 'perm.provider.create'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  {{ (isUpdateMode ? 'SHARED.UPDATE' : 'SHARED.CREATE') | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>
