import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import MetisMenu from 'metismenujs';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuService } from 'src/app/services/utils/menu.service';
import { EventService } from '../../../core/services/event.service';
import { MenuItem } from './menu.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  menu: any;
  routerSubscription: Subscription;
  @ViewChild('sideMenu', { static: false }) sideMenu!: ElementRef;

  constructor(
    private eventService: EventService,
    private router: Router,
    public menuService: MenuService
  ) {
    // S'abonner aux événements du routeur pour activer le bon menu en fonction de l'URL
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });
  }

  ngOnInit(): void {
    // Charger les éléments du menu depuis le service
    this.menuService.loadMenuItems(); 
  }

  ngAfterViewInit() {
    // Initialiser MetisMenu après la première mise en page
    this.initializeMetisMenu();
  }

  ngOnDestroy() {
    // Se désabonner pour éviter les fuites de mémoire
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.menu) {
      this.menu.dispose(); // Nettoyer l'ancienne instance de MetisMenu
    }
  }

  /**
   * Initialiser MetisMenu
   */
  initializeMetisMenu() {
    setTimeout(() => {
      this.menu = new MetisMenu(this.sideMenu.nativeElement);
      this._activateMenuDropdown();
    }, 100); // Attendre que le DOM soit entièrement rendu avant d'initialiser MetisMenu
  }

  /**
   * Activer le bon menu en fonction de l'URL
   */
  private _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl: HTMLElement | null = null;

    const paths: string[] = [];
    for (let i = 0; i < links.length; i++) {
      const link = links[i] as HTMLAnchorElement;
      paths.push(link.pathname);
    }

    const currentPath = window.location.pathname;
    const itemIndex = paths.indexOf(currentPath);

    if (itemIndex === -1) {
      const strIndex = currentPath.lastIndexOf('/');
      const item = currentPath.substring(0, strIndex);
      const parentIndex = paths.indexOf(item);
      if (parentIndex !== -1) {
        menuItemEl = links[parentIndex] as HTMLElement;
      }
    } else {
      menuItemEl = links[itemIndex] as HTMLElement;
    }

    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement?.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;

          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');

            if (childAnchor) { (childAnchor as HTMLElement).classList.add('mm-active'); }
            if (childDropdown) { (childDropdown as HTMLElement).classList.add('mm-active'); }
          }
        }
      }
    }
  }

  /**
   * Supprimer toutes les classes spécifiées
   * @param className Nom de la classe à supprimer
   */
  private _removeAllClass(className: string) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements[0].classList.remove(className);
    }
  }

  /**
   * Vérifier si un élément de menu a des sous-éléments
   * @param item Élément de menu
   * @returns boolean - Vrai si des sous-éléments sont présents
   */
  hasItems(item: MenuItem): boolean {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
