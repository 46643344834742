import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, ActivatedRoute, Router } from '@angular/router';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PermissionService } from 'src/app/services/core/user/permission.service';
import { Permission } from 'src/app/interfaces/core/user/permission';
import { takeUntil } from 'rxjs/operators';
import { SubscribeDestroyerService } from 'src/app/services/utils/subscribe-destroyer.service';
import Swal from 'sweetalert2';
import { PageTitleComponent } from 'src/app/shared/ui/pagetitle/pagetitle.component';
import { BreadcrumbItem } from 'src/app/interfaces/dtos/breadcrumb-item';
import { HasPermissionsDirective } from 'src/app/directives/has-permissions.directive';

@Component({
  selector: 'app-permission-create-update',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    PageTitleComponent,
    HasPermissionsDirective
  ],
  templateUrl: './permission-create-update.component.html',
  styleUrls: ['./permission-create-update.component.scss'],
  providers: [SubscribeDestroyerService],
})
export class PermissionCreateUpdateComponent implements OnInit {
  permissionForm: FormGroup;
  isUpdateMode = false;
  permissionId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];

  constructor(
    private fb: FormBuilder,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private subscribeDestroyer$: SubscribeDestroyerService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.permissionForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "USER.PERMISSION.TEXT",
        "USER.PERMISSION.CREATE.TITLE",
        "USER.PERMISSION.EDIT.TITLE",
      ])
      .subscribe((translations) => {
        this.breadcrumbItems = [
          { label: translations["SHARED.HOME"], link: "/" },
          {
            label: translations["USER.PERMISSION.TEXT"],
            link: "/apps/user/permission",
          },
          {
            label: this.isUpdateMode
              ? translations["USER.PERMISSION.EDIT.TITLE"]
              : translations["USER.PERMISSION.CREATE.TITLE"],
            active: true,
          },
        ];
      });
  }

  checkForUpdateMode(): void {
    this.permissionId = this.route.snapshot.paramMap.get('id');
    if (this.permissionId) {
      this.isUpdateMode = true;
      this.loadPermissionData();
    }
  }

  async loadPermissionData() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.permission.read'], condition: 'AND', equalsTo: true })) {
      return;
    }
    if (this.permissionId) {
      this.permissionService.findOne(this.permissionId).pipe(
        takeUntil(this.subscribeDestroyer$)
      ).subscribe({
        next: (response) => {
          this.permissionForm.patchValue(response.payload);
        },
        error: (error) => {
          console.error('Error loading permission:', error);
          this.showErrorAlert('USER.PERMISSION.ERROR.LOAD_PERMISSION');
        }
      });
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.permissionForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  async onSubmit(): Promise<void> {
    this.isFormSubmitted = true;
  
    if (this.permissionForm.valid) {
      const permissionData: Permission = this.permissionForm.value;
  
      if (this.isUpdateMode && this.permissionId) {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.permission.update'], condition: 'AND', equalsTo: true })) {
          return;
        }
        this.updatePermission(permissionData);
      } else {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.permission.create'], condition: 'AND', equalsTo: true })) {
          return;
        }
        this.createPermission(permissionData);
      }
    }
  }

  createPermission(permissionData: Permission): void {
    this.permissionService.create(permissionData).pipe(
      takeUntil(this.subscribeDestroyer$)
    ).subscribe({
      next: () => {
        this.showSuccessAlert('USER.PERMISSION.CREATED');
        this.router.navigate(['/apps/user/permission']);
      },
      error: (error) => {
        console.error('Error creating permission:', error);
        this.showErrorAlert('USER.PERMISSION.ERROR.CREATE');
      }
    });
  }

  updatePermission(permissionData: Permission): void {
    if (this.permissionId) {
      this.permissionService.update(this.permissionId, permissionData).pipe(
        takeUntil(this.subscribeDestroyer$)
      ).subscribe({
        next: () => {
          this.showSuccessAlert('USER.PERMISSION.UPDATED');
          this.router.navigate(['/apps/user/permission']);
        },
        error: (error) => {
          console.error('Error updating permission:', error);
          this.showErrorAlert('USER.PERMISSION.ERROR.UPDATE');
        }
      });
    }
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService.get(['SHARED.SUCCESS', messageKey]).subscribe(translations => {
      Swal.fire(translations['SHARED.SUCCESS'], translations[messageKey], 'success');
    });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService.get(['SHARED.ERROR', messageKey]).subscribe(translations => {
      Swal.fire(translations['SHARED.ERROR'], translations[messageKey], 'error');
    });
  }

  cancel(): void {
    this.router.navigate(['/apps/user/permission']);
  }
}
