<div class="container-fluid">
    <app-page-title [breadcrumbItems]="breadcrumbItems" [title]="(isUpdateMode ? 'USER.PERMISSION.EDIT.TITLE' : 'USER.PERMISSION.CREATE.TITLE') | translate"></app-page-title>
  
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form [formGroup]="permissionForm" (ngSubmit)="onSubmit()"  *appHasPermissions="{ permissions: [isUpdateMode ? 'perm.permission.update' : 'perm.permission.create'], condition: 'OR', equalsTo: true }">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="name">{{ 'USER.PERMISSION.FORM.NAME' | translate }}</label>
                    <input type="text" id="name" class="form-control" formControlName="name"
                           [ngClass]="{'is-invalid': hasError('name', 'required')}">
                    @if(hasError('name', 'required')) {
                      <div class="invalid-feedback">
                        {{ 'USER.PERMISSION.FORM.NAME_REQUIRED' | translate }}
                      </div>
                    }
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="code">{{ 'USER.PERMISSION.FORM.CODE' | translate }}</label>
                    <input type="text" id="code" class="form-control" formControlName="code"
                           [ngClass]="{'is-invalid': hasError('code', 'required')}">
                    @if(hasError('code', 'required')) {
                      <div class="invalid-feedback">
                        {{ 'USER.PERMISSION.FORM.CODE_REQUIRED' | translate }}
                      </div>
                    }
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="description">{{ 'USER.PERMISSION.FORM.DESCRIPTION' | translate }}</label>
                    <input type="text" id="description" class="form-control" formControlName="description"
                           [ngClass]="{'is-invalid': hasError('description', 'required')}">
                    @if(hasError('description', 'required')) {
                      <div class="invalid-feedback">
                        {{ 'USER.PERMISSION.FORM.DESCRIPTION_REQUIRED' | translate }}
                      </div>
                    }
                  </div>
                </div>
              </div>
  
              <div class="row mt-4">
                <div class="col-12 text-right">
                  <button type="button" class="btn btn-secondary me-2" (click)="cancel()">
                    {{ 'SHARED.CANCEL' | translate }}
                  </button>
                  <button type="submit" class="btn btn-primary" [disabled]="!permissionForm.valid">
                    {{ (isUpdateMode ? 'SHARED.UPDATE' : 'SHARED.CREATE') | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  