<div class="form-group" *appHasPermissions="{
  permissions: ['perm.fee.read'],
  condition: 'OR',
  equalsTo: true
}">
  <label>{{ 'CLIENT.FORM.FEES' | translate }}</label>
  <div class="d-flex mb-2" *appHasPermissions="{
    permissions: ['perm.fee.create'],
    condition: 'OR',
    equalsTo: true
  }">
    <ng-select class="flex-grow-1 me-2"
               [items]="availableFees"
               bindLabel="label"
               bindValue="uuid"
               [(ngModel)]="selectedFeeId"
               [placeholder]="'CLIENT.FORM.SELECT_FEE' | translate">
    </ng-select>
    <button type="button" class="btn btn-primary" (click)="addFee()" [disabled]="!selectedFeeId">
      {{ 'SHARED.ADD' | translate }}
    </button>
  </div>
  <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th>{{ 'PAYMENT.FEE.LIST.LABEL' | translate }}</th>
        <th>{{ 'PAYMENT.FEE.LIST.PERCENTAGE' | translate }}</th>
        <th>{{ 'PAYMENT.FEE.LIST.AMOUNT' | translate }}</th>
        <th>{{ 'SHARED.ACTIONS' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let fee of clientFees">
        <td>{{ fee.label }}</td>
        <td>{{ fee.percentage }}%</td>
        <td>{{ fee.amount | currencyFormat }}</td>
        <td>
          <button type="button" class="btn btn-sm btn-danger" (click)="removeFee(fee)" *appHasPermissions="{
            permissions: ['perm.fee.delete'],
            condition: 'OR',
            equalsTo: true
          }">
            {{ 'SHARED.REMOVE' | translate }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
