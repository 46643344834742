<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems"
    [title]="(isUpdateMode ? 'USER.PROFIL.EDIT.TITLE' : 'USER.PROFIL.CREATE.TITLE') | translate"></app-page-title>

  <div class="card">
    <div class="card-body">
      <form [formGroup]="profilForm" (ngSubmit)="onSubmit()"   *appHasPermissions="{ permissions: [isUpdateMode ? 'perm.profil.update' : 'perm.profil.create'], condition: 'OR', equalsTo: true }">
        <div class="row mb-4">
          <div class="col-md-6">
            <div class="form-group">
              <label for="name" class="form-label">{{ 'USER.PROFIL.FORM.NAME' | translate }}</label>
              <input type="text" class="form-control" id="name" formControlName="name"
                [ngClass]="{'is-invalid': profilForm.get('name')?.invalid && (profilForm.get('name')?.dirty || profilForm.get('name')?.touched)}">
              <div class="invalid-feedback">
                {{ 'USER.PROFIL.FORM.NAME_REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>

        <h5 class="mb-3">{{ 'USER.PROFIL.FORM.PERMISSIONS' | translate }}</h5>

        @for (appFeature of appFeatures; track appFeature.uuid) {
          <div class="card mb-4 shadow-sm">
            <div class="card-header bg-light d-flex justify-content-between align-items-center">
              <h6 class="mb-0">{{ appFeature.name }}</h6>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" [id]="'check-all-' + appFeature.uuid"
                  [checked]="isAllChecked(appFeature.uuid)" (change)="toggleAll(appFeature.uuid, $event.target.checked)">
                <label class="form-check-label" [for]="'check-all-' + appFeature.uuid">
                  {{ 'USER.PROFIL.FORM.SELECT_ALL' | translate }}
                </label>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th style="width: 40px;"></th>
                      <th>{{ 'USER.PERMISSION.LIST.NAME' | translate }}</th>
                      <th>{{ 'USER.PERMISSION.LIST.CODE' | translate }}</th>
                      <th>{{ 'USER.PERMISSION.LIST.DESCRIPTION' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (permission of permissionsByAppFeature[appFeature.uuid]; track permission.uuid) {
                      <tr>
                        <td>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" [id]="permission.uuid"
                              [checked]="isPermissionChecked(permission)"
                              (change)="onPermissionChange(permission, $event.target.checked)">
                          </div>
                        </td>
                        <td>
                          <label class="form-check-label" [for]="permission.uuid">
                            {{ permission.name }}
                          </label>
                        </td>
                        <td>{{ permission.code }}</td>
                        <td>
                          <span class="text-muted" [title]="permission.description">
                            {{ permission.description | slice:0:50 }}{{ permission.description.length > 50 ? '...' : '' }}
                          </span>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }

        <div class="text-end mt-4">
          <button type="button" class="btn btn-secondary me-2" (click)="cancel()">
            {{ 'SHARED.CANCEL' | translate }}
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!profilForm.valid">
            {{ (isUpdateMode ? 'SHARED.UPDATE' : 'SHARED.CREATE') | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>