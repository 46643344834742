<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems"
    title="{{ 'PAYMENT.FEE.LIST.TITLE' | translate }}"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
            <div class="mb-2 mb-md-0">
              <button type="button" class="btn btn-primary waves-effect waves-light"
                (click)="openFilterModal(filterModal)">
                <i class="mdi mdi-magnify mr-1"></i> {{ 'SHARED.SEARCH' | translate }}
                @if(filtersActive){
                <span class="badge badge-light ml-1">
                  <i class="mdi mdi-filter"></i>
                </span>
                }
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-success btn-rounded waves-effect waves-light" (click)="createFee()">
                <i class="mdi mdi-plus mr-1"></i> {{ 'PAYMENT.FEE.LIST.ADD_NEW' | translate }}
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>{{ 'PAYMENT.FEE.LIST.LABEL' | translate }}</th>
                  <th>{{ 'PAYMENT.FEE.LIST.PERCENTAGE' | translate }}</th>
                  <th>{{ 'PAYMENT.FEE.LIST.AMOUNT' | translate }}</th>
                  <th>{{ 'PAYMENT.FEE.LIST.IS_EYONE_FEE' | translate }}</th>
                  <th>{{ 'PAYMENT.FEE.LIST.IS_GLOBAL' | translate }}</th>
                  <th>{{ 'PAYMENT.FEE.LIST.IS_APPLICABLE_FOR_INCOMING' | translate }}</th>
                  <th>{{ 'PAYMENT.FEE.LIST.IS_APPLICABLE_FOR_OUTGOING' | translate }}</th>
                  <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                @for (fee of fees; track fee.uuid) {
                <tr>
                  <td>{{ fee.label }}</td>
                  <td>{{ fee.percentage }}%</td>
                  <td>{{ fee.amount | currencyFormat }}</td>
                  <td>{{ fee.isEyoneFee ? ('SHARED.YES' | translate) : ('SHARED.NO' | translate) }}</td>
                  <td>{{ fee.isGlobal ? ('SHARED.YES' | translate) : ('SHARED.NO' | translate) }}</td>
                  <td>{{ fee.isApplicableForIncomingPayment ? ('SHARED.YES' | translate) : ('SHARED.NO' | translate) }}
                  </td>
                  <td>{{ fee.isApplicableForOutgoingPayment ? ('SHARED.YES' | translate) : ('SHARED.NO' | translate) }}
                  </td>
                  <td>
                    <button type="button" class="btn btn-primary btn-sm btn-rounded me-2" (click)="editFee(fee.uuid)">
                      {{ 'SHARED.EDIT' | translate }}
                    </button>
                    <button type="button" class="btn btn-danger btn-sm btn-rounded" (click)="deleteFee(fee.uuid)">
                      {{ 'SHARED.DELETE' | translate }}
                    </button>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>

          <div class="row mt-4">
            <div class="col-lg-12">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="paginationDto.page"
                [pageSize]="paginationDto.limit" (pageChange)="onPageChange($event)"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filterModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{ 'PAYMENT.FEE.LIST.FILTER' | translate }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <form [formGroup]="filterForm">
      <div class="form-group">
        <label for="label">{{ 'PAYMENT.FEE.LIST.LABEL' | translate }}</label>
        <input type="text" class="form-control" id="label" formControlName="label">
      </div>
      <div class="form-group">
        <label>{{ 'PAYMENT.FEE.LIST.IS_EYONE_FEE' | translate }}</label>
        <select class="form-control" formControlName="isEyoneFee">
          <option [ngValue]="null">{{ 'SHARED.ALL' | translate }}</option>
          <option [ngValue]="true">{{ 'SHARED.YES' | translate }}</option>
          <option [ngValue]="false">{{ 'SHARED.NO' | translate }}</option>
        </select>
      </div>
      <div class="form-group">
        <label>{{ 'PAYMENT.FEE.LIST.IS_GLOBAL' | translate }}</label>
        <select class="form-control" formControlName="isGlobal">
          <option [ngValue]="null">{{ 'SHARED.ALL' | translate }}</option>
          <option [ngValue]="true">{{ 'SHARED.YES' | translate }}</option>
          <option [ngValue]="false">{{ 'SHARED.NO' | translate }}</option>
        </select>
      </div>
      <div class="form-group">
        <label>{{ 'PAYMENT.FEE.LIST.IS_APPLICABLE_FOR_INCOMING' | translate }}</label>
        <select class="form-control" formControlName="isApplicableForIncomingPayment">
          <option [ngValue]="null">{{ 'SHARED.ALL' | translate }}</option>
          <option [ngValue]="true">{{ 'SHARED.YES' | translate }}</option>
          <option [ngValue]="false">{{ 'SHARED.NO' | translate }}</option>
        </select>
      </div>
      <div class="form-group">
        <label>{{ 'PAYMENT.FEE.LIST.IS_APPLICABLE_FOR_OUTGOING' | translate }}</label>
        <select class="form-control" formControlName="isApplicableForOutgoingPayment">
          <option [ngValue]="null">{{ 'SHARED.ALL' | translate }}</option>
          <option [ngValue]="true">{{ 'SHARED.YES' | translate }}</option>
          <option [ngValue]="false">{{ 'SHARED.NO' | translate }}</option>
        </select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="clearFilters(); modal.close('Close click')">{{ 'SHARED.CLEAR' |
      translate }}</button>
    <button type="button" class="btn btn-primary" (click)="applyFilters()">{{ 'SHARED.APPLY' | translate }}</button>
  </div>
</ng-template>

<ngx-spinner type="ball-scale-multiple"></ngx-spinner>