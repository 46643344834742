<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems" title="{{ 'COMMON.CURRENCY.LIST.TITLE' | translate }}"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
            <div class="mb-2 mb-md-0" *appHasPermissions="{
              permissions: ['perm.currency.read'],
              condition: 'OR',
              equalsTo: true
            }">
              <button type="button" class="btn btn-primary waves-effect waves-light"
                (click)="openFilterModal(filterModal)">
                <i class="mdi mdi-magnify mr-1"></i> {{ 'SHARED.SEARCH' | translate }}
                @if(filtersActive){
                <span class="badge badge-light ml-1">
                  <i class="mdi mdi-filter"></i>
                </span>
                }
              </button>
            </div>
            <div *appHasPermissions="{
              permissions: ['perm.currency.create'],
              condition: 'OR',
              equalsTo: true
            }">
              <button type="button" class="btn btn-success btn-rounded waves-effect waves-light"
                (click)="createDevise()">
                <i class="mdi mdi-plus mr-1"></i> {{ 'COMMON.CURRENCY.LIST.ADD_NEW' | translate }}
              </button>
            </div>
          </div>

          <div class="table-responsive" *appHasPermissions="{
            permissions: ['perm.currency.read'],
            condition: 'OR',
            equalsTo: true
          }">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>{{ 'COMMON.CURRENCY.LIST.CODE' | translate }}</th>
                  <th>{{ 'COMMON.CURRENCY.LIST.LABEL' | translate }}</th>
                  <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                @for (devise of devises; track devise.uuid) {
                <tr>
                  <td>{{ devise.code }}</td>
                  <td>{{ devise.label }}</td>
                  <td>
                    <button *appHasPermissions="{
                      permissions: ['perm.currency.update'],
                      condition: 'OR',
                      equalsTo: true
                    }" type="button" class="btn btn-primary btn-sm btn-rounded me-2"
                      (click)="editDevise(devise.uuid)">
                      {{ 'SHARED.EDIT' | translate }}
                    </button>
                    <button *appHasPermissions="{
                      permissions: ['perm.currency.delete'],
                      condition: 'OR',
                      equalsTo: true
                    }" type="button" class="btn btn-danger btn-sm btn-rounded" (click)="deleteDevise(devise.uuid)">
                      {{ 'SHARED.DELETE' | translate }}
                    </button>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>

          <div class="row mt-4">
            <div class="col-lg-12">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="paginationDto.page"
                [pageSize]="paginationDto.limit" (pageChange)="onPageChange($event)"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filterModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{ 'COMMON.CURRENCY.LIST.FILTER' | translate }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <form [formGroup]="filterForm">
      <div class="form-group">
        <label for="code">{{ 'COMMON.CURRENCY.LIST.CODE' | translate }}</label>
        <input type="text" class="form-control" id="code" formControlName="code">
      </div>
      <div class="form-group">
        <label for="label">{{ 'COMMON.CURRENCY.LIST.LABEL' | translate }}</label>
        <input type="text" class="form-control" id="label" formControlName="label">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="clearFilters(); modal.close('Close click')">{{ 'SHARED.CLEAR' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="applyFilters()">{{ 'SHARED.APPLY' | translate }}</button>
  </div>
</ng-template>

<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
