import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Fee } from 'src/app/interfaces/core/payment/fee';
import { DefaultResponsePayload } from 'src/app/interfaces/dtos/default-response-payload';
import { getPagination } from 'src/app/interfaces/dtos/filter';
import { FindAllResponse } from 'src/app/interfaces/dtos/find-all-response';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class FeeService {
    private readonly apiUrl = `${environment.API_BASE_URL}/fee`;

    constructor(private http: HttpClient) {}

    findAll(pagination?: PaginationDto): Observable<FindAllResponse<Fee>> {
        const params = getPagination(pagination);
        return this.http.get<FindAllResponse<Fee>>(this.apiUrl, { params });
    }

    findOne(id: string): Observable<DefaultResponsePayload<Fee>> {
        return this.http.get<DefaultResponsePayload<Fee>>(`${this.apiUrl}/${id}`);
    }

    create(payload: Fee): Observable<DefaultResponsePayload<Fee>> {
        return this.http.post<DefaultResponsePayload<Fee>>(this.apiUrl, payload);
    }

    createBulk(payload: Fee[]): Observable<DefaultResponsePayload<Fee[]>> {
        return this.http.post<DefaultResponsePayload<Fee[]>>(`${this.apiUrl}/bulk`, payload);
    }

    update(id: string, payload: Fee): Observable<DefaultResponsePayload<Fee>> {
        return this.http.patch<DefaultResponsePayload<Fee>>(`${this.apiUrl}/${id}`, payload);
    }

    delete(id: string): Observable<DefaultResponsePayload<Fee>> {
        return this.http.delete<DefaultResponsePayload<Fee>>(`${this.apiUrl}/${id}`);
    }

    deleteBulk(ids: string[]): Observable<DefaultResponsePayload<Fee[]>> {
        return this.http.post<DefaultResponsePayload<Fee[]>>(`${this.apiUrl}/bulk/delete`, { ids });
    }
}
