import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { Profil } from "src/app/interfaces/core/user/profil";
import { Permission } from "src/app/interfaces/core/user/permission";
import { AppFeature } from "src/app/interfaces/core/user/app-feature";
import { ProfilService } from "src/app/services/core/user/profil.service";
import { PermissionService } from "src/app/services/core/user/permission.service";
import { AppFeatureService } from "src/app/services/core/user/app-feature.service";
import { forkJoin, of } from "rxjs";
import { CommonModule } from "@angular/common";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";

@Component({
  selector: "app-profil-create-update",
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    CommonModule,
    HasPermissionsDirective
  ],
  templateUrl: "./profil-create-update.component.html",
  styleUrls: ["./profil-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ProfilCreateUpdateComponent implements OnInit {
  profilForm: FormGroup;
  isUpdateMode = false;
  profilId: string | null = null;
  breadcrumbItems: BreadcrumbItem[] = [];
  appFeatures: AppFeature[] = [];
  permissionsByAppFeature: { [key: string]: Permission[] } = {};
  allPermissions: Permission[] = [];
  selectedPermissions: Map<string, Permission> = new Map();

  constructor(
    private formBuilder: FormBuilder,
    private profilService: ProfilService,
    private permissionService: PermissionService,
    private appFeatureService: AppFeatureService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.checkForUpdateMode();
    this.initBreadcrumbItems();
    this.loadAppFeaturesAndPermissions();
  }

  initForm(): void {
    this.profilForm = this.formBuilder.group({
      name: ["", Validators.required],
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "USER.TEXT",
        "USER.PROFIL.CREATE.TITLE",
        "USER.PROFIL.EDIT.TITLE",
      ])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            { label: translations["USER.TEXT"], link: "/apps/user/profil" },
            {
              label: this.isUpdateMode
                ? translations["USER.PROFIL.EDIT.TITLE"]
                : translations["USER.PROFIL.CREATE.TITLE"],
              active: true,
            },
          ];
        },
        error: (error) => {
          console.error("Error loading translations:", error);
        },
      });
  }

  checkForUpdateMode(): void {
    this.profilId = this.route.snapshot.paramMap.get("id");
    this.isUpdateMode = !!this.profilId;
  }

  async loadAppFeaturesAndPermissions() {
    if (!await this.permissionService.hasPermission({ 
      permissions: ['perm.appfeature.read', 'perm.permission.read', 'perm.profil.read'], 
      condition: 'AND', 
      equalsTo: true 
    })) {
      return;
    }
    this.spinner.show();
    forkJoin({
      appFeatures: this.appFeatureService.findAll(),
      permissions: this.permissionService.findAll(),
      profil: this.isUpdateMode
        ? this.profilService.findOne(this.profilId!)
        : of(null),
    })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: ({ appFeatures, permissions, profil }) => {
          this.appFeatures = appFeatures.payload.content;
          this.allPermissions = permissions.payload.content;

          this.appFeatures.forEach((appFeature) => {
            this.permissionsByAppFeature[appFeature.uuid] = this.allPermissions.filter(
              (permission) => permission.appFeatureId === appFeature.uuid
            );
          });

          if (this.isUpdateMode && profil) {
            this.profilForm.patchValue({ name: profil.payload.name });
            this.initializeSelectedPermissions(profil.payload);
          }

          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading data:", error);
          this.showErrorAlert("USER.PROFIL.ERROR.LOAD_DATA");
          this.spinner.hide();
        },
      });
  }

  initializeSelectedPermissions(profil: Profil): void {
    this.selectedPermissions.clear();
    
    // Add permissions directly associated with the profile
    profil.permissions.forEach(permission => {
      this.selectedPermissions.set(permission.uuid, permission);
    });

    // Add permissions from app features associated with the profile
    profil.appFeatures.forEach(appFeature => {
      const appFeaturePermissions = this.permissionsByAppFeature[appFeature.uuid] || [];
      appFeaturePermissions.forEach(permission => {
        this.selectedPermissions.set(permission.uuid, permission);
      });
    });
  }
  async onSubmit(): Promise<void> {
    if (this.profilForm.valid) {
      this.spinner.show();
      const profilData: Profil = {
        ...this.profilForm.value,
        permissions: Array.from(this.selectedPermissions.values()),
      };
  
      if (this.isUpdateMode && this.profilId) {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.profil.update'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }
        this.updateProfil(profilData);
      } else {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.profil.create'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }
        this.createProfil(profilData);
      }
    }
  }

  createProfil(profilData: Profil): void {
    this.profilService
      .create(profilData)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: () => {
          this.showSuccessAlert("USER.PROFIL.CREATED");
          this.router.navigate(["/apps/user/profil"]);
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error creating profil:", error);
          this.showErrorAlert("USER.PROFIL.ERROR.CREATE");
          this.spinner.hide();
        },
      });
  }

  updateProfil(profilData: Profil): void {
    if (this.profilId) {
      this.profilService
        .update(this.profilId, profilData)
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: () => {
            this.showSuccessAlert("USER.PROFIL.UPDATED");
            this.router.navigate(["/apps/user/profil"]);
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error updating profil:", error);
            this.showErrorAlert("USER.PROFIL.ERROR.UPDATE");
            this.spinner.hide();
          },
        });
    }
  }

  onPermissionChange(permission: Permission, isChecked: boolean): void {
    if (isChecked) {
      this.selectedPermissions.set(permission.uuid, permission);
    } else {
      this.selectedPermissions.delete(permission.uuid);
    }
  }

  isPermissionChecked(permission: Permission): boolean {
    return this.selectedPermissions.has(permission.uuid);
  }

  cancel(): void {
    this.router.navigate(["/apps/user/profil"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.SUCCESS", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.SUCCESS"],
            translations[messageKey],
            "success"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
        },
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
        },
      });
  }

  isAllChecked(appFeatureId: string): boolean {
    const permissions = this.permissionsByAppFeature[appFeatureId];
    return permissions.every(permission => this.isPermissionChecked(permission));
  }
  
  toggleAll(appFeatureId: string, isChecked: boolean): void {
    const permissions = this.permissionsByAppFeature[appFeatureId];
    permissions.forEach(permission => {
      if (isChecked) {
        this.selectedPermissions.set(permission.uuid, permission);
      } else {
        this.selectedPermissions.delete(permission.uuid);
      }
    });
  }
}