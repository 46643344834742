import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { Profil } from "src/app/interfaces/core/user/profil";
import { DefaultResponsePayload } from "src/app/interfaces/dtos/default-response-payload";
import { getPagination } from "src/app/interfaces/dtos/filter";
import { FindAllResponse } from "src/app/interfaces/dtos/find-all-response";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "../../utils/local-storage.service";
import { PermissionService } from "./permission.service";
import { MenuService } from "../../utils/menu.service";

@Injectable({
  providedIn: "root",
})
export class ProfilService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private permissionService:PermissionService,
    private menuService:MenuService
  ) {}

  findAll(pagination?: PaginationDto): Observable<FindAllResponse<Profil>> {
    let params = getPagination(pagination);

    return this.http.get<FindAllResponse<Profil>>(
      `${environment.API_BASE_URL}/user/profil`,
      {
        params,
      }
    );
  }

  findOne(id: string): Observable<DefaultResponsePayload<Profil>> {
    return this.http.get<DefaultResponsePayload<Profil>>(
      `${environment.API_BASE_URL}/user/profil/${id}`
    );
  }

  create(payload: Profil): Observable<DefaultResponsePayload<Profil>> {
    return this.http.post<DefaultResponsePayload<Profil>>(
      `${environment.API_BASE_URL}/user/profil`,
      payload
    );
  }

  createBulk(payload: Profil[]): Observable<DefaultResponsePayload<Profil[]>> {
    return this.http.post<DefaultResponsePayload<Profil[]>>(
      `${environment.API_BASE_URL}/user/profil/bulk`,
      payload
    );
  }

  update(
    id: string,
    payload: Profil
  ): Observable<DefaultResponsePayload<Profil>> {
    return this.http
      .patch<DefaultResponsePayload<Profil>>(
        `${environment.API_BASE_URL}/user/profil/${id}`,
        payload
      )
      .pipe(
        tap(() => {
          this.permissionService.resetCurrentUserPermissions();
          this.menuService.loadMenuItems();
        })
      );
  }

  delete(id: string): Observable<DefaultResponsePayload<Profil>> {
    return this.http
      .delete<DefaultResponsePayload<Profil>>(
        `${environment.API_BASE_URL}/user/profil/${id}`
      )
      .pipe(
        tap(() => {
          this.permissionService.resetCurrentUserPermissions();
        })
      );
  }

  deleteBulk(ids: string[]): Observable<DefaultResponsePayload<Profil[]>> {
    return this.http
      .post<DefaultResponsePayload<Profil[]>>(
        `${environment.API_BASE_URL}/user/profil/bulk/delete`,
        { ids }
      )
      .pipe(
        tap(() => {
          this.permissionService.resetCurrentUserPermissions();
        })
      );
  }
}
