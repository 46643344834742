import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRoute, Router } from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { Country } from "src/app/interfaces/core/common/country";
import { Devise } from "src/app/interfaces/core/common/devise";
import { CountryService } from "src/app/services/core/common/country.service";
import { DeviseService } from "src/app/services/core/common/devise.service";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-country-create-update",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    HasPermissionsDirective
  ],
  templateUrl: "./country-create-update.component.html",
  styleUrls: ["./country-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class CountryCreateUpdateComponent implements OnInit {
  countryForm: FormGroup;
  devises: Devise[] = [];
  isUpdateMode = false;
  countryId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private deviseService: DeviseService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.loadDevises();
    await this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.countryForm = this.formBuilder.group({
      code: ["", [Validators.required, Validators.maxLength(10)]],
      name: ["", Validators.required],
      timezone: [
        0,
        [Validators.required, Validators.min(-12), Validators.max(14)],
      ],
      indicative: [0, [Validators.required, Validators.min(0)]],
      deviseId: ["", Validators.required],
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get([
        "SHARED.HOME",
        "COMMON.COUNTRY.TEXT",
        "COMMON.COUNTRY.CREATE.TITLE",
        "COMMON.COUNTRY.EDIT.TITLE",
      ])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            {
              label: translations["COMMON.COUNTRY.TEXT"],
              link: "/apps/common/country",
            },
            {
              label: this.isUpdateMode
                ? translations["COMMON.COUNTRY.EDIT.TITLE"]
                : translations["COMMON.COUNTRY.CREATE.TITLE"],
              active: true,
            },
          ];
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        }
      });
  }

  async loadDevises(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.devise.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.deviseService
      .findAll({ limit: 0 })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.devises = response.payload.content;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading devises:", error);
          this.showErrorAlert("COMMON.CURRENCY.ERROR.LOAD_CURRENCIES");
          this.spinner.hide();
        },
      });
  }

  async checkForUpdateMode(): Promise<void> {
    this.countryId = this.route.snapshot.paramMap.get("id");
    if (this.countryId) {
      this.isUpdateMode = true;
      await this.loadCountryData();
    }
  }

  async loadCountryData(): Promise<void> {
    if (!this.countryId) return;

    if (!await this.permissionService.hasPermission({ permissions: ['perm.country.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.countryService
      .findOne(this.countryId)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.countryForm.patchValue(response.payload);
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading country:", error);
          this.showErrorAlert("COMMON.COUNTRY.ERROR.LOAD_COUNTRY");
          this.spinner.hide();
        },
      });
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.countryForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  async onSubmit(): Promise<void> {
    this.isFormSubmitted = true;

    if (this.countryForm.valid) {
      this.spinner.show();
      const countryData: Country = this.countryForm.value;

      if (this.isUpdateMode && this.countryId) {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.country.update'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }

        this.countryService
          .update(this.countryId, countryData)
          .pipe(takeUntil(this.subscribeDestroyer$))
          .subscribe({
            next: () => {
              this.showSuccessAlert("COMMON.COUNTRY.UPDATED");
              this.router.navigate(["/apps/common/country"]);
              this.spinner.hide();
            },
            error: (error) => {
              console.error("Error updating country:", error);
              this.showErrorAlert("COMMON.COUNTRY.ERROR.UPDATE");
              this.spinner.hide();
            },
          });
      } else {
        if (!await this.permissionService.hasPermission({ permissions: ['perm.country.create'], condition: 'AND', equalsTo: true })) {
          this.spinner.hide();
          return;
        }

        this.countryService
          .create(countryData)
          .pipe(takeUntil(this.subscribeDestroyer$))
          .subscribe({
            next: () => {
              this.showSuccessAlert("COMMON.COUNTRY.CREATED");
              this.router.navigate(["/apps/common/country"]);
              this.spinner.hide();
            },
            error: (error) => {
              console.error("Error creating country:", error);
              this.showErrorAlert("COMMON.COUNTRY.ERROR.CREATE");
              this.spinner.hide();
            },
          });
      }
    }
  }

  cancel(): void {
    this.router.navigate(["/apps/common/country"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.SUCCESS", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.SUCCESS"],
            translations[messageKey],
            "success"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Success", "Operation completed successfully", "success");
        }
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Error", "An error occurred", "error");
        }
      });
  }
}