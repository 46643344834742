import { Injectable, signal } from "@angular/core";
import { DEFAULT_MENU } from "src/app/layouts/shared/sidebar/menu";
import { MenuItem } from "src/app/layouts/shared/sidebar/menu.model";
import { PermissionService } from "../core/user/permission.service";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  menu = signal<MenuItem[]>([]);

  constructor(
    private permissionService: PermissionService,
    private authService: AuthService
  ) {
    this.loadMenuItems();
  }

  private async filterMenuItemsByPermission(
    items: MenuItem[]
  ): Promise<MenuItem[]> {
    const filteredItems: MenuItem[] = [];

    for (const item of items) {
      if (item.permission) {
        const hasPermission = await this.permissionService.hasPermission(
          item.permission
        );
        if (!hasPermission) {
          continue;
        }
      }

      const filteredItem: MenuItem = { ...item };

      if (item.subItems) {
        filteredItem.subItems = await this.filterMenuItemsByPermission(
          item.subItems
        );
        if (filteredItem.subItems.length === 0 && !filteredItem.isTitle) {
          continue;
        }
      }

      filteredItems.push(filteredItem);
    }

    return filteredItems;
  }

  private applyParentPermissions(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.map((item) => {
      if (item.subItems && item.subItems.length > 0) {
        const childPermissions: string[] = [];
        item.subItems.forEach((subItem) => {
          if (subItem.permission && subItem.permission.permissions) {
            childPermissions.push(...subItem.permission.permissions);
          }
        });

        const uniquePermissions = Array.from(new Set(childPermissions));

        return {
          ...item,
          permission: {
            permissions:
              uniquePermissions && uniquePermissions.length > 0
                ? uniquePermissions
                : [],
            condition: "OR",
            equalsTo: true,
          },
        };
      }
      return item;
    });
  }

  async loadMenuItems() {
    if (
      this.authService.isAuthenticated() ||
      (this.authService.isAuthenticated() &&
        this.permissionService.refreshPermission())
    ) {
      let menu = await this.filterMenuItemsByPermission(
        this.applyParentPermissions(DEFAULT_MENU)
      );
      this.menu.set(menu);
    }
  }
}
