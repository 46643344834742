import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PageTitleComponent } from "../../shared/ui/pagetitle/pagetitle.component";
import { CommonModule } from '@angular/common';


@Component({
  standalone:true,
  imports: [
    CommonModule,
    PageTitleComponent
],
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {


  constructor(public formBuilder: UntypedFormBuilder) {
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

 

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Dashboard', active: true }];
    
  }

  
}
