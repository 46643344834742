import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProviderAttribute } from 'src/app/interfaces/core/configuration/provider-attribute';
import { DefaultResponsePayload } from 'src/app/interfaces/dtos/default-response-payload';
import { getPagination } from 'src/app/interfaces/dtos/filter';
import { FindAllResponse } from 'src/app/interfaces/dtos/find-all-response';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProviderAttributeService {
  private readonly apiUrl = `${environment.API_BASE_URL}/provider-attribute`;

  constructor(private http: HttpClient) {}

  findAll(pagination?: PaginationDto): Observable<FindAllResponse<ProviderAttribute>> {
    const params = getPagination(pagination);
    return this.http.get<FindAllResponse<ProviderAttribute>>(this.apiUrl, { params });
  }

  findOne(id: string): Observable<DefaultResponsePayload<ProviderAttribute>> {
    return this.http.get<DefaultResponsePayload<ProviderAttribute>>(`${this.apiUrl}/${id}`);
  }

  create(payload: ProviderAttribute): Observable<DefaultResponsePayload<ProviderAttribute>> {
    return this.http.post<DefaultResponsePayload<ProviderAttribute>>(this.apiUrl, payload);
  }

  createBulk(payload: ProviderAttribute[]): Observable<DefaultResponsePayload<ProviderAttribute[]>> {
    return this.http.post<DefaultResponsePayload<ProviderAttribute[]>>(`${this.apiUrl}/bulk`, payload);
  }

  update(id: string, payload: ProviderAttribute): Observable<DefaultResponsePayload<ProviderAttribute>> {
    return this.http.patch<DefaultResponsePayload<ProviderAttribute>>(`${this.apiUrl}/${id}`, payload);
  }

  delete(id: string): Observable<DefaultResponsePayload<ProviderAttribute>> {
    return this.http.delete<DefaultResponsePayload<ProviderAttribute>>(`${this.apiUrl}/${id}`);
  }

  deleteBulk(ids: string[]): Observable<DefaultResponsePayload<ProviderAttribute[]>> {
    return this.http.post<DefaultResponsePayload<ProviderAttribute[]>>(`${this.apiUrl}/bulk/delete`, { ids });
  }
}