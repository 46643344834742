<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems" title="{{ 'CONFIG.CONFIG' | translate }}"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
            <div class="mb-2 mb-md-0">
              <button type="button" class="btn btn-primary waves-effect waves-light"
                (click)="openFilterModal(filterModal)">
                <i class="mdi mdi-magnify mr-1"></i> {{ 'SHARED.SEARCH' | translate }}
                @if(filtersActive){
                <span class="badge badge-light ml-1">
                  <i class="mdi mdi-filter"></i>
                </span>
                }
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-success btn-rounded waves-effect waves-light"
                (click)="createConfiguration()">
                <i class="mdi mdi-plus mr-1"></i> {{ 'CONFIG.ADD_NEW' | translate }}
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>{{ 'CONFIG.NAME' | translate }}</th>
                  <th>{{ 'CONFIG.PROVIDER' | translate }}</th>
                  <th>{{ 'CONFIG.COUNTRY' | translate }}</th>
                  <th>{{ 'CONFIG.DEVISE' | translate }}</th>
                  <th>{{ 'CONFIG.CONVERSION_RATE' | translate }}</th>
                  <th>{{ 'CONFIG.IS_DEFAULT' | translate }}</th>
                  <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                @for (configuration of configurations; track configuration.uuid) {
                <tr>
                  <td>{{ configuration.name }}</td>
                  <td>{{ configuration.provider?.name }}</td>
                  <td>{{ configuration.country?.name }}</td>
                  <td>{{ configuration.devise?.code }}</td>
                  <td>{{ configuration.conversionRate | number:'1.2-2' }}</td>
                  <td>
                    <input type="checkbox" [checked]="configuration.isDefault" disabled>
                  </td>
                  <td>
                    <div ngbDropdown class="d-inline-block" placement="bottom-right" container="body">
                      <button class="btn btn-sm btn-outline-secondary" id="dropdownMenuButton{{configuration.uuid}}" ngbDropdownToggle>
                        <i class="mdi mdi-dots-horizontal"></i>
                      </button>
                      <div ngbDropdownMenu class="dropdown-menu-right">
                        <button ngbDropdownItem (click)="editConfiguration(configuration.uuid)">
                          <i class="mdi mdi-pencil mr-2"></i>{{ 'SHARED.EDIT' | translate }}
                        </button>
                        <button ngbDropdownItem (click)="deleteConfiguration(configuration.uuid)">
                          <i class="mdi mdi-delete mr-2"></i>{{ 'SHARED.DELETE' | translate }}
                        </button>
                        <button ngbDropdownItem (click)="setDefaultConfiguration(configuration.uuid)" [disabled]="configuration.isDefault">
                          <i class="mdi mdi-star mr-2"></i>{{ 'CONFIG.SET_DEFAULT' | translate }}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>

          <div class="row mt-4">
            <div class="col-lg-12">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="paginationDto.page"
                [pageSize]="paginationDto.limit" (pageChange)="onPageChange($event)"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filterModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{ 'CONFIG.FILTER' | translate }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <form [formGroup]="filterForm">
      <div class="form-group">
        <label for="name">{{ 'CONFIG.NAME' | translate }}</label>
        <input type="text" class="form-control" id="name" formControlName="name">
      </div>
      <div class="form-group">
        <label for="providerId">{{ 'CONFIG.PROVIDER' | translate }}</label>
        <ng-select [items]="providers" bindLabel="name" bindValue="uuid" [clearable]="true" formControlName="providerId"
          id="providerId">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="countryId">{{ 'CONFIG.COUNTRY' | translate }}</label>
        <ng-select [items]="countries" bindLabel="name" bindValue="uuid" [clearable]="true" formControlName="countryId"
          id="countryId">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="deviseId">{{ 'CONFIG.DEVISE' | translate }}</label>
        <ng-select [items]="devises" bindLabel="code" bindValue="uuid" [clearable]="true" formControlName="deviseId"
          id="deviseId">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="isDefault">{{ 'CONFIG.IS_DEFAULT' | translate }}</label>
        <select class="form-control" id="isDefault" formControlName="isDefault">
          <option [value]="null">{{ 'SHARED.ALL' | translate }}</option>
          <option [value]="true">{{ 'SHARED.YES' | translate }}</option>
          <option [value]="false">{{ 'SHARED.NO' | translate }}</option>
        </select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="clearFilters(); modal.close('Close click')">{{ 'SHARED.CLEAR' |
      translate }}</button>
    <button type="button" class="btn btn-primary" (click)="applyFilters()">{{ 'SHARED.APPLY' | translate }}</button>
  </div>
</ng-template>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>