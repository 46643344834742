import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModalModule, NgbPaginationModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import Swal from 'sweetalert2';

import { SubscribeDestroyerService } from '../../../services/utils/subscribe-destroyer.service';
import { PageTitleComponent } from '../../../shared/ui/pagetitle/pagetitle.component';
import { OperationType } from '../../../interfaces/enums/operation-type.enum';
import { PaymentMethod } from '../../../interfaces/enums/payment-method.enum';
import { Client } from 'src/app/interfaces/core/client/client';
import { VirtualCreditOperation } from 'src/app/interfaces/core/payment/virtual-credit-operation';
import { BreadcrumbItem } from 'src/app/interfaces/dtos/breadcrumb-item';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { ClientService } from 'src/app/services/core/client/client.service';
import { VirtualCreditOperationService } from 'src/app/services/core/payment/virtual-credit-operation.service';
import { CurrencyFormatPipe } from "../../../pipes/currency-format.pipe";

@Component({
  selector: 'app-virtual-credit-operation',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    CurrencyFormatPipe
],
  templateUrl: './virtual-credit-operation.component.html',
  styleUrls: ['./virtual-credit-operation.component.scss'],
  providers: [SubscribeDestroyerService]
})
export class VirtualCreditOperationComponent implements OnInit {
  operations: VirtualCreditOperation[] = [];
  clients: Client[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: 'createdAt',
    orderDirection: 'DESC',
    search: '',
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;
  operationTypes = Object.values(OperationType);
  paymentMethods = Object.values(PaymentMethod);

  constructor(
    private virtualCreditOperationService: VirtualCreditOperationService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initBreadcrumbItems();
    this.initFilterForm();
    this.loadClients();
    this.loadOperations();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(['SHARED.HOME', 'PAYMENT.TEXT', 'PAYMENT.VCREDIT.TEXT'])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations['SHARED.HOME'], link: '/' },
            { label: translations['PAYMENT.TEXT'], link: '/apps/payment' },
            { label: translations['PAYMENT.VCREDIT.TEXT'], active: true },
          ];
        },
        error: (error) => {
          console.error('Error loading translations:', error);
        }
      });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      clientId: [null],
      type: [null],
      paymentMethod: [null],
      startDate: [null],
      endDate: [null],
    });
  }

  loadClients(): void {
    this.spinner.show();
    this.clientService.findAll({ limit: 0 })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.clients = response.payload.content;
          this.spinner.hide();
        },
        error: (error) => {
          console.error('Error loading clients:', error);
          this.showErrorAlert('COMMON.ERROR.LOAD_CLIENTS');
          this.spinner.hide();
        }
      });
  }

  loadOperations(): void {
    this.spinner.show();
    this.updatePaginationDto();

    this.virtualCreditOperationService.findAll(this.paginationDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.operations = response.payload.content;
          this.collectionSize = response.payload.totalRecords;
          this.spinner.hide();
        },
        error: (error) => {
          console.error('Error loading virtual credit operations:', error);
          this.showErrorAlert('PAYMENT.ERROR.LOAD_OPERATIONS');
          this.spinner.hide();
        }
      });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];

    if (filters.clientId) {
      this.paginationDto.params.push({ clientId: filters.clientId });
    }
    if (filters.type) {
      this.paginationDto.params.push({ type: filters.type });
    }
    if (filters.paymentMethod) {
      this.paginationDto.params.push({ paymentMethod: filters.paymentMethod });
    }
    if (filters.startDate) {
      this.paginationDto.params.push({ startDate: filters.startDate });
    }
    if (filters.endDate) {
      this.paginationDto.params.push({ endDate: filters.endDate });
    }

    this.filtersActive = this.paginationDto.params.length > 0;
  }

  onPageChange(page: number): void {
    this.paginationDto.page = page;
    this.loadOperations();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  applyFilters(): void {
    this.paginationDto.page = 1;
    this.loadOperations();
    this.modalService.dismissAll();
  }

  clearFilters(): void {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: 'createdAt',
      orderDirection: 'DESC',
      search: '',
      params: [],
    };
    this.filtersActive = false;
    this.loadOperations();
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(['SHARED.ERROR', messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations['SHARED.ERROR'],
            translations[messageKey],
            'error'
          );
        },
        error: (error) => {
          console.error('Error loading translations:', error);
        }
      });
  }
}