import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { Attribute } from 'src/app/interfaces/core/common/attribute';

@Component({
  selector: 'app-attribute-management',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    TranslateModule,
    NgSelectModule
  ],
  templateUrl: './attribute-management.component.html',
  styleUrls: ['./attribute-management.component.scss']
})
export class AttributeManagementComponent implements OnInit {
  @Input() entityAttributes: any[] = [];
  @Input() allAttributes: Attribute[] = [];
  @Output() attributesChanged = new EventEmitter<any[]>();
  
  attributeForm: FormGroup;
  visibleValues: { [key: number]: boolean } = {};
  selectedAttribute: Attribute | null = null;

  constructor(private formBuilder: FormBuilder,private translateService: TranslateService) {}

  ngOnInit() {
    this.initForm();
    this.initVisibleValues();
  }

  initForm() {
    this.attributeForm = this.formBuilder.group({
      attributeId: [null, Validators.required],
      value: ['', Validators.required]
    });

    this.attributeForm.get('attributeId')?.valueChanges.subscribe(attributeId => {
      this.selectedAttribute = this.allAttributes.find(attr => attr.uuid === attributeId) || null;
      this.updateValueValidators();
    });
  }

  updateValueValidators() {
    const valueControl = this.attributeForm.get('value');
    if (valueControl && this.selectedAttribute) {
      valueControl.clearValidators();
      switch (this.selectedAttribute.type) {
        case 'number':
          valueControl.setValidators([Validators.required, Validators.pattern(/^\d+$/)]);
          break;
        case 'boolean':
          valueControl.setValidators(Validators.required);
          break;
        case 'date':
          valueControl.setValidators([Validators.required, Validators.pattern(/^\d{4}-\d{2}-\d{2}$/)]);
          break;
        default:
          valueControl.setValidators(Validators.required);
      }
      valueControl.updateValueAndValidity();
    }
  }

  initVisibleValues() {
    this.entityAttributes.forEach((_, index) => {
      this.visibleValues[index] = false;
    });
  }

  addAttribute() {
    if (this.attributeForm.valid && this.selectedAttribute) {
      const newEntityAttribute = {
        attributeId: this.attributeForm.value.attributeId,
        value: this.formatValue(this.attributeForm.value.value, this.selectedAttribute.type)
      };
      this.entityAttributes.push(newEntityAttribute);
      this.visibleValues[this.entityAttributes.length - 1] = false;
      this.attributesChanged.emit(this.entityAttributes);
      this.resetForm();
    }
  }

  formatValue(value: any, type: string): any {
    switch (type) {
      case 'number':
        return Number(value);
      case 'boolean':
        return value === 'true';
      case 'date':
        return new Date(value).toISOString();
      default:
        return value;
    }
  }

  removeAttribute(index: number) {
    this.entityAttributes.splice(index, 1);
    delete this.visibleValues[index];
    this.attributesChanged.emit(this.entityAttributes);
  }

  resetForm() {
    this.attributeForm.reset();
    this.selectedAttribute = null;
  }

  getAttributeLabel(attributeId: string): string {
    const attribute = this.allAttributes.find(attr => attr.uuid === attributeId);
    return attribute ? attribute.label : '';
  }

  toggleValueVisibility(index: number) {
    this.visibleValues[index] = !this.visibleValues[index];
  }

  getDisplayValue(value: any, type: string, index: number): string {
    if (!this.visibleValues[index]) {
      return '••••••';
    }
    switch (type) {
      case 'boolean':
        return value ? 'true' : 'false';
      case 'date':
        return new Date(value).toLocaleDateString();
      default:
        return String(value);
    }
  }

  getAttributeType(attributeId: string): string {
    const attribute = this.allAttributes.find(attr => attr.uuid === attributeId);
    return attribute ? attribute.type : 'string';
  }

  copyAttributeValue(value: string) {
    navigator.clipboard.writeText(value).then(() => {
      // Optionnel : Afficher un message de confirmation
      this.translateService.get('COMMON.ATTRIBUTE.COPY_SUCCESS').subscribe((res: string) => {
        // Vous pouvez utiliser ici un service de notification ou une alerte pour informer l'utilisateur
        console.log(res); // Par exemple, afficher dans la console
      });
    }, (err) => {
      console.error('Impossible de copier le texte: ', err);
    });
  }
}