<div class="container-fluid">
    <app-page-title [breadcrumbItems]="breadcrumbItems" title="{{ 'COMMON.COUNTRY.LIST.TITLE' | translate }}"></app-page-title>
  
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
              <div class="mb-2 mb-md-0">
                <button type="button" class="btn btn-primary waves-effect waves-light"
                  (click)="openFilterModal(filterModal)">
                  <i class="mdi mdi-magnify mr-1"></i> {{ 'SHARED.SEARCH' | translate }}
                    @if(filtersActive){
                <span class="badge badge-light ml-1">
                  <i class="mdi mdi-filter"></i>
                </span>
                }
                </button>
              </div>
              <div>
                <button type="button" class="btn btn-success btn-rounded waves-effect waves-light"
                  (click)="createCountry()">
                  <i class="mdi mdi-plus mr-1"></i> {{ 'COMMON.COUNTRY.LIST.ADD_NEW' | translate }}
                </button>
              </div>
            </div>
  
            <div class="table-responsive">
              <table class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>{{ 'COMMON.COUNTRY.LIST.CODE' | translate }}</th>
                    <th>{{ 'COMMON.COUNTRY.LIST.NAME' | translate }}</th>
                    <th>{{ 'COMMON.COUNTRY.LIST.TIMEZONE' | translate }}</th>
                    <th>{{ 'COMMON.COUNTRY.LIST.INDICATIVE' | translate }}</th>
                    <th>{{ 'COMMON.COUNTRY.LIST.DEVISE' | translate }}</th>
                    <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  @for (country of countries; track country.uuid) {
                  <tr>
                    <td>{{ country.code }}</td>
                    <td>{{ country.name }}</td>
                    <td>{{ country.timezone }}</td>
                    <td>{{ country.indicative }}</td>
                    <td>{{ country.devise?.code }}</td>
                    <td>
                      <button type="button" class="btn btn-primary btn-sm btn-rounded m-2"
                        (click)="editCountry(country.uuid)">
                        {{ 'SHARED.EDIT' | translate }}
                      </button>
                      <button type="button" class="btn btn-danger btn-sm btn-rounded" (click)="deleteCountry(country.uuid)">
                        {{ 'SHARED.DELETE' | translate }}
                      </button>
                    </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
  
            <div class="row mt-4">
              <div class="col-lg-12">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="paginationDto.page"
                  [pageSize]="paginationDto.limit" (pageChange)="onPageChange($event)"></ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #filterModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">{{ 'COMMON.COUNTRY.LIST.FILTER' | translate }}</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
    <div class="modal-body">
      <form [formGroup]="filterForm">
        <div class="form-group">
          <label for="code">{{ 'COMMON.COUNTRY.LIST.CODE' | translate }}</label>
          <input type="text" class="form-control" id="code" formControlName="code">
        </div>
        <div class="form-group">
          <label for="name">{{ 'COMMON.COUNTRY.LIST.NAME' | translate }}</label>
          <input type="text" class="form-control" id="name" formControlName="name">
        </div>
        <div class="form-group">
          <label for="deviseId">{{ 'COMMON.COUNTRY.LIST.DEVISE' | translate }}</label>
          <ng-select [items]="devises" bindLabel="code" bindValue="uuid" [clearable]="true"
            formControlName="deviseId" id="deviseId">
          </ng-select>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="clearFilters(); modal.close('Close click')">{{ 'SHARED.CLEAR' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="applyFilters()">{{ 'SHARED.APPLY' | translate }}</button>
    </div>
  </ng-template>
  
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>