<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">
  <ngx-simplebar class="h-100" data-simplebar-track="light">
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
        <ng-container *ngFor="let item of menuService.menu(); let i = index">
          <!-- Titre du Menu -->
          <ng-container *ngIf="item.isTitle">
            <li class="menu-title">{{ item.label | translate }}</li>
          </ng-container>

          <!-- Menu Items -->
          <ng-container *ngIf="!item.isTitle">
            <li>
              <ng-container *ngIf="hasItems(item); else singleItem">
                <!-- Menu avec Sous-Items -->
                <a href="javascript:void(0);"
                   class="is-parent"
                   [ngClass]="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }">
                  <i *ngIf="item.icon" class="bx {{ item.icon }}"></i>
                  <span>{{ item.label | translate }}</span>
                  <span *ngIf="item.badge" class="badge rounded-pill bg-{{ item.badge.variant }} float-end">
                    {{ item.badge.text | translate }}
                  </span>
                </a>

                <ul class="sub-menu" aria-expanded="false">
                  <ng-container *ngFor="let subitem of item.subItems">
                    <li>
                      <a [routerLink]="subitem.link"
                         class="side-nav-link-ref"
                         routerLinkActive="active">
                        {{ subitem.label | translate }}
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </ng-container>

              <!-- Menu sans Sous-Items -->
              <ng-template #singleItem>
                <a [routerLink]="item.link"
                   class="side-nav-link-ref"
                   routerLinkActive="active">
                  <i *ngIf="item.icon" class="bx {{ item.icon }}"></i>
                  <span>{{ item.label | translate }}</span>
                </a>
              </ng-template>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
    <!-- Sidebar -->
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
