<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-lg-8">
            <div class="card">
                <div class="card-body">
                    <h2 class="card-title text-center mb-4">{{ 'PAYMENT.VCREDIT.TRANSFER.TITLE' | translate }}</h2>
                    <form [formGroup]="transferForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="senderId" class="form-label">{{ 'PAYMENT.VCREDIT.SENDER' | translate
                                    }}</label>
                                <ng-select [items]="clients" bindLabel="name" bindValue="uuid"
                                    formControlName="senderId" [searchFn]="searchFn" (change)="onSenderChange()"
                                    id="senderId" placeholder="{{ 'PAYMENT.VCREDIT.SELECT_SENDER' | translate }}">
                                    <ng-template ng-label-tmp let-item="item">
                                        {{item.name}} ({{item.phoneNumber}})
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                        {{item.name}} ({{item.phoneNumber}})
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="paymentMethod" class="form-label">{{ 'PAYMENT.VCREDIT.PAYMENT_METHOD' |
                                    translate }}</label>
                                <ng-select [items]="paymentMethods" formControlName="paymentMethod" id="paymentMethod"
                                    (change)="updateRemainingCredit()"
                                    placeholder="{{ 'PAYMENT.VCREDIT.SELECT_METHOD' | translate }}">
                                </ng-select>
                            </div>
                        </div>

                        <div *ngIf="selectedSender && transferForm.get('paymentMethod')?.value"
                            class="alert alert-info mt-3">
                            <p class="mb-1"><strong>{{ 'PAYMENT.VCREDIT.AVAILABLE_CREDIT' | translate }}:</strong>
                                {{ (transferForm.get('paymentMethod')?.value === 'ORANGE_MONEY'
                                ? selectedSender.availableVirtualCreditOrangeMoney
                                : selectedSender.availableVirtualCreditWave) | currencyFormat }}
                            </p>
                            <p class="mb-0"><strong>{{ 'PAYMENT.VCREDIT.REMAINING_CREDIT' | translate }}:</strong> {{
                                remainingCredit | currencyFormat }}</p>
                        </div>

                        <div formArrayName="receivers" class="mt-4">
                            <h4 class="mb-3">{{ 'PAYMENT.VCREDIT.RECEIVERS' | translate }}</h4>
                            <div class="d-flex justify-content-between mt-4">
                                <button type="button" class="btn btn-outline-secondary" (click)="addReceiver()">
                                    <i class="bi bi-plus-circle"></i> {{ 'PAYMENT.VCREDIT.ADD_RECEIVER' | translate }}
                                </button>
                            </div>
                            <div *ngFor="let receiver of receivers.controls; let i = index" [formGroupName]="i"
                                class="card mt-3">
                                <div class="card-body">
                                    <h5 class="card-title">{{ 'PAYMENT.VCREDIT.RECEIVER' | translate }} {{i + 1}}</h5>
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <label [for]="'receiverId'+i" class="form-label">{{
                                                'PAYMENT.VCREDIT.RECEIVER' | translate }}</label>
                                            <ng-select [items]="clients" bindLabel="name" bindValue="uuid"
                                                [formControlName]="'receiverId'" [searchFn]="searchFn"
                                                [id]="'receiverId'+i"
                                                placeholder="{{ 'PAYMENT.VCREDIT.SELECT_RECEIVER' | translate }}">
                                                <ng-template ng-label-tmp let-item="item">
                                                    {{item.name}} ({{item.phoneNumber}})
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item">
                                                    {{item.name}} ({{item.phoneNumber}})
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label [for]="'amount'+i" class="form-label">{{ 'PAYMENT.VCREDIT.AMOUNT' |
                                                translate }}</label>
                                            <input type="number" class="form-control" [id]="'amount'+i"
                                                formControlName="amount" (change)="updateRemainingCredit()">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 mb-3">
                                            <label [for]="'currency'+i" class="form-label">{{ 'PAYMENT.VCREDIT.CURRENCY'
                                                | translate }}</label>
                                            <input type="text" class="form-control" [id]="'currency'+i"
                                                formControlName="currency">
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <label [for]="'subject'+i" class="form-label">{{ 'PAYMENT.VCREDIT.SUBJECT' |
                                                translate }}</label>
                                            <input type="text" class="form-control" [id]="'subject'+i"
                                                formControlName="subject">
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <label [for]="'reference'+i" class="form-label">{{
                                                'PAYMENT.VCREDIT.REFERENCE' | translate }}</label>
                                            <input type="text" class="form-control" [id]="'reference'+i"
                                                formControlName="reference">
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-outline-danger btn-sm"
                                        (click)="removeReceiver(i)"><i class="bi bi-trash"></i> {{ 'SHARED.REMOVE' |
                                        translate }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end mt-4">
                            <button type="submit" class="btn btn-primary"
                                [disabled]="!transferForm.valid || remainingCredit < 0">
                                <i class="bi bi-send"></i> {{ 'PAYMENT.VCREDIT.TRANSFER.SUBMIT' | translate }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>