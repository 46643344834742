import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LayoutComponent } from "./layouts/layout/layout.component";
import { AuthGuard } from "./guards/canActivate/auth.guard";
import { DashboardComponent } from "./modules/dashboard/dashboard.component";
import { VirtualCreditOperationComponent } from "./modules/payment/virtual-credit-operation/virtual-credit-operation.component";
import { PermissionComponent } from "./modules/user/permission/permission.component";
import { ProfilComponent } from "./modules/user/profil/profil.component";
import { ClientListComponent } from "./modules/client/client-list/client-list.component";
import { TypeClientCreateUpdateComponent } from "./modules/client/type-client-list/pages/type-client-create-update/type-client-create-update.component";
import { TypeClientListComponent } from "./modules/client/type-client-list/type-client-list.component";
import { ActivityAreaListComponent } from "./modules/client/activity-area-list/activity-area-list.component";
import { ActivityAreaCreateUpdateComponent } from "./modules/client/activity-area-list/pages/activity-area-create-update/activity-area-create-update.component";
import { DeviseListComponent } from "./modules/common/devise-list/devise-list.component";
import { DeviseCreateUpdateComponent } from "./modules/common/devise-list/pages/devise-create-update/devise-create-update.component";
import { CountryListComponent } from "./modules/common/country-list/country-list.component";
import { CountryCreateUpdateComponent } from "./modules/common/country-list/pages/country-create-update/country-create-update.component";
import { ProviderCreateUpdateComponent } from "./modules/config/provider-list/pages/provider-create-update/provider-create-update.component";
import { ProviderListComponent } from "./modules/config/provider-list/provider-list.component";
import { ConfigurationListComponent } from "./modules/config/configuration-list/configuration-list.component";
import { ConfigurationCreateUpdateComponent } from "./modules/config/configuration-list/pages/configuration-create-update/configuration-create-update.component";
import { FeeListComponent } from "./modules/payment/fee-list/fee-list.component";
import { FeeCreateUpdateComponent } from "./modules/payment/fee-list/pages/fee-create-update/fee-create-update.component";
import { AttributeListComponent } from "./modules/common/attribute-list/attribute-list.component";
import { AttributeCreateUpdateComponent } from "./modules/common/attribute-list/pages/attribute-create-update/attribute-create-update.component";
import { PaymentListComponent } from "./modules/payment/payment-list/payment-list.component";
import { TransactionListComponent } from "./modules/payment/transaction-list/transaction-list.component";
import { TransactionDetailComponent } from "./modules/payment/transaction-list/pages/transaction-detail/transaction-detail.component";
import { PaymentDetailComponent } from "./modules/payment/payment-list/pages/payment-detail/payment-detail.component";
import { ClientCreateUpdateComponent } from "./modules/client/client-list/pages/client-create-update/client-create-update.component";
import { VirtualCreditTransferComponent } from "./modules/payment/virtual-credit-transfer/virtual-credit-transfer.component";
import { AppFeatureListComponent } from "./modules/user/app-feature-list/app-feature-list.component";
import { AppFeatureCreateUpdateComponent } from "./modules/user/app-feature-list/pages/app-feature-create-update/app-feature-create-update.component";
import { PermissionCreateUpdateComponent } from "./modules/user/permission/pages/permission-create-update/permission-create-update.component";
import { checkPermissionsCanMatchGuard } from "./guards/canMatch/check-permissions-can-match.guard";
import { PermissionDirectiveDto } from "./interfaces/dtos/permission-directive-dto";
import { UserListComponent } from "./modules/user/user-list/user-list.component";
import { UserCreateUpdateComponent } from "./modules/user/user-list/pages/user-create-update/user-create-update.component";
import { ProfilCreateUpdateComponent } from "./modules/user/profil/pages/profil-create-update/profil-create-update.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "apps",
  },
  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "apps" },
  {
    path: "account",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./account/auth/auth.module").then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: "apps",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", pathMatch: "full", redirectTo: "dashboard" },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },

      {
        path: "user",
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "user-list" },
          {
            path: "user-list",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.users.read", "perm.users.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: UserListComponent,
          },
          {
            path: "user-list/create",
            component: UserCreateUpdateComponent,
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.users.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
          },
          {
            path: "user-list/edit/:id",
            component: UserCreateUpdateComponent,
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.users.read", "perm.users.update"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
          },
          {
            path: "profil",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.profil.read", "perm.profil.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ProfilComponent,
          },
          {
            path: "profil/create",
            component: ProfilCreateUpdateComponent,
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.profil.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
          },
          {
            path: "profil/edit/:id",
            component: ProfilCreateUpdateComponent,
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.profil.read", "perm.profil.update"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
          },
          {
            path: "app-feature",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.appfeature.read", "perm.appfeature.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: AppFeatureListComponent,
          },
          {
            path: "app-feature/create",
            component: AppFeatureCreateUpdateComponent,
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.appfeature.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
          },
          {
            path: "app-feature/edit/:id",
            component: AppFeatureCreateUpdateComponent,
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.appfeature.read", "perm.appfeature.update"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
          },
          {
            path: "permission",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.permission.read", "perm.permission.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: PermissionComponent,
          },
          {
            path: "permission/create",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.permission.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: PermissionCreateUpdateComponent,
          },

          {
            path: "permission/edit/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.permission.read", "perm.permission.update"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: PermissionCreateUpdateComponent,
          },
        ],
      },
      {
        path: "client",
        children: [
          { path: "", pathMatch: "full", redirectTo: "client-list" },
          {
            path: "client-list",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.client.read", "perm.client.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ClientListComponent,
          },
          {
            path: "client-list/create",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.client.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ClientCreateUpdateComponent,
          },
          {
            path: "client-list/edit/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.client.read", "perm.client.update"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ClientCreateUpdateComponent,
          },
          {
            path: "type-client",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.typeclient.read", "perm.typeclient.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: TypeClientListComponent,
          },
          {
            path: "type-client/create",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.typeclient.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: TypeClientCreateUpdateComponent,
          },
          {
            path: "type-client/edit/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.typeclient.read", "perm.typeclient.update"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: TypeClientCreateUpdateComponent,
          },
          {
            path: "activity-area",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: [
                  "perm.activityarea.read",
                  "perm.activityarea.create",
                ],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ActivityAreaListComponent,
          },
          {
            path: "activity-area/create",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.activityarea.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ActivityAreaCreateUpdateComponent,
          },
          {
            path: "activity-area/edit/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: [
                  "perm.activityarea.read",
                  "perm.activityarea.update",
                ],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ActivityAreaCreateUpdateComponent,
          },
        ],
      },
      {
        path: "payment",
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "payment" },
          {
            path: "payment",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.payment.read"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: PaymentListComponent,
          },
          {
            path: "payment/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.payment.read"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: PaymentDetailComponent,
          },
          {
            path: "transaction",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.transaction.read"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: TransactionListComponent,
          },
          {
            path: "transaction/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.transaction.read"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: TransactionDetailComponent,
          },
          {
            path: "virtual-credit-operation",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.virtualcreditoperation.read"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: VirtualCreditOperationComponent,
          },
          {
            path: "fee",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.fee.read", "perm.fee.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: FeeListComponent,
          },
          {
            path: "fee/create",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.fee.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: FeeCreateUpdateComponent,
          },
          {
            path: "fee/edit/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.fee.read", "perm.fee.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: FeeCreateUpdateComponent,
          },
          {
            path: "virtual-credit-transfer",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.payment.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: VirtualCreditTransferComponent,
          },
        ],
      },
      {
        path: "config",
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "configuration" },
          {
            path: "configuration",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: [
                  "perm.configuration.read",
                  "perm.configuration.create",
                ],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ConfigurationListComponent,
          },
          {
            path: "configuration/create",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.configuration.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ConfigurationCreateUpdateComponent,
          },
          {
            path: "configuration/edit/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: [
                  "perm.configuration.read",
                  "perm.configuration.create",
                ],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ConfigurationCreateUpdateComponent,
          },
          {
            path: "provider",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.provider.read", "perm.provider.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ProviderListComponent,
          },
          {
            path: "provider/create",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.provider.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ProviderCreateUpdateComponent,
          },
          {
            path: "provider/edit/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.provider.read", "perm.provider.update"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: ProviderCreateUpdateComponent,
          },
        ],
      },
      {
        path: "common",
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "attribute" },
          {
            path: "attribute",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.attribute.read", "perm.attribute.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: AttributeListComponent,
          },
          {
            path: "attribute/create",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.attribute.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: AttributeCreateUpdateComponent,
          },
          {
            path: "attribute/edit/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.attribute.read", "perm.attribute.update"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: AttributeCreateUpdateComponent,
          },
          {
            path: "currency",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.currency.read", "perm.currency.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: DeviseListComponent,
          },
          {
            path: "currency/create",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.currency.create"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: DeviseCreateUpdateComponent,
          },
          {
            path: "currency/edit/:id",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.currency.read", "perm.currency.update"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: DeviseCreateUpdateComponent,
          },
          {
            path: "country",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.country.read", "perm.country.create"],
                condition: "OR",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: CountryListComponent,
          },
          {
            path: "country/create",
            canMatch: [checkPermissionsCanMatchGuard],
            data: {
              permission: {
                permissions: ["perm.country.read", "perm.country.update"],
                condition: "AND",
                equalsTo: true,
              } as PermissionDirectiveDto,
            },
            component: CountryCreateUpdateComponent,
          },
          { path: "country/edit/:id", component: CountryCreateUpdateComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
