import { HttpClient } from '@angular/common/http';
import { Injectable, signal, Signal } from '@angular/core';
import { Observable, lastValueFrom, tap } from 'rxjs';
import { Users } from 'src/app/interfaces/core/user/user';
import { DefaultResponsePayload } from 'src/app/interfaces/dtos/default-response-payload';
import { getPagination } from 'src/app/interfaces/dtos/filter';
import { FindAllResponse } from 'src/app/interfaces/dtos/find-all-response';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../utils/local-storage.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _user = signal<Users | null>(null);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  set user(value: Users) {
    this._user.set(value);
  }

  get user(): Signal<Users | null> {
    return this._user;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  async refreshLoggedUserInfo() {
    let data = await lastValueFrom(
      this.http.get<DefaultResponsePayload<Users>>(
        `${environment.API_BASE_URL}/user/users/current-user`
      )
    );

    this.localStorageService.saveData(
      environment.USER_KEY,
      JSON.stringify(data.payload)
    );
    this._user.set(data.payload);
  }

  findAll(pagination?: PaginationDto): Observable<FindAllResponse<Users>> {
    let params = getPagination(pagination);
    return this.http.get<FindAllResponse<Users>>(
      `${environment.API_BASE_URL}/user/users`,
      { params }
    );
  }

  findOne(id: string): Observable<DefaultResponsePayload<Users>> {
    return this.http.get<DefaultResponsePayload<Users>>(
      `${environment.API_BASE_URL}/user/users/${id}`
    );
  }

  getCurrentUser(): Observable<DefaultResponsePayload<Users>> {
    return this.http.get<DefaultResponsePayload<Users>>(
      `${environment.API_BASE_URL}/user/users/current-user`
    );
  }

  update(id: string, payload: Users): Observable<DefaultResponsePayload<Users>> {
    return this.http
      .patch<DefaultResponsePayload<Users>>(
        `${environment.API_BASE_URL}/user/users/${id}`,
        payload
      )
      .pipe(
        tap(async (data) => {
          await this.refreshLoggedUserInfo();
        })
      );
  }

  delete(id: string): Observable<DefaultResponsePayload<Users>> {
    return this.http
      .delete<DefaultResponsePayload<Users>>(
        `${environment.API_BASE_URL}/user/users/${id}`
      )
      .pipe(
        tap(async (data) => {
          await this.refreshLoggedUserInfo();
        })
      );
  }

  create(payload: Users): Observable<DefaultResponsePayload<Users>> {
    return this.http
      .post<DefaultResponsePayload<Users>>(
        `${environment.API_BASE_URL}/user/users`,
        payload
      )
      .pipe(
        tap(async (data) => {
          await this.refreshLoggedUserInfo();
        })
      );
  }

  createBulk(payload: Users[]): Observable<DefaultResponsePayload<Users[]>> {
    return this.http.post<DefaultResponsePayload<Users[]>>(
      `${environment.API_BASE_URL}/user/users/bulk`,
      payload
    );
  }

  deleteBulk(ids: string[]): Observable<DefaultResponsePayload<Users[]>> {
    return this.http.post<DefaultResponsePayload<Users[]>>(
      `${environment.API_BASE_URL}/user/users/bulk/delete`,
      { ids }
    );
  }
}
