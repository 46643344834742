import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { PermissionService } from 'src/app/services/core/user/permission.service';

export const checkPermissionsCanMatchGuard: CanMatchFn = (route, segments) => {
  if(route.data?.['permission']){
    return inject(PermissionService).hasPermission(route.data?.['permission'])??inject(Router).createUrlTree(['/signed-in-redirect']);
  }
  return inject(Router).createUrlTree(['/signed-in-redirect']);
};
