<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems"
    [title]="(isUpdateMode ? 'COMMON.COUNTRY.EDIT.TITLE' : 'COMMON.COUNTRY.CREATE.TITLE') | translate"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="countryForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" *appHasPermissions="{
                  permissions: ['perm.country.create', 'perm.country.update'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  <label for="code">{{ 'COMMON.COUNTRY.FORM.CODE' | translate }}</label>
                  <input type="text" class="form-control" id="code" formControlName="code"
                    [ngClass]="{'is-invalid': hasError('code', 'required') || hasError('code', 'maxlength')}">
                  @if(hasError('code', 'required')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.COUNTRY.FORM.CODE_REQUIRED' | translate }}
                  </div>
                  }
                  @if(hasError('code', 'maxlength')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.COUNTRY.FORM.CODE_MAX_LENGTH' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" *appHasPermissions="{
                  permissions: ['perm.country.create', 'perm.country.update'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  <label for="name">{{ 'COMMON.COUNTRY.FORM.NAME' | translate }}</label>
                  <input type="text" class="form-control" id="name" formControlName="name"
                    [ngClass]="{'is-invalid': hasError('name', 'required')}">
                  @if(hasError('name', 'required')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.COUNTRY.FORM.NAME_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group" *appHasPermissions="{
                  permissions: ['perm.country.create', 'perm.country.update'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  <label for="timezone">{{ 'COMMON.COUNTRY.FORM.TIMEZONE' | translate }}</label>
                  <input type="number" class="form-control" id="timezone" formControlName="timezone"
                    [ngClass]="{'is-invalid': hasError('timezone', 'required') || hasError('timezone', 'min') || hasError('timezone', 'max')}">
                  @if(hasError('timezone', 'required')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.COUNTRY.FORM.TIMEZONE_REQUIRED' | translate }}
                  </div>
                  }
                  @if(hasError('timezone', 'min') || hasError('timezone', 'max')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.COUNTRY.FORM.TIMEZONE_INVALID' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" *appHasPermissions="{
                  permissions: ['perm.country.create', 'perm.country.update'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  <label for="indicative">{{ 'COMMON.COUNTRY.FORM.INDICATIVE' | translate }}</label>
                  <input type="number" class="form-control" id="indicative" formControlName="indicative"
                    [ngClass]="{'is-invalid': hasError('indicative', 'required') || hasError('indicative', 'min')}">
                  @if(hasError('indicative', 'required')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.COUNTRY.FORM.INDICATIVE_REQUIRED' | translate }}
                  </div>
                  }
                  @if(hasError('indicative', 'min')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.COUNTRY.FORM.INDICATIVE_INVALID' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group" *appHasPermissions="{
                  permissions: ['perm.country.create', 'perm.country.update'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  <label for="deviseId">{{ 'COMMON.COUNTRY.FORM.DEVISE' | translate }}</label>
                  <ng-select [items]="devises" bindLabel="code" bindValue="uuid" formControlName="deviseId"
                    id="deviseId" [ngClass]="{'is-invalid': hasError('deviseId', 'required')}">
                  </ng-select>
                  @if(hasError('deviseId', 'required')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.COUNTRY.FORM.DEVISE_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12 text-right">
                <button type="button" class="btn btn-secondary margin m-2" (click)="cancel()" *appHasPermissions="{
                  permissions: ['perm.country.read'],
                  condition: 'OR',
                  equalsTo: true
                }">{{ 'SHARED.CANCEL' | translate }}</button>
                <button type="submit" class="btn btn-primary" [disabled]="!countryForm.valid" *appHasPermissions="{
                  permissions: [isUpdateMode ? 'perm.country.update' : 'perm.country.create'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  {{ (isUpdateMode ? 'SHARED.UPDATE' : 'SHARED.CREATE') | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>
