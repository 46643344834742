import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Attribute } from 'src/app/interfaces/core/common/attribute';
import { DefaultResponsePayload } from 'src/app/interfaces/dtos/default-response-payload';
import { getPagination } from 'src/app/interfaces/dtos/filter';
import { FindAllResponse } from 'src/app/interfaces/dtos/find-all-response';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AttributeService {
    private readonly apiUrl = `${environment.API_BASE_URL}/attribute`;

    constructor(private http: HttpClient) {}

    findAll(pagination?: PaginationDto): Observable<FindAllResponse<Attribute>> {
        const params = getPagination(pagination);
        return this.http.get<FindAllResponse<Attribute>>(this.apiUrl, { params });
    }

    findOne(id: string): Observable<DefaultResponsePayload<Attribute>> {
        return this.http.get<DefaultResponsePayload<Attribute>>(`${this.apiUrl}/${id}`);
    }

    create(payload: Attribute): Observable<DefaultResponsePayload<Attribute>> {
        return this.http.post<DefaultResponsePayload<Attribute>>(this.apiUrl, payload);
    }

    createBulk(payload: Attribute[]): Observable<DefaultResponsePayload<Attribute[]>> {
        return this.http.post<DefaultResponsePayload<Attribute[]>>(`${this.apiUrl}/bulk`, payload);
    }

    update(id: string, payload: Attribute): Observable<DefaultResponsePayload<Attribute>> {
        return this.http.patch<DefaultResponsePayload<Attribute>>(`${this.apiUrl}/${id}`, payload);
    }

    delete(id: string): Observable<DefaultResponsePayload<Attribute>> {
        return this.http.delete<DefaultResponsePayload<Attribute>>(`${this.apiUrl}/${id}`);
    }

    deleteBulk(ids: string[]): Observable<DefaultResponsePayload<Attribute[]>> {
        return this.http.post<DefaultResponsePayload<Attribute[]>>(`${this.apiUrl}/bulk/delete`, { ids });
    }
}
