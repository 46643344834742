<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems"
    [title]="(isUpdateMode ? 'COMMON.CURRENCY.EDIT.TITLE' : 'COMMON.CURRENCY.CREATE.TITLE') | translate"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="deviseForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" *appHasPermissions="{
                  permissions: ['perm.currency.create', 'perm.currency.update'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  <label for="code">{{ 'COMMON.CURRENCY.FORM.CODE' | translate }}</label>
                  <input type="text" class="form-control" id="code" formControlName="code"
                    [ngClass]="{'is-invalid': hasError('code', 'required') || hasError('code', 'maxlength')}">
                  @if(hasError('code', 'required')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.CURRENCY.FORM.CODE_REQUIRED' | translate }}
                  </div>
                  }
                  @if(hasError('code', 'maxlength')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.CURRENCY.FORM.CODE_MAX_LENGTH' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" *appHasPermissions="{
                  permissions: ['perm.currency.create', 'perm.currency.update'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  <label for="label">{{ 'COMMON.CURRENCY.FORM.LABEL' | translate }}</label>
                  <input type="text" class="form-control" id="label" formControlName="label"
                    [ngClass]="{'is-invalid': hasError('label', 'required')}">
                  @if(hasError('label', 'required')){
                  <div class="invalid-feedback">
                    {{ 'COMMON.CURRENCY.FORM.LABEL_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12 text-right">
                <button type="button" class="btn btn-secondary margin me-2" (click)="cancel()" *appHasPermissions="{
                  permissions: ['perm.currency.read'],
                  condition: 'OR',
                  equalsTo: true
                }">{{ 'SHARED.CANCEL' | translate }}</button>
                <button type="submit" class="btn btn-primary" [disabled]="!deviseForm.valid" *appHasPermissions="{
                  permissions: [isUpdateMode ? 'perm.currency.update' : 'perm.currency.create'],
                  condition: 'OR',
                  equalsTo: true
                }">
                  {{ (isUpdateMode ? 'SHARED.UPDATE' : 'SHARED.CREATE') | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>
