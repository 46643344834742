import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from "@angular/forms";
import { NgbModalModule, NgbPaginationModule, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { Profil } from "src/app/interfaces/core/user/profil";
import { Permission } from "src/app/interfaces/core/user/permission";
import { AppFeature } from "src/app/interfaces/core/user/app-feature";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";
import { AppFeatureService } from "src/app/services/core/user/app-feature.service";
import { PermissionService } from "src/app/services/core/user/permission.service";
import { ProfilService } from "src/app/services/core/user/profil.service";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";

@Component({
  selector: "app-profil",
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    HasPermissionsDirective
  ],
  templateUrl: "./profil.component.html",
  styleUrls: ["./profil.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ProfilComponent implements OnInit {
  profils: Profil[] = [];
  permissions: Permission[] = [];
  appFeatures: AppFeature[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: "createdAt",
    orderDirection: "DESC",
    search: "",
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;

  constructor(
    private profilService: ProfilService,
    private permissionService: PermissionService,
    private appFeatureService: AppFeatureService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initBreadcrumbItems();
    this.initFilterForm();
    this.loadPermissions();
    this.loadAppFeatures();
    this.loadProfils();
  }

  initBreadcrumbItems(): void {
    this.translateService.get(["SHARED.HOME", "USER.TEXT", "USER.PROFIL.LIST.TITLE"]).subscribe({
      next: (translations) => {
        this.breadcrumbItems = [
          { label: translations["SHARED.HOME"], link: "/" },
          { label: translations["USER.TEXT"], link: "/apps/user/profil" },
          { label: translations["USER.PROFIL.LIST.TITLE"], active: true },
        ];
      },
      error: (error) => {
        console.error("Error loading translations:", error);
      },
    });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      name: [""],
      permissionIds: [[]],
      appFeatureIds: [[]],
    });
  }

  async loadPermissions() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.permission.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.permissionService.findAll().pipe(takeUntil(this.subscribeDestroyer$)).subscribe({
      next: (response) => {
        this.permissions = response.payload.content;
      },
      error: (error) => {
        console.error("Error loading permissions:", error);
        this.showErrorAlert("USER.PROFIL.ERROR.LOAD_PERMISSIONS");
      },
    });
  }

  async loadAppFeatures() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.appfeature.read'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.appFeatureService.findAll().pipe(takeUntil(this.subscribeDestroyer$)).subscribe({
      next: (response) => {
        this.appFeatures = response.payload.content;
      },
      error: (error) => {
        console.error("Error loading app features:", error);
        this.showErrorAlert("USER.PROFIL.ERROR.LOAD_APP_FEATURES");
      },
    });
  }

  async loadProfils() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.profil.read'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.spinner.show();
    this.updatePaginationDto();

    this.profilService.findAll(this.paginationDto).pipe(takeUntil(this.subscribeDestroyer$)).subscribe({
      next: (response) => {
        this.profils = response.payload.content;
        this.collectionSize = response.payload.totalRecords;
        this.spinner.hide();
      },
      error: (error) => {
        console.error("Error loading profils:", error);
        this.showErrorAlert("USER.PROFIL.ERROR.LOAD_PROFILS");
        this.spinner.hide();
      },
    });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];

    if (filters.name) {
      this.paginationDto.search = filters.name;
    }
    if (filters.permissionIds && filters.permissionIds.length > 0) {
      this.paginationDto.params.push({ permissionIds: filters.permissionIds });
    }
    if (filters.appFeatureIds && filters.appFeatureIds.length > 0) {
      this.paginationDto.params.push({ appFeatureIds: filters.appFeatureIds });
    }

    this.filtersActive = this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  onPageChange(page: number): void {
    this.paginationDto.page = page;
    this.loadProfils();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  applyFilters(): void {
    this.paginationDto.page = 1;
    this.loadProfils();
    this.modalService.dismissAll();
  }

  clearFilters(): void {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: "createdAt",
      orderDirection: "DESC",
      search: "",
      params: [],
    };
    this.filtersActive = false;
    this.loadProfils();
  }

  async createProfil() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.profil.create'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.router.navigate(["/apps/user/profil/create"]);
  }

  async editProfil(uuid: string) {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.profil.update'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.router.navigate(["/apps/user/profil/edit", uuid]);
  }

  async deleteProfil(uuid: string) {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.profil.delete'], condition: 'AND', equalsTo: true })) {
      return;
    }
    this.translateService.get([
      "USER.PROFIL.CONFIRM_DELETE",
      "SHARED.YES",
      "SHARED.NO",
      "USER.PROFIL.DELETED",
      "USER.PROFIL.ERROR.DELETE",
    ]).subscribe({
      next: (translations) => {
        Swal.fire({
          title: translations["USER.PROFIL.CONFIRM_DELETE"],
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: translations["SHARED.YES"],
          cancelButtonText: translations["SHARED.NO"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.profilService.delete(uuid).pipe(takeUntil(this.subscribeDestroyer$)).subscribe({
              next: () => {
                Swal.fire(translations["USER.PROFIL.DELETED"], "", "success");
                this.loadProfils();
              },
              error: (error) => {
                console.error("Error deleting profil:", error);
                this.showErrorAlert("USER.PROFIL.ERROR.DELETE");
              },
            });
          }
        });
      },
      error: (error) => {
        console.error("Error loading translations:", error);
      },
    });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService.get(["SHARED.ERROR", messageKey]).subscribe({
      next: (translations) => {
        Swal.fire(translations["SHARED.ERROR"], translations[messageKey], "error");
      },
      error: (error) => {
        console.error("Error loading translations:", error);
      },
    });
  }
}
