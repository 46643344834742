import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BreadcrumbItem } from 'src/app/interfaces/dtos/breadcrumb-item';

@Component({
  standalone:true,
  imports:[CommonModule, RouterLink],
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PageTitleComponent implements OnInit {

  @Input() breadcrumbItems:BreadcrumbItem[];
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
