import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { LangChangeEvent, TranslateModule, TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { TransactionService } from "src/app/services/core/payment/transaction.service";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { Transaction } from "src/app/interfaces/core/payment/transaction";
import { CurrencyFormatPipe } from "../../../../../pipes/currency-format.pipe";
import { TransactionType } from "src/app/interfaces/enums/transaction-type.enum";
import { TransactionStatus } from "src/app/interfaces/enums/transaction-status.enum";

@Component({
  selector: "app-transaction-detail",
  standalone: true,
  imports: [
    RouterModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    CurrencyPipe,
    DatePipe,
    CommonModule,
    CurrencyFormatPipe
  ],
  templateUrl: "./transaction-detail.component.html",
  styleUrls: ["./transaction-detail.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class TransactionDetailComponent implements OnInit {
  transaction: Transaction | null = null;
  relatedTransactions: Transaction[] = [];
  breadcrumbItems: BreadcrumbItem[] = [];
  language: string;

  constructor(
    private transactionService: TransactionService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.language = event.lang;
    });
    this.initBreadcrumbItems();
    this.loadTransaction();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(["SHARED.HOME", "PAYMENT.TEXT", "PAYMENT.TRANSACTION.TITLE", "PAYMENT.TRANSACTION.DETAIL"])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            { label: translations["PAYMENT.TEXT"], link: "/apps/payment" },
            { label: translations["PAYMENT.TRANSACTION.TITLE"], link: "/apps/payment/transaction" },
            { label: translations["PAYMENT.TRANSACTION.DETAIL"], active: true },
          ];
        },
        error: (error) => {
          console.error("Translation error:", error);
        }
      });
  }

  loadTransaction(): void {
    const uuid = this.route.snapshot.paramMap.get("id");
    if (uuid) {
      this.spinner.show();
      this.transactionService
        .findOne(uuid)
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: (response) => {
            this.transaction = response.payload;
            this.loadRelatedTransactions();
          },
          error: (error) => {
            console.error("Error loading transaction:", error);
            this.showErrorAlert("PAYMENT.TRANSACTION.ERROR.LOAD_DETAIL");
            this.spinner.hide();
          },
        });
    }
  }

  loadRelatedTransactions(): void {
    if (this.transaction && this.transaction.paymentId) {
      this.transactionService
        .findAllByPaymentId(this.transaction.paymentId,{
          limit:0,
        })
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: (response) => {
            this.relatedTransactions = response.payload.content.filter(t => t.uuid !== this.transaction.uuid);
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error loading related transactions:", error);
            this.showErrorAlert("PAYMENT.TRANSACTION.ERROR.LOAD_RELATED");
            this.spinner.hide();
          },
        });
    } else {
      this.spinner.hide();
    }
  }

  goBack(): void {
    this.router.navigate(["/apps/payment/transaction"]);
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Translation error:", error);
        }
      });
  }
}