import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENU.TEXT",
    isTitle: true,
  },
  {
    id: 2,
    label: "DASHBOARD.TEXT",
    icon: "ri-dashboard-line",
    link: "/apps/dashboard",
  },
  {
    id: 3,
    label: "USER.TEXT",
    icon: "ri-user-line",
    subItems: [
      {
        id: 4,
        label: "USER.USER",
        link: "/apps/user/user",
        parentId: 3,
      },
      {
        id: 5,
        label: "USER.PROFIL",
        link: "/apps/user/profil",
        parentId: 3,
      },
      {
        id: 6,
        label: "USER.ACTION",
        link: "/apps/user/action",
        parentId: 3,
      },
      {
        id: 7,
        label: "USER.PERMISSION",
        link: "/apps/user/permission",
        parentId: 3,
      },
    ],
  },
  {
    id: 8,
    label: "CLIENT.TEXT",
    icon: "ri-team-line",
    subItems: [
      {
        id: 9,
        label: "CLIENT.CLIENT",
        link: "/apps/client/client-list",
        parentId: 8,
      },
      {
        id: 10,
        label: "CLIENT.TYPE.TEXT",
        link: "/apps/client/type-client",
        parentId: 8,
      },
      {
        id: 11,
        label: "CLIENT.ACTIVITY.TEXT",
        link: "/apps/client/activity-area",
        parentId: 8,
      },
    ],
  },
  {
    id: 12,
    label: "PAYMENT.TEXT",
    icon: "ri-wallet-line",
    subItems: [
      {
        id: 13,
        label: "PAYMENT.PAYMENT",
        link: "/apps/payment/payment",
        parentId: 12,
      },
      {
        id: 14,
        label: "PAYMENT.TRANSACTION.TITLE",
        link: "/apps/payment/transaction",
        parentId: 12,
      },
      {
        id: 15,
        label: "PAYMENT.VCREDIT.TEXT",
        link: "/apps/payment/virtual-credit-operation",
        parentId: 12,
      },
      {
        id: 16,
        label: "PAYMENT.FEE.TEXT",
        link: "/apps/payment/fee",
        parentId: 12,
      },
      {
        id: 24, 
        label: 'PAYMENT.VCREDIT_TRANSFER.TITLE',
        link: '/apps/payment/virtual-credit-transfer',
        parentId: 12
      },
    ],
  },
  {
    id: 17,
    label: "CONFIG.TEXT",
    icon: "ri-settings-line",
    subItems: [
      {
        id: 18,
        label: "CONFIG.CONFIG",
        link: "/apps/config/configuration",
        parentId: 17,
      },
      {
        id: 19,
        label: "CONFIG.PROVIDER",
        link: "/apps/config/provider",
        parentId: 17,
      },
    ],
  },
  {
    id: 20,
    label: "COMMON.TEXT",
    icon: "ri-global-line",
    subItems: [
      {
        id: 21,
        label: "COMMON.ATTRIBUTE.TEXT",
        link: "/apps/common/attribute",
        parentId: 20,
      },
      {
        id: 22,
        label: "COMMON.CURRENCY.TEXT",
        link: "/apps/common/currency",
        parentId: 20,
      },
      {
        id: 23,
        label: "COMMON.COUNTRY.TEXT",
        link: "/apps/common/country",
        parentId: 20,
      },
    ],
  },
];
