import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from "@angular/forms";
import { NgbModalModule, NgbPaginationModule, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { ActivityArea } from "../../../interfaces/core/client/activity-area";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { ActivityAreaService } from "src/app/services/core/client/activity-area.service";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-activity-area-list",
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    HasPermissionsDirective
  ],
  templateUrl: "./activity-area-list.component.html",
  styleUrls: ["./activity-area-list.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ActivityAreaListComponent implements OnInit {
  activityAreas: ActivityArea[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: 'createdAt',
    orderDirection: 'DESC',
    search: '',
    params: []
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;

  constructor(
    private activityAreaService: ActivityAreaService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initBreadcrumbItems();
    this.initFilterForm();
    await this.loadActivityAreas();
  }

  initBreadcrumbItems(): void {
    this.translateService.get(['SHARED.HOME', 'CLIENT.TEXT', 'CLIENT.ACTIVITY.LIST.TITLE']).subscribe(translations => {
      this.breadcrumbItems = [
        { label: translations['SHARED.HOME'], link: "/" },
        { label: translations['CLIENT.TEXT'], link: "/apps/client/activity-area" },
        { label: translations['CLIENT.ACTIVITY.LIST.TITLE'], active: true },
      ];
    });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      code: [''],
      label: [''],
    });
  }

  async loadActivityAreas(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.activityarea.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.spinner.show();
    this.updatePaginationDto();
    
    this.activityAreaService
      .findAll(this.paginationDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.activityAreas = response.payload.content;
          this.collectionSize = response.payload.totalRecords;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading activity areas:", error);
          this.showErrorAlert('CLIENT.ACTIVITY.ERROR.LOAD');
          this.spinner.hide();
        },
      });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];
    
    if (filters.code) {
      this.paginationDto.params.push({ code: filters.code });
    }
    if (filters.label) {
      this.paginationDto.search = filters.label;
    }

    this.filtersActive = this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  async onPageChange(page: number): Promise<void> {
    this.paginationDto.page = page;
    await this.loadActivityAreas();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  async applyFilters(): Promise<void> {
    this.paginationDto.page = 1;
    await this.loadActivityAreas();
    this.modalService.dismissAll();
  }

  async clearFilters(): Promise<void> {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: 'createdAt',
      orderDirection: 'DESC',
      search: '',
      params: []
    };
    this.filtersActive = false;
    await this.loadActivityAreas();
  }

  async createActivityArea(): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.activityarea.create'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.router.navigate(["/apps/client/activity-area/create"]);
  }

  async editActivityArea(uuid: string): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.activityarea.update'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.router.navigate(["/apps/client/activity-area/edit", uuid]);
  }

  async deleteActivityArea(uuid: string): Promise<void> {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.activityarea.delete'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.translateService.get(['CLIENT.ACTIVITY.CONFIRM_DELETE', 'SHARED.YES', 'SHARED.NO', 'CLIENT.ACTIVITY.DELETED', 'CLIENT.ACTIVITY.ERROR.DELETE']).subscribe(translations => {
      Swal.fire({
        title: translations['CLIENT.ACTIVITY.CONFIRM_DELETE'],
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: translations['SHARED.YES'],
        cancelButtonText: translations['SHARED.NO']
      }).then((result) => {
        if (result.isConfirmed) {
          this.activityAreaService
            .delete(uuid)
            .pipe(takeUntil(this.subscribeDestroyer$))
            .subscribe(
              () => {
                Swal.fire(translations['CLIENT.ACTIVITY.DELETED'], "", "success");
                this.loadActivityAreas();
              },
              (error) => {
                console.error("Error deleting activity area:", error);
                this.showErrorAlert('CLIENT.ACTIVITY.ERROR.DELETE');
              }
            );
        }
      });
    });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService.get(['SHARED.ERROR', messageKey]).subscribe(translations => {
      Swal.fire(translations['SHARED.ERROR'], translations[messageKey], "error");
    });
  }
}