import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { PaymentService } from "src/app/services/core/payment/payment.service";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { CurrencyFormatPipe } from "src/app/pipes/currency-format.pipe";
import { Payment } from "src/app/interfaces/core/payment/payment";
import { TransactionType } from "src/app/interfaces/enums/transaction-type.enum";
import { TransactionStatus } from "src/app/interfaces/enums/transaction-status.enum";

@Component({
  selector: "app-payment-detail",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgxSpinnerModule,
    PageTitleComponent,
    CurrencyPipe,
    DatePipe,
    CurrencyFormatPipe
  ],
  templateUrl: "./payment-detail.component.html",
  styleUrls: ["./payment-detail.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class PaymentDetailComponent implements OnInit {
  payment: Payment | null = null;
  breadcrumbItems: BreadcrumbItem[] = [];

  constructor(
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initBreadcrumbItems();
    this.loadPayment();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(["SHARED.HOME", "PAYMENT.TEXT", "PAYMENT.LIST", "PAYMENT.DETAIL"])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            { label: translations["PAYMENT.TEXT"], link: "/apps/payment" },
            { label: translations["PAYMENT.LIST"], link: "/apps/payment/payment" },
            { label: translations["PAYMENT.DETAIL"], active: true },
          ];
        },
        error: (error) => {
          console.error("Translation error:", error);
        }
      });
  }

  loadPayment(): void {
    const uuid = this.route.snapshot.paramMap.get("id");
    if (uuid) {
      this.spinner.show();
      this.paymentService
        .findOne(uuid)
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: (response) => {
            this.payment = response.payload;
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error loading payment:", error);
            this.showErrorAlert("PAYMENT.ERROR.LOAD_PAYMENT");
            this.spinner.hide();
          },
        });
    }
  }

  getTransactionTypeLabel(type: TransactionType): string {
    return this.translateService.instant(`PAYMENT.TRANSACTION_TYPE.${type}`);
  }

  getTransactionStatusLabel(status: TransactionStatus): string {
    return this.translateService.instant(`PAYMENT.TRANSACTION_STATUS.${status}`);
  }

  goBack(): void {
    this.router.navigate(["/apps/payment/payment"]);
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Translation error:", error);
        }
      });
  }
}