import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { Profil } from "src/app/interfaces/core/user/profil";
import { UserService } from "src/app/services/core/user/user.service";
import { ProfilService } from "src/app/services/core/user/profil.service";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { Users } from "src/app/interfaces/core/user/user";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-user-create-update",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
    HasPermissionsDirective,
  ],
  templateUrl: "./user-create-update.component.html",
  styleUrls: ["./user-create-update.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class UserCreateUpdateComponent implements OnInit {
  userForm: FormGroup;
  profils: Profil[] = [];
  isUpdateMode = false;
  userId: string | null = null;
  isFormSubmitted = false;
  breadcrumbItems: BreadcrumbItem[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private profilService: ProfilService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadProfils();
    this.checkForUpdateMode();
    this.initBreadcrumbItems();
  }

  initForm(): void {
    this.userForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      gender: [""],
      phone: ["", Validators.required],
      address: ["", Validators.required],
      state: [""],
      isDefaultPassword: [false],
      isValidate: [false],
      emailVerifiedAt: [null],
      profilId: ["", Validators.required],
    });
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(["SHARED.HOME", "USER.TEXT", "USER.CREATE.TITLE", "USER.EDIT.TITLE"])
      .subscribe({
        next: (translations) => {
          this.breadcrumbItems = [
            { label: translations["SHARED.HOME"], link: "/" },
            { label: translations["USER.TEXT"], link: "/apps/user/user-list" },
            {
              label: this.isUpdateMode
                ? translations["USER.EDIT.TITLE"]
                : translations["USER.CREATE.TITLE"],
              active: true,
            },
          ];
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          this.showErrorAlert("SHARED.ERROR.LOAD_TRANSLATIONS");
        },
      });
  }

  async loadProfils() {
    if (
      !(await this.permissionService.hasPermission({
        permissions: ["perm.profil.read"],
        condition: "AND",
        equalsTo: true,
      }))
    ) {
      return;
    }
    this.spinner.show();
    this.profilService
      .findAll()
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.profils = response.payload.content;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading profils:", error);
          this.showErrorAlert("USER.ERROR.LOAD_PROFILS");
          this.spinner.hide();
        },
      });
  }

  checkForUpdateMode(): void {
    this.userId = this.route.snapshot.paramMap.get("id");
    if (this.userId) {
      this.isUpdateMode = true;
      this.loadUserData();
    }
  }

  async loadUserData() {
    if (
      !(await this.permissionService.hasPermission({
        permissions: ["perm.users.read"],
        condition: "AND",
        equalsTo: true,
      }))
    ) {
      return;
    }
    if (this.userId) {
      this.spinner.show();
      this.userService
        .findOne(this.userId)
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: (response) => {
            const user = response.payload;
            this.userForm.patchValue({
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              gender: user.gender,
              phone: user.phone,
              address: user.address,
              state: user.state,
              isDefaultPassword: user.isDefaultPassword,
              isValidate: user.isValidate,
              emailVerifiedAt: user.emailVerifiedAt,
              profilId: user.profil?.uuid || user.profilId,
            });
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error loading user:", error);
            this.showErrorAlert("USER.ERROR.LOAD_USER");
            this.spinner.hide();
          },
        });
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.userForm.get(controlName);
    return (
      (control?.invalid && (control.dirty || control.touched)) ||
      (this.isFormSubmitted && control?.invalid) ||
      false
    );
  }

  onSubmit(): void {
    this.isFormSubmitted = true;

    if (this.userForm.valid) {
      this.spinner.show();
      const userData: Users = this.userForm.value;

      if (this.isUpdateMode && this.userId) {
        this.updateUser(userData);
      } else {
        this.createUser(userData);
      }
    }
  }

  async createUser(userData: Users) {
    if (
      !(await this.permissionService.hasPermission({
        permissions: ["perm.users.create"],
        condition: "AND",
        equalsTo: true,
      }))
    ) {
      return;
    }
    this.userService
      .create(userData)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: () => {
          this.showSuccessAlert("USER.CREATED");
          this.router.navigate(["/apps/user/user-list"]);
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error creating user:", error);
          this.showErrorAlert("USER.ERROR.CREATE");
          this.spinner.hide();
        },
      });
  }

  async updateUser(userData: Users) {
    if (
      !(await this.permissionService.hasPermission({
        permissions: ["perm.users.update"],
        condition: "AND",
        equalsTo: true,
      }))
    ) {
      return;
    }
    if (this.userId) {
      this.userService
        .update(this.userId, userData)
        .pipe(takeUntil(this.subscribeDestroyer$))
        .subscribe({
          next: () => {
            this.showSuccessAlert("USER.UPDATED");
            this.router.navigate(["/apps/user/user-list"]);
            this.spinner.hide();
          },
          error: (error) => {
            console.error("Error updating user:", error);
            this.showErrorAlert("USER.ERROR.UPDATE");
            this.spinner.hide();
          },
        });
    }
  }

  cancel(): void {
    this.router.navigate(["/apps/user/user-list"]);
  }

  showSuccessAlert(messageKey: string): void {
    this.translateService.get(["SHARED.SUCCESS", messageKey]).subscribe({
      next: (translations) => {
        Swal.fire(
          translations["SHARED.SUCCESS"],
          translations[messageKey],
          "success"
        );
      },
      error: (error) => {
        console.error("Error loading translations:", error);
      },
    });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService.get(["SHARED.ERROR", messageKey]).subscribe({
      next: (translations) => {
        Swal.fire(
          translations["SHARED.ERROR"],
          translations[messageKey],
          "error"
        );
      },
      error: (error) => {
        console.error("Error loading translations:", error);
      },
    });
  }
}
