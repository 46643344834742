import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ClientService } from 'src/app/services/core/client/client.service';
import { PaymentService } from 'src/app/services/core/payment/payment.service';
import { Client } from 'src/app/interfaces/core/client/client';
import { OrangeMoneyBulkCashInPaymentDto } from 'src/app/interfaces/dtos/orange-money-bulk-cash-in-payment-dto';
import { WavePayoutBatchDto } from 'src/app/interfaces/dtos/wave-payout-batch-dto';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SubscribeDestroyerService } from 'src/app/services/utils/subscribe-destroyer.service';
import { CurrencyFormatPipe } from "../../../pipes/currency-format.pipe";

@Component({
  selector: 'app-virtual-credit-transfer',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    TranslateModule,
    NgSelectModule,
    CurrencyFormatPipe
  ],
  templateUrl: './virtual-credit-transfer.component.html',
  styleUrls: ['./virtual-credit-transfer.component.scss'],
  providers: [SubscribeDestroyerService]
})
export class VirtualCreditTransferComponent implements OnInit {
  transferForm: FormGroup;
  clients: Client[] = [];
  selectedSender: Client | null = null;
  paymentMethods = ['ORANGE_MONEY', 'WAVE'];
  remainingCredit: number = 0;
  translations:any;

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private paymentService: PaymentService,
    private subscribeDestroyer$: SubscribeDestroyerService
  ) {}

  ngOnInit(): void {
    this.translateService
      .get(['CLIENT.ERROR.LOAD_CLIENTS', 'PAYMENT.VCREDIT.ERROR.INSUFFICIENT_CREDIT', 'PAYMENT.VCREDIT.SUCCESS.TRANSFER','PAYMENT.VCREDIT.ERROR.TRANSFER','PAYMENT.VCREDIT.SUCCESS.TRANSFER','PAYMENT.VCREDIT.ERROR.TRANSFER'])
      .subscribe({
        next: (translations) => {
          this.translations=translations;
        },
        error: (error) => {
          console.error("Translation error:", error);
        }
      });
    this.initForm();
    this.loadClients();
  }

  initForm(): void {
    this.transferForm = this.formBuilder.group({
      senderId: ['', Validators.required],
      paymentMethod: ['', Validators.required],
      receivers: this.formBuilder.array([])
    });

    this.addReceiver();
  }

  get receivers(): FormArray {
    return this.transferForm.get('receivers') as FormArray;
  }

  addReceiver(): void {
    const receiverForm = this.formBuilder.group({
      receiverId: ['', Validators.required],
      amount: [0, [Validators.required, Validators.min(1)]],
      currency: ['XOF', Validators.required],
      subject: ['Virtual Credit Transfer'],
      reference: ['']
    });

    this.receivers.push(receiverForm);
    this.updateRemainingCredit();
  }

  removeReceiver(index: number): void {
    this.receivers.removeAt(index);
    this.updateRemainingCredit();
  }

  loadClients(): void {
    this.clientService.findAll()
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.clients = response.payload.content;
        },
        error: (error) => {
          console.error('Error loading clients:', error);
          this.showErrorAlert(this.translations['CLIENT.ERROR.LOAD_CLIENTS']);
        }
      });
  }

  onSenderChange(): void {
    const senderId = this.transferForm.get('senderId')?.value;
    this.selectedSender = this.clients.find(client => client.uuid === senderId) || null;
    this.updateRemainingCredit();
  }

  updateRemainingCredit(): void {
    if (this.selectedSender) {
      const paymentMethod = this.transferForm.get('paymentMethod')?.value;
      const totalAmount = this.receivers.controls.reduce((sum, control) => sum + control.get('amount')?.value, 0);

      const availableCredit = paymentMethod === 'ORANGE_MONEY' 
        ? this.selectedSender.availableVirtualCreditOrangeMoney 
        : this.selectedSender.availableVirtualCreditWave;

      this.remainingCredit = availableCredit - totalAmount;
    }
  }

  onSubmit(): void {
    if (this.transferForm.valid && this.selectedSender) {
      const { paymentMethod } = this.transferForm.value;
      const receivers = this.receivers.value;

      if (this.remainingCredit < 0) {
        this.showErrorAlert(this.translations['PAYMENT.VCREDIT.ERROR.INSUFFICIENT_CREDIT']);
        return;
      }

      if (paymentMethod === 'ORANGE_MONEY') {
        this.executeOrangeMoneyTransfer(receivers);
      } else {
        this.executeWaveTransfer(receivers);
      }
    }
  }

  executeOrangeMoneyTransfer(receivers: any[]): void {
    const bulkCashInDto: OrangeMoneyBulkCashInPaymentDto = {
      bulks: receivers.map(receiver => {
        const receiverClient = this.clients.find(client => client.uuid === receiver.receiverId);
        return {
          customerSender: { 
            id: this.selectedSender!.phoneNumber,
            name: this.selectedSender!.name
          },
          customerReceiver: { 
            id: receiverClient!.phoneNumber,
            name: receiverClient!.name
          },
          currency: { unit: receiver.currency, value: receiver.amount },
          subject: receiver.subject || 'Virtual Credit Transfer',
          reference: receiver.reference || `VCT-${Date.now()}-${receiverClient!.uuid}`,
        };
      })
    };

    this.paymentService.bulkCashIn(bulkCashInDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.showSuccessAlert(this.translations['PAYMENT.VCREDIT.SUCCESS.TRANSFER']);
          this.resetForm();
        },
        error: (error) => {
          console.error('Error executing Orange Money transfer:', error);
          this.showErrorAlert(this.translations['PAYMENT.VCREDIT.ERROR.TRANSFER']);
        }
      });
  }

  executeWaveTransfer(receivers: any[]): void {
    const wavePayoutBatchDto: WavePayoutBatchDto = {
      bulks: receivers.map(receiver => {
        const receiverClient = this.clients.find(client => client.uuid === receiver.receiverId);
        return {
          customerSender: { 
            id: this.selectedSender!.phoneNumber,
            name: this.selectedSender!.name
          },
          customerReceiver: { 
            id: receiverClient!.phoneNumber,
            name: receiverClient!.name
          },
          currency: { unit: receiver.currency, value: receiver.amount },
          subject: receiver.subject || 'Virtual Credit Transfer',
          reference: receiver.reference || `VCT-${Date.now()}-${receiverClient!.uuid}`,
        };
      })
    };

    this.paymentService.createWavePayoutBatch(wavePayoutBatchDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.showSuccessAlert(this.translations['PAYMENT.VCREDIT.SUCCESS.TRANSFER']);
          this.resetForm();
        },
        error: (error) => {
          console.error('Error executing Wave transfer:', error);
          this.showErrorAlert(this.translations['PAYMENT.VCREDIT.ERROR.TRANSFER']);
        }
      });
  }

  resetForm(): void {
    this.transferForm.reset();
    this.receivers.clear();
    this.addReceiver();
    this.selectedSender = null;
    this.remainingCredit = 0;
  }

  showSuccessAlert(messageKey: string): void {
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: messageKey,
    });
  }

  showErrorAlert(messageKey: string): void {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: messageKey,
    });
  }

  searchFn(term: string, item: Client) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.phoneNumber.indexOf(term) > -1;
  }
}