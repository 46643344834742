<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-dark.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="20">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-light.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="20">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>


    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-search-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown"
          ngbDropdownMenu>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ...">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          @if(flagvalue === undefined){
          <img src="{{valueset}}" alt="Header Language" height="16">
          }@else{
          <img src="{{flagvalue}}" alt="Header Language" height="16">
          }
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          @for(item of listLang;track $index){
          <a href="javascript:void(0);" class="dropdown-item notify-item"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
          }
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen"
          (click)="fullscreen()">
          <i class="ri-fullscreen-line"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle
          class="btn header-item waves-effect d-flex align-items-center justify-content-center"
          id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="rounded-circle header-profile-user" src="assets/images/users/user-icon-with-a-rounded-circle.png"
            alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{userService.user().firstName}}
            {{userService.user().lastName}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block ms-1"></i>
        </button>


        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);"><i class="ri-user-line align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="ri-shut-down-line align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

    </div>
  </div>
</header>