<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems"
    [title]="(isUpdateMode ? 'PAYMENT.FEE.EDIT.TITLE' : 'PAYMENT.FEE.CREATE.TITLE') | translate"></app-page-title>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="feeForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="label">{{ 'PAYMENT.FEE.FORM.LABEL' | translate }}</label>
                  <input type="text" class="form-control" id="label" formControlName="label"
                    [ngClass]="{'is-invalid': hasError('label', 'required')}">
                  @if(hasError('label', 'required')){
                  <div class="invalid-feedback">
                    {{ 'PAYMENT.FEE.FORM.LABEL_REQUIRED' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="percentage">{{ 'PAYMENT.FEE.FORM.PERCENTAGE' | translate }}</label>
                  <input type="number" class="form-control" id="percentage" formControlName="percentage"
                    [ngClass]="{'is-invalid': hasError('percentage', 'required') || hasError('percentage', 'min') || hasError('percentage', 'max')}">
                  @if(hasError('percentage', 'required')){
                  <div class="invalid-feedback">
                    {{ 'PAYMENT.FEE.FORM.PERCENTAGE_REQUIRED' | translate }}
                  </div>
                  }
                  @if(hasError('percentage', 'min') || hasError('percentage', 'max')){
                  <div class="invalid-feedback">
                    {{ 'PAYMENT.FEE.FORM.PERCENTAGE_INVALID' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="amount">{{ 'PAYMENT.FEE.FORM.AMOUNT' | translate }}</label>
                  <input type="number" class="form-control" id="amount" formControlName="amount"
                    [ngClass]="{'is-invalid': hasError('amount', 'required') || hasError('amount', 'min')}">
                  @if(hasError('amount', 'required')){
                  <div class="invalid-feedback">
                    {{ 'PAYMENT.FEE.FORM.AMOUNT_REQUIRED' | translate }}
                  </div>
                  }
                  @if(hasError('amount', 'min')){
                  <div class="invalid-feedback">
                    {{ 'PAYMENT.FEE.FORM.AMOUNT_INVALID' | translate }}
                  </div>
                  }
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" id="isEyoneFee" formControlName="isEyoneFee">
                  <label class="form-check-label" for="isEyoneFee">
                    {{ 'PAYMENT.FEE.FORM.IS_EYONE_FEE' | translate }}
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" id="isGlobal" formControlName="isGlobal">
                  <label class="form-check-label" for="isGlobal">
                    {{ 'PAYMENT.FEE.FORM.IS_GLOBAL' | translate }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" id="isApplicableForIncomingPayment" formControlName="isApplicableForIncomingPayment">
                  <label class="form-check-label" for="isApplicableForIncomingPayment">
                    {{ 'PAYMENT.FEE.FORM.IS_APPLICABLE_FOR_INCOMING_PAYMENT' | translate }}
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" id="isApplicableForOutgoingPayment" formControlName="isApplicableForOutgoingPayment">
                  <label class="form-check-label" for="isApplicableForOutgoingPayment">
                    {{ 'PAYMENT.FEE.FORM.IS_APPLICABLE_FOR_OUTGOING_PAYMENT' | translate }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12 text-right">
                <button type="button" class="btn btn-secondary margin me-2" (click)="cancel()">{{ 'SHARED.CANCEL' | translate }}</button>
                <button type="submit" class="btn btn-primary" [disabled]="!feeForm.valid">
                  {{ (isUpdateMode ? 'SHARED.UPDATE' : 'SHARED.CREATE') | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>