<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems" title="{{ 'CLIENT.LIST.TITLE' | translate }}"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
            <div class="mb-2 mb-md-0">
              <button type="button" class="btn btn-primary waves-effect waves-light"
                (click)="openFilterModal(filterModal)">
                <i class="mdi mdi-magnify mr-1"></i> {{ 'SHARED.SEARCH' | translate }}
                @if(filtersActive){
                <span class="badge badge-light ml-1">
                  <i class="mdi mdi-filter"></i>
                </span>
                }
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-success btn-rounded waves-effect waves-light"
                (click)="createClient()">
                <i class="mdi mdi-plus mr-1"></i> {{ 'CLIENT.LIST.ADD_NEW' | translate }}
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>{{ 'CLIENT.LIST.NAME' | translate }}</th>
                  <th>{{ 'CLIENT.LIST.TYPE' | translate }}</th>
                  <th>{{ 'CLIENT.LIST.ACTIVITY_AREA' | translate }}</th>
                  <th>{{ 'CLIENT.LIST.EYONE_ID' | translate }}</th>
                  <th>{{ 'CLIENT.LIST.PHONE_NUMBER' | translate }}</th>
                  <th>{{ 'CLIENT.LIST.TOTAL_CREDIT' | translate }}</th>
                  <th>{{ 'CLIENT.LIST.AVAILABLE_CREDIT_OM' | translate }}</th>
                  <th>{{ 'CLIENT.LIST.AVAILABLE_CREDIT_WAVE' | translate }}</th>
                  <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                @for (client of clients; track client.uuid) {
                <tr>
                  <td>{{ client.name }}</td>
                  <td>{{ client.typeClient?.label }}</td>
                  <td>{{ client.activityArea?.label }}</td>
                  <td>{{ client.eyoneExternalId }}</td>
                  <td>{{ client.phoneNumber }}</td>
                  <td>{{ client.totalVirtualCredit | currencyFormat}}</td>
                  <td>{{ client.availableVirtualCreditOrangeMoney | currencyFormat  }}</td>
                  <td>{{ client.availableVirtualCreditWave | currencyFormat }}</td>
                  <td>
                    <button type="button" class="btn btn-primary btn-sm btn-rounded me-2"
                      (click)="editClient(client.uuid)">
                      {{ 'SHARED.EDIT' | translate }}
                    </button>
                    <button type="button" class="btn btn-danger btn-sm btn-rounded" (click)="deleteClient(client.uuid)">
                      {{ 'SHARED.DELETE' | translate }}
                    </button>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>

          <div class="row mt-4">
            <div class="col-lg-12">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="paginationDto.page"
                [pageSize]="paginationDto.limit" (pageChange)="onPageChange($event)"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filterModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{ 'CLIENT.LIST.FILTER' | translate }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <form [formGroup]="filterForm">
      <div class="form-group">
        <label for="name">{{ 'CLIENT.LIST.NAME' | translate }}</label>
        <input type="text" class="form-control" id="name" formControlName="name">
      </div>
      <div class="form-group">
        <label for="eyoneExternalId">{{ 'CLIENT.LIST.EYONE_ID' | translate }}</label>
        <input type="text" class="form-control" id="eyoneExternalId" formControlName="eyoneExternalId">
      </div>
      <div class="form-group">
        <label for="phoneNumber">{{ 'CLIENT.LIST.PHONE_NUMBER' | translate }}</label>
        <input type="text" class="form-control" id="phoneNumber" formControlName="phoneNumber">
      </div>
      <div class="form-group">
        <label for="typeClientId">{{ 'CLIENT.LIST.TYPE' | translate }}</label>
        <ng-select [items]="typeClients" bindLabel="label" bindValue="id" [clearable]="true"
          formControlName="typeClientId" id="typeClientId">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="activityAreaId">{{ 'CLIENT.LIST.ACTIVITY_AREA' | translate }}</label>
        <ng-select [items]="activityAreas" bindLabel="label" bindValue="id" [clearable]="true"
          formControlName="activityAreaId" id="activityAreaId">
        </ng-select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="clearFilters(); modal.close('Close click')">{{ 'SHARED.CLEAR' |
      translate }}</button>
    <button type="button" class="btn btn-primary" (click)="applyFilters()">{{ 'SHARED.APPLY' | translate }}</button>
  </div>
</ng-template>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>