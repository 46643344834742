<div class="container-fluid">
  <app-page-title
    [breadcrumbItems]="breadcrumbItems"
    title="{{ 'USER.LIST.TITLE' | translate }}"
  ></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4"
          >
            <div
              class="mb-2 mb-md-0"
              *appHasPermissions="{
                permissions: ['perm.users.read'],
                condition: 'OR',
                equalsTo: true
              }"
            >
              <button
                type="button"
                class="btn btn-primary waves-effect waves-light"
                (click)="openFilterModal(filterModal)"
              >
                <i class="mdi mdi-magnify mr-1"></i>
                {{ "SHARED.SEARCH" | translate }}
                @if (filtersActive) {
                <span class="badge badge-light ml-1">
                  <i class="mdi mdi-filter"></i>
                </span>
                }
              </button>
            </div>
            <div
              *appHasPermissions="{
                permissions: ['perm.users.create'],
                condition: 'OR',
                equalsTo: true
              }"
            >
              <button
                type="button"
                class="btn btn-success btn-rounded waves-effect waves-light"
                (click)="createUser()"
              >
                <i class="mdi mdi-plus mr-1"></i>
                {{ "USER.LIST.ADD_NEW" | translate }}
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>{{ "USER.LIST.EMAIL" | translate }}</th>
                  <th>{{ "USER.LIST.FIRST_NAME" | translate }}</th>
                  <th>{{ "USER.LIST.LAST_NAME" | translate }}</th>
                  <th>{{ "USER.LIST.PROFILE" | translate }}</th>
                  <th>{{ "SHARED.ACTIONS" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                @for (user of users; track user.uuid) {
                <tr>
                  <td>{{ user.email }}</td>
                  <td>{{ user.firstName }}</td>
                  <td>{{ user.lastName }}</td>
                  <td>{{ user.profil?.name }}</td>
                  <td>
                    <button
                      *appHasPermissions="{
                        permissions: ['perm.users.update'],
                        condition: 'OR',
                        equalsTo: true
                      }"
                      type="button"
                      class="btn btn-primary btn-sm btn-rounded me-2"
                      (click)="editUser(user.uuid)"
                    >
                      {{ "SHARED.EDIT" | translate }}
                    </button>
                    <button
                      *appHasPermissions="{
                        permissions: ['perm.users.delete'],
                        condition: 'OR',
                        equalsTo: true
                      }"
                      type="button"
                      class="btn btn-danger btn-sm btn-rounded"
                      (click)="deleteUser(user.uuid)"
                    >
                      {{ "SHARED.DELETE" | translate }}
                    </button>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>

          <div class="row mt-4">
            <div class="col-lg-12">
              <ngb-pagination
                [collectionSize]="collectionSize"
                [(page)]="paginationDto.page"
                [pageSize]="paginationDto.limit"
                (pageChange)="onPageChange($event)"
              ></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filterModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{ "USER.LIST.FILTER" | translate }}</h5>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
    >
      ×
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="filterForm">
      <div class="form-group">
        <label for="email">{{ "USER.LIST.EMAIL" | translate }}</label>
        <input
          type="text"
          class="form-control"
          id="email"
          formControlName="email"
        />
      </div>
      <div class="form-group">
        <label for="firstName">{{ "USER.LIST.FIRST_NAME" | translate }}</label>
        <input
          type="text"
          class="form-control"
          id="firstName"
          formControlName="firstName"
        />
      </div>
      <div class="form-group">
        <label for="lastName">{{ "USER.LIST.LAST_NAME" | translate }}</label>
        <input
          type="text"
          class="form-control"
          id="lastName"
          formControlName="lastName"
        />
      </div>
      <div class="form-group">
        <label for="profilId">{{ "USER.LIST.PROFILE" | translate }}</label>
        <ng-select
          [items]="profils"
          bindLabel="name"
          bindValue="uuid"
          [clearable]="true"
          formControlName="profilId"
          id="profilId"
        >
        </ng-select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-light"
      (click)="clearFilters(); modal.close('Close click')"
    >
      {{ "SHARED.CLEAR" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="applyFilters()">
      {{ "SHARED.APPLY" | translate }}
    </button>
  </div>
</ng-template>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">
    {{ "SHARED.LOADING" | translate }}
  </p>
</ngx-spinner>
