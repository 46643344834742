import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModal, NgbModalModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";
import { Provider } from "src/app/interfaces/core/configuration/provider";
import { ProviderService } from "src/app/services/core/configuration/provider.service";
import { ProviderType } from "src/app/interfaces/enums/provider-type.enum";

@Component({
  selector: "app-provider-list",
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
  ],
  templateUrl: "./provider-list.component.html",
  styleUrls: ["./provider-list.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class ProviderListComponent implements OnInit {
  providers: Provider[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: "createdAt",
    orderDirection: "DESC",
    search: "",
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;
  providerTypes = Object.values(ProviderType);

  constructor(
    private providerService: ProviderService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initBreadcrumbItems();
    this.initFilterForm();
    this.loadProviders();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(["SHARED.HOME", "CONFIG.PROVIDER", "CONFIG.PROVIDER_LIST.TITLE"])
      .subscribe((translations) => {
        this.breadcrumbItems = [
          { label: translations["SHARED.HOME"], link: "/" },
          { label: translations["CONFIG.PROVIDER"], link: "/apps/config/provider" },
          { label: translations["CONFIG.PROVIDER_LIST.TITLE"], active: true },
        ];
      });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      name: [""],
      eyoneExternalId: [""],
      type: [""],
    });
  }

  loadProviders(): void {
    this.spinner.show();
    this.updatePaginationDto();

    this.providerService
      .findAll(this.paginationDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.providers = response.payload.content;
          this.collectionSize = response.payload.totalRecords;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading providers:", error);
          this.showErrorAlert("CONFIG.PROVIDER_ERROR.LOAD_PROVIDERS");
          this.spinner.hide();
        },
      });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];

    if (filters.name) {
      this.paginationDto.search = filters.name;
    }
    if (filters.eyoneExternalId) {
      this.paginationDto.params.push({ eyoneExternalId: filters.eyoneExternalId });
    }
    if (filters.type) {
      this.paginationDto.params.push({ type: filters.type });
    }

    this.filtersActive = this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  onPageChange(page: number): void {
    this.paginationDto.page = page;
    this.loadProviders();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  applyFilters(): void {
    this.paginationDto.page = 1;
    this.loadProviders();
    this.modalService.dismissAll();
  }

  clearFilters(): void {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: "createdAt",
      orderDirection: "DESC",
      search: "",
      params: [],
    };
    this.filtersActive = false;
    this.loadProviders();
  }

  createProvider(): void {
    this.router.navigate(["/apps/config/provider/create"]);
  }

  editProvider(uuid: string): void {
    this.router.navigate(["/apps/config/provider/edit", uuid]);
  }

  deleteProvider(uuid: string): void {
    this.translateService
      .get(["CONFIG.PROVIDER_CONFIRM_DELETE", "SHARED.YES", "SHARED.NO", "CONFIG.PROVIDER_DELETED", "CONFIG.PROVIDER_ERROR.DELETE"])
      .subscribe((translations) => {
        Swal.fire({
          title: translations["CONFIG.PROVIDER_CONFIRM_DELETE"],
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: translations["SHARED.YES"],
          cancelButtonText: translations["SHARED.NO"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.providerService
              .delete(uuid)
              .pipe(takeUntil(this.subscribeDestroyer$))
              .subscribe({
                next: () => {
                  Swal.fire(translations["CONFIG.PROVIDER_DELETED"], "", "success");
                  this.loadProviders();
                },
                error: (error) => {
                  console.error("Error deleting provider:", error);
                  this.showErrorAlert("CONFIG.PROVIDER_ERROR.DELETE");
                },
              });
          }
        });
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe((translations) => {
        Swal.fire(translations["SHARED.ERROR"], translations[messageKey], "error");
      });
  }
}