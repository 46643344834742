import { MenuItem } from "./menu.model";
export let DEFAULT_MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENU.TEXT",
    isTitle: true,
  },
  {
    id: 2,
    label: "DASHBOARD.TEXT",
    icon: "ri-dashboard-line",
    link: "/apps/dashboard",
  },
  {
    id: 3,
    label: "USER.TEXT",
    icon: "ri-user-line",
    subItems: [
      {
        id: 4,
        label: "USER.USER",
        link: "/apps/user/user-list",
        parentId: 3,
        permission: {
          permissions: ["perm.users.read", "perm.users.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
      {
        id: 5,
        label: "USER.PROFIL.LIST.TITLE",
        link: "/apps/user/profil",
        parentId: 3,
        permission: {
          permissions: ["perm.profil.read", "perm.profil.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
      {
        id: 6,
        label: "USER.APP_FEATURE.TEXT",
        link: "/apps/user/app-feature",
        parentId: 3,
        permission: {
          permissions: ["perm.appfeature.read", "perm.appfeature.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
      {
        id: 7,
        label: "USER.PERMISSION.TEXT",
        link: "/apps/user/permission",
        parentId: 3,
        permission: {
          permissions: ["perm.permission.read", "perm.permission.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
    ],
  },
  {
    id: 8,
    label: "CLIENT.TEXT",
    icon: "ri-team-line",
    subItems: [
      {
        id: 9,
        label: "CLIENT.CLIENT",
        link: "/apps/client/client-list",
        parentId: 8,
        permission: {
          permissions: ["perm.client.read", "perm.client.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
      {
        id: 10,
        label: "CLIENT.TYPE.TEXT",
        link: "/apps/client/type-client",
        parentId: 8,
        permission: {
          permissions: ["perm.typeclient.read", "perm.typeclient.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
      {
        id: 11,
        label: "CLIENT.ACTIVITY.TEXT",
        link: "/apps/client/activity-area",
        parentId: 8,
        permission: {
          permissions: ["perm.activityarea.read", "perm.activityarea.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
    ],
  },
  {
    id: 12,
    label: "PAYMENT.TEXT",
    icon: "ri-wallet-line",
    subItems: [
      {
        id: 13,
        label: "PAYMENT.PAYMENT",
        link: "/apps/payment/payment",
        parentId: 12,
        permission: {
          permissions: ["perm.payment.read"],
          condition: "AND",
          equalsTo: true,
        },
      },
      {
        id: 14,
        label: "PAYMENT.TRANSACTION.TITLE",
        link: "/apps/payment/transaction",
        parentId: 12,
        permission: {
          permissions: ["perm.transaction.read"],
          condition: "AND",
          equalsTo: true,
        },
      },
      {
        id: 15,
        label: "PAYMENT.VCREDIT.TEXT",
        link: "/apps/payment/virtual-credit-operation",
        parentId: 12,
        permission: {
          permissions: ["perm.virtualcreditoperation.read"],
          condition: "AND",
          equalsTo: true,
        },
      },
      {
        id: 16,
        label: "PAYMENT.FEE.TEXT",
        link: "/apps/payment/fee",
        parentId: 12,
        permission: {
          permissions: ["perm.fee.read", "perm.fee.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
      {
        id: 24,
        label: "PAYMENT.VCREDIT_TRANSFER.TITLE",
        link: "/apps/payment/virtual-credit-transfer",
        parentId: 12,
        permission: {
          permissions: ["perm.payment.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
    ],
  },
  {
    id: 17,
    label: "CONFIG.TEXT",
    icon: "ri-settings-line",
    subItems: [
      {
        id: 18,
        label: "CONFIG.CONFIG",
        link: "/apps/config/configuration",
        parentId: 17,
        permission: {
          permissions: ["perm.configuration.read", "perm.configuration.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
      {
        id: 19,
        label: "CONFIG.PROVIDER",
        link: "/apps/config/provider",
        parentId: 17,
        permission: {
          permissions: ["perm.provider.read", "perm.provider.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
    ],
  },
  {
    id: 20,
    label: "COMMON.TEXT",
    icon: "ri-global-line",
    subItems: [
      {
        id: 21,
        label: "COMMON.ATTRIBUTE.TEXT",
        link: "/apps/common/attribute",
        parentId: 20,
        permission: {
          permissions: ["perm.attribute.read", "perm.attribute.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
      {
        id: 22,
        label: "COMMON.CURRENCY.TEXT",
        link: "/apps/common/currency",
        parentId: 20,
        permission: {
          permissions: ["perm.currency.read", "perm.currency.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
      {
        id: 23,
        label: "COMMON.COUNTRY.TEXT",
        link: "/apps/common/country",
        parentId: 20,
        permission: {
          permissions: ["perm.country.read", "perm.country.create"],
          condition: "OR",
          equalsTo: true,
        },
      },
    ],
  },
];
