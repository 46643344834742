<div class="container-fluid">
  <app-page-title [breadcrumbItems]="breadcrumbItems"
    title="{{ 'PAYMENT.TRANSACTION.TITLE' | translate }}"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
            <div class="mb-2 mb-md-0">
              <button type="button" class="btn btn-primary waves-effect waves-light"
                (click)="openFilterModal(filterModal)">
                <i class="mdi mdi-magnify mr-1"></i> {{ 'SHARED.SEARCH' | translate }}
                @if(filtersActive){
                <span class="badge badge-light ml-1">
                  <i class="mdi mdi-filter"></i>
                </span>
                }
              </button>
            </div>
          </div>

          <div ngbAccordion>
            @for (group of groupedTransactions; track group.mainTransaction.uuid) {
            <div ngbAccordionItem [id]="group.mainTransaction.uuid">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton class="w-100 text-left">
                  <div class="transaction-summary p-3 border rounded">
                    <div class="row align-items-center">
                      <div class="col-md-3">
                        <strong>{{ 'PAYMENT.TRANSACTION.TYPE' | translate }}:</strong>
                        <span [ngClass]="{
                          'badge badge-soft-info': group.mainTransaction.type === 'VIRTUAL_CREDIT_DEPOSIT',
                          'badge badge-soft-warning': group.mainTransaction.type === 'VIRTUAL_CREDIT_USAGE'
                        }">
                          {{ group.mainTransaction.type | translate }}
                        </span>
                      </div>
                      <div class="col-md-3">
                        <strong>{{ 'PAYMENT.TRANSACTION.AMOUNT' | translate }}:</strong>
                        {{ group.mainTransaction.totalBrutAmount | currencyFormat:group.mainTransaction.devise.code }}
                      </div>
                      <div class="col-md-3">
                        <strong>{{ 'PAYMENT.TRANSACTION.SENDER' | translate }}:</strong>
                        {{ group.mainTransaction.payment?.customerSender?.name }}
                      </div>
                      <div class="col-md-3">
                        <strong>{{ 'PAYMENT.TRANSACTION.RECEIVER' | translate }}:</strong>
                        {{ group.mainTransaction.payment?.customerReceiver?.name }}
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-3">
                        <strong>{{ 'PAYMENT.TRANSACTION.STATUS' | translate }}:</strong>
                        <span [ngClass]="{
                          'badge badge-soft-success': group.mainTransaction.payment.status === 'COMPLETED',
                          'badge badge-soft-warning': group.mainTransaction.payment.status === 'PENDING',
                          'badge badge-soft-danger': group.mainTransaction.payment.status === 'FAILED' || group.mainTransaction.payment.status === 'CANCELLED'
                        }">
                          {{ group.mainTransaction.payment.status }}
                        </span>
                      </div>
                      <div class="col-md-9">
                        <strong>{{ 'PAYMENT.TRANSACTION.DATE' | translate }}:</strong>
                        {{ group.mainTransaction.createdAt | date:(language === 'fr' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm') }}
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <h5>{{ 'PAYMENT.TRANSACTION.MAIN_TRANSACTION' | translate }}</h5>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tr>
                        <th>{{ 'PAYMENT.TRANSACTION.REFERENCE' | translate }}</th>
                        <td>{{ group.mainTransaction.reference }}</td>
                      </tr>
                      <tr>
                        <th>{{ 'PAYMENT.TRANSACTION.AMOUNT' | translate }}</th>
                        <td>{{ group.mainTransaction.totalBrutAmount | currencyFormat:group.mainTransaction.devise.code }}</td>
                      </tr>
                      <tr>
                        <th>{{ 'PAYMENT.TRANSACTION.TYPE' | translate }}</th>
                        <td>{{ group.mainTransaction.type | translate }}</td>
                      </tr>
                      <tr>
                        <th>{{ 'PAYMENT.TRANSACTION.STATUS' | translate }}</th>
                        <td>{{ group.mainTransaction.status }}</td>
                      </tr>
                      <tr>
                        <th>{{ 'PAYMENT.TRANSACTION.DATE' | translate }}</th>
                        <td>{{ group.mainTransaction.createdAt | date:(language === 'fr' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm') }}</td>
                      </tr>
                    </table>
                  </div>

                  @if (group.feeTransactions.length > 0) {
                  <h5 class="mt-4">{{ 'PAYMENT.TRANSACTION.FEE_TRANSACTIONS' | translate }}</h5>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>{{ 'PAYMENT.TRANSACTION.REFERENCE' | translate }}</th>
                          <th>{{ 'PAYMENT.TRANSACTION.AMOUNT' | translate }}</th>
                          <th>{{ 'PAYMENT.TRANSACTION.TYPE' | translate }}</th>
                          <th>{{ 'PAYMENT.TRANSACTION.STATUS' | translate }}</th>
                          <th>{{ 'PAYMENT.TRANSACTION.DATE' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        @for (feeTransaction of group.feeTransactions; track feeTransaction.uuid) {
                        <tr>
                          <td>{{ feeTransaction.reference }}</td>
                          <td>{{ feeTransaction.totalBrutAmount | currencyFormat:feeTransaction.devise.code }}</td>
                          <td>{{ feeTransaction.type | translate }}</td>
                          <td>{{ feeTransaction.status }}</td>
                          <td>{{ feeTransaction.createdAt | date:(language === 'fr' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm') }}</td>
                        </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  }

                  <button type="button" class="btn btn-primary btn-sm mt-3"
                    (click)="viewTransaction(group.mainTransaction.uuid)">
                    {{ 'SHARED.VIEW_FULL_DETAILS' | translate }}
                  </button>
                </div>
              </div>
            </div>
            }
          </div>

          <div class="row mt-4">
            <div class="col-lg-12">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="paginationDto.page"
                [pageSize]="paginationDto.limit" (pageChange)="onPageChange($event)"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filterModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{ 'PAYMENT.TRANSACTION.FILTER' | translate }}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <form [formGroup]="filterForm">
      <div class="form-group">
        <label for="reference">{{ 'PAYMENT.TRANSACTION.REFERENCE' | translate }}</label>
        <input type="text" class="form-control" id="reference" formControlName="reference">
      </div>
      <div class="form-group">
        <label for="status">{{ 'PAYMENT.TRANSACTION.STATUS' | translate }}</label>
        <ng-select [items]="transactionStatuses" formControlName="status">
        </ng-select>
      </div>
      <div class="form-group">
        <label for="type">{{ 'PAYMENT.TRANSACTION.TYPE' | translate }}</label>
        <ng-select [items]="transactionTypes" formControlName="type" id="type">
        </ng-select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="clearFilters(); modal.close('Close click')">{{ 'SHARED.CLEAR' |
      translate }}</button>
    <button type="button" class="btn btn-primary" (click)="applyFilters()">{{ 'SHARED.APPLY' | translate }}</button>
  </div>
</ng-template>

<ngx-spinner type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{ 'SHARED.LOADING' | translate }}</p>
</ngx-spinner>