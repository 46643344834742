import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppFeature } from 'src/app/interfaces/core/user/app-feature';
import { DefaultResponsePayload } from 'src/app/interfaces/dtos/default-response-payload';
import { FindAllResponse } from 'src/app/interfaces/dtos/find-all-response';
import { PaginationDto } from 'src/app/interfaces/dtos/pagination-dto';
import { getPagination } from 'src/app/interfaces/dtos/filter';

@Injectable({
  providedIn: 'root',
})
export class AppFeatureService {
  private readonly apiUrl = `${environment.API_BASE_URL}/user/app-feature`;

  constructor(private http: HttpClient) {}

  findAll(pagination?: PaginationDto): Observable<FindAllResponse<AppFeature>> {
    const params = getPagination(pagination);
    return this.http.get<FindAllResponse<AppFeature>>(this.apiUrl, { params });
  }

  findOne(id: string): Observable<DefaultResponsePayload<AppFeature>> {
    return this.http.get<DefaultResponsePayload<AppFeature>>(`${this.apiUrl}/${id}`);
  }

  create(payload: AppFeature): Observable<DefaultResponsePayload<AppFeature>> {
    return this.http.post<DefaultResponsePayload<AppFeature>>(this.apiUrl, payload);
  }

  update(id: string, payload: AppFeature): Observable<DefaultResponsePayload<AppFeature>> {
    return this.http.patch<DefaultResponsePayload<AppFeature>>(`${this.apiUrl}/${id}`, payload);
  }

  delete(id: string): Observable<DefaultResponsePayload<AppFeature>> {
    return this.http.delete<DefaultResponsePayload<AppFeature>>(`${this.apiUrl}/${id}`);
  }
}
