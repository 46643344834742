import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs";
import { Fee } from "src/app/interfaces/core/payment/fee";
import { FeeService } from "src/app/services/core/payment/fee.service";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import Swal from "sweetalert2";
import { CurrencyFormatPipe } from "../../../../../pipes/currency-format.pipe";
import { HasPermissionsDirective } from "src/app/directives/has-permissions.directive";
import { PermissionService } from "src/app/services/core/user/permission.service";

@Component({
  selector: "app-client-fee-management",
  standalone: true,
  imports: [CommonModule, FormsModule, NgSelectModule, TranslateModule, CurrencyFormatPipe, HasPermissionsDirective],
  templateUrl: "./client-fee-management.component.html",
  styleUrl: "./client-fee-management.component.scss",
})
export class ClientFeeManagementComponent implements OnInit {
  @Input() clientFees: Fee[] = [];
  @Output() feesChanged = new EventEmitter<Fee[]>();
  availableFees: Fee[] = [];
  selectedFeeId: string | null = null;

  constructor(
    private feeService: FeeService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService,
    private permissionService: PermissionService
  ) {}

  async ngOnInit() {
    await this.loadAllFees();
  }

  async loadAllFees() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.fee.read'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.feeService.findAll({ limit: 0 })
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.availableFees = response.payload.content;
          this.updateAvailableFees();
        },
        error: (error) => {
          console.error('Error loading fees:', error);
          this.showErrorAlert('CLIENT.ERROR.LOAD_FEES');
        }
      });
  }

  updateAvailableFees() {
    this.availableFees = this.availableFees.filter(fee =>
      !this.clientFees.some(clientFee => clientFee.uuid === fee.uuid)
    );
  }

  async addFee() {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.fee.create'], condition: 'AND', equalsTo: true })) {
      return;
    }

    if (this.selectedFeeId) {
      const feeToAdd = this.availableFees.find(fee => fee.uuid === this.selectedFeeId);
      if (feeToAdd) {
        this.clientFees.push(feeToAdd);
        this.updateAvailableFees();
        this.selectedFeeId = null;
        this.feesChanged.emit(this.clientFees);
      }
    }
  }

  async removeFee(feeToRemove: Fee) {
    if (!await this.permissionService.hasPermission({ permissions: ['perm.fee.delete'], condition: 'AND', equalsTo: true })) {
      return;
    }

    this.clientFees = this.clientFees.filter(fee => fee.uuid !== feeToRemove.uuid);
    this.availableFees.push(feeToRemove);
    this.feesChanged.emit(this.clientFees);
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe({
        next: (translations) => {
          Swal.fire(
            translations["SHARED.ERROR"],
            translations[messageKey],
            "error"
          );
        },
        error: (error) => {
          console.error("Error loading translations:", error);
          Swal.fire("Error", "An error occurred", "error");
        },
      });
  }
}