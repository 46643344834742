import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Router } from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import {
  NgbModalModule,
  NgbPaginationModule,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { TypeClient } from "../../../interfaces/core/client/type-client";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import Swal from "sweetalert2";
import { PageTitleComponent } from "src/app/shared/ui/pagetitle/pagetitle.component";
import { SubscribeDestroyerService } from "src/app/services/utils/subscribe-destroyer.service";
import { BreadcrumbItem } from "src/app/interfaces/dtos/breadcrumb-item";
import { TypeClientService } from "src/app/services/core/client/type-client.service";
import { PaginationDto } from "src/app/interfaces/dtos/pagination-dto";

@Component({
  selector: "app-type-client-list",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    PageTitleComponent,
    TranslateModule,
    NgSelectModule,
  ],
  templateUrl: "./type-client-list.component.html",
  styleUrls: ["./type-client-list.component.scss"],
  providers: [SubscribeDestroyerService],
})
export class TypeClientListComponent implements OnInit {
  typeClients: TypeClient[] = [];
  filterForm: FormGroup;
  paginationDto: PaginationDto = {
    page: 1,
    limit: 10,
    orderField: "createdAt",
    orderDirection: "DESC",
    search: "",
    params: [],
  };
  collectionSize = 0;
  breadcrumbItems: BreadcrumbItem[] = [];
  filtersActive = false;

  constructor(
    private typeClientService: TypeClientService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscribeDestroyer$: SubscribeDestroyerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initBreadcrumbItems();
    this.initFilterForm();
    this.loadTypeClients();
  }

  initBreadcrumbItems(): void {
    this.translateService
      .get(["SHARED.HOME", "CLIENT.TEXT", "CLIENT.TYPE.LIST.TITLE"])
      .subscribe((translations) => {
        this.breadcrumbItems = [
          { label: translations["SHARED.HOME"], link: "/" },
          {
            label: translations["CLIENT.TEXT"],
            link: "/apps/client/type-client",
          },
          { label: translations["CLIENT.TYPE.LIST.TITLE"], active: true },
        ];
      });
  }

  initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      code: [""],
      label: [""],
    });
  }

  loadTypeClients(): void {
    this.spinner.show();
    this.updatePaginationDto();

    this.typeClientService
      .findAll(this.paginationDto)
      .pipe(takeUntil(this.subscribeDestroyer$))
      .subscribe({
        next: (response) => {
          this.typeClients = response.payload.content;
          this.collectionSize = response.payload.totalRecords;
          this.spinner.hide();
        },
        error: (error) => {
          console.error("Error loading type clients:", error);
          this.showErrorAlert("CLIENT.TYPE.ERROR.LOAD_TYPE_CLIENTS");
          this.spinner.hide();
        },
      });
  }

  updatePaginationDto(): void {
    const filters = this.filterForm.value;
    this.paginationDto.params = [];

    if (filters.code) {
      this.paginationDto.params.push({ code: filters.code });
    }
    if (filters.label) {
      this.paginationDto.search = filters.label;
    }

    this.filtersActive =
      this.paginationDto.params.length > 0 || !!this.paginationDto.search;
  }

  onPageChange(page: number): void {
    this.paginationDto.page = page;
    this.loadTypeClients();
  }

  openFilterModal(content: any): void {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  applyFilters(): void {
    this.paginationDto.page = 1;
    this.loadTypeClients();
    this.modalService.dismissAll();
  }

  clearFilters(): void {
    this.filterForm.reset();
    this.paginationDto = {
      page: 1,
      limit: 10,
      orderField: "createdAt",
      orderDirection: "DESC",
      search: "",
      params: [],
    };
    this.filtersActive = false;
    this.loadTypeClients();
  }

  createTypeClient(): void {
    this.router.navigate(["/apps/client/type-client/create"]);
  }

  editTypeClient(uuid: string): void {
    this.router.navigate(["/apps/client/type-client/edit", uuid]);
  }

  deleteTypeClient(uuid: string): void {
    this.translateService
      .get([
        "CLIENT.TYPE.CONFIRM_DELETE",
        "SHARED.YES",
        "SHARED.NO",
        "CLIENT.TYPE.DELETED",
        "CLIENT.TYPE.ERROR.DELETE",
      ])
      .subscribe((translations) => {
        Swal.fire({
          title: translations["CLIENT.TYPE.CONFIRM_DELETE"],
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: translations["SHARED.YES"],
          cancelButtonText: translations["SHARED.NO"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.typeClientService
              .delete(uuid)
              .pipe(takeUntil(this.subscribeDestroyer$))
              .subscribe({
                next: () => {
                  Swal.fire(translations["CLIENT.TYPE.DELETED"], "", "success");
                  this.loadTypeClients();
                },
                error: (error) => {
                  console.error("Error deleting type client:", error);
                  this.showErrorAlert("CLIENT.TYPE.ERROR.DELETE");
                },
              });
          }
        });
      });
  }

  showErrorAlert(messageKey: string): void {
    this.translateService
      .get(["SHARED.ERROR", messageKey])
      .subscribe((translations) => {
        Swal.fire(
          translations["SHARED.ERROR"],
          translations[messageKey],
          "error"
        );
      });
  }
}
